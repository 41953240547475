/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { RateFunctionModel } from '../model/rate-function.model';

@Injectable({ providedIn: 'root'})
export class RateFunctionService extends RestApiService<RateFunctionModel> {

  readonly basePath = '/rate-function/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

}
