import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AclInterface } from '@shared/model/acl/acl.interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { SessionInterface } from '../interface';

@Injectable({ providedIn: 'root' })
export class SessionService {
  readonly basePath = '/session/';

  constructor(@Inject(ApiConfig) protected config: ApiConfig, private http: HttpClient) {}

  load(): Observable<SessionInterface> {
    return this.http.get<SessionInterface>(this.getPath());
  }

  insert(credentials: { email: string; password: string }): Observable<SessionInterface> {
    return this.http.post<SessionInterface>(this.getPath(), credentials);
  }

  update(acl: AclInterface): Observable<SessionInterface> {
    return this.http.patch<SessionInterface>(this.getPath(), acl);
  }

  delete(): Observable<{ success: true; message: string }> {
    return this.http.delete<{ success: true; message: string }>(this.getPath());
  }

  refresh(headers: HttpHeaders): Observable<HttpResponse<SessionInterface>> {
    return this.http.post<SessionInterface>(this.getPath('refresh'), null, { headers: headers, observe: 'response' });
  }

  private getPath(identifier?: string): string {
    return ((this.config.baseUrl ?? '') + this.basePath + (identifier || '')).replace(/\/$/, '');
  }
}
