/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { OrganizationModel } from '../../organization/model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { LoyaltyProgramStatusEnum } from '../enumeration/loyalty-program-status.enum';

export class LoyaltyProgramParameters extends OffsetHttpParameters {
  protected status: number[] = [];
  protected searchJSON: { [key: string]: any } = {
    loyalty_program: undefined,
    organization: undefined
  };

  constructor(params?: Partial<LoyaltyProgramParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy: 'name') {
    return super.setOrderBy('loyalty_program.' + orderBy);
  }

  setWhereName(name?: string) {
    this.searchJSON.loyalty_program = name ? { name: name } : undefined;
    return this;
  }

  setWhereOrganization(organization?: OrganizationModel | TypedEntityInterface) {
    this.searchJSON.organization = organization ? { distinct: organization.uuid } : undefined;
    return this;
  }

  setWhereStatusIn(statuses?: LoyaltyProgramStatusEnum[]) {
    this.status = statuses ? statuses : undefined;
    return this;
  }

  getParams() {
    return {
      ...super.getParams(),
      ...this.getSearchJSON(),
      'status[]': this.status
    };
  }

  getSearchJSON() {
    return { search: JSON.stringify(this.searchJSON) };
  }
}
