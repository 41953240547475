/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { isEmpty } from 'lodash';

type MenuOrderByTypes = 'menu.name';

interface RequestSearchInterface {
  location: { distinct: string };
  type: { distinct: string };
  parent: { distinct: string };
  category: { in: string[] };
  brand: { in: string[] };
}
export class MenuParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    menu: { name: string };
    site: { distinct: string };
    location: { distinct: string } | { not_in: { uuid: string }[] };
    license_class: { in: { uuid: string }[] };
    product: { request: { search: Partial<RequestSearchInterface> } };
  }> = {};
  private requestSearchJSON: Partial<RequestSearchInterface> = {};

  constructor(params?: Partial<MenuParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: MenuOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, menu: name ? { name: name } : undefined };
    return this;
  }

  setWhereSite(site?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, site: site ? { distinct: site.uuid } : undefined };
    return this;
  }

  setWhereLocation(location?: { uuid: string }): this {
    this.searchJSON = {
      ...this.searchJSON,
      location: location ? { distinct: location.uuid } : undefined
    };
    return this;
  }

  setWhereLocationNotIn(locations?: { uuid: string }[]): this {
    this.searchJSON.location = locations ? { not_in: locations } : undefined;
    return this;
  }

  setWhereLicenseClassIn(lcs?: { uuid: string }[]) {
    this.searchJSON.license_class = lcs ? { in: lcs } : undefined;
    return this;
  }

  setWhereBrandIn(brand?: TypedEntityInterface | TypedEntityInterface[]) {
    if (Array.isArray(brand)) {
      const ids = brand.map(brand => brand.uuid);
      this.requestSearchJSON = {
        ...this.requestSearchJSON,
        brand: brand.length ? { in: ids } : undefined
      };
    } else {
      this.requestSearchJSON = {
        ...this.requestSearchJSON,
        brand: brand ? { in: [brand.uuid] } : undefined
      };
    }

    return this;
  }

  setWhereCategoryIn(category?: TypedEntityInterface | TypedEntityInterface[]) {
    if (Array.isArray(category)) {
      const ids = category.map(category => category.uuid);
      this.requestSearchJSON = {
        ...this.requestSearchJSON,
        category: category.length ? { in: ids } : undefined
      };
    } else {
      this.requestSearchJSON = {
        ...this.requestSearchJSON,
        category: category ? { in: [category.uuid] } : undefined
      };
    }

    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify({
        ...this.searchJSON,
        ...{ product: this.searchJSON.product ? this.searchJSON.product : this.getProductRequestSearch() }
      })
    };
    return this.removeUndefinedValues(params);
  }

  private getProductRequestSearch() {
    return isEmpty(this.removeUndefinedValues(this.requestSearchJSON))
      ? undefined
      : { request: { search: this.requestSearchJSON } };
  }
}
