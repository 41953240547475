import { LocationModel } from '@shared/entity/location/model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';

export class EventParameters extends OffsetHttpParameters {
  protected timezone: string;
  protected searchJSON: Partial<{
    event: { name: string };
    location: { distinct: string };
    time: DateRangeFieldInterface;
  }> = {};

  constructor(params?: Partial<EventParameters>) {
    super();
    if (params) Object.assign(this, params);
    this.setOrderBy('event.name');
  }

  setTimezone(timezone?: string): this {
    this.timezone = timezone ? timezone : undefined;
    return this;
  }

  setWhereLocation(location?: LocationModel | TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereDateRange(dateRange?: DateRangeFieldInterface): this {
    this.searchJSON = { ...this.searchJSON, time: dateRange };
    return this;
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, event: name ? { name : name } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      timezone: this.timezone,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
