import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../../form';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OrganizationAutocompleteComponent } from './organization-autocomplete.component';

@NgModule({
  declarations: [OrganizationAutocompleteComponent],
  imports: [
    CommonModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule, FormModule, MatProgressBarModule
  ],
  exports: [OrganizationAutocompleteComponent]
})
export class OrganizationAutocompleteModule {
}
