import { Component, Input, OnInit } from '@angular/core';

import { NgxPermissionsService } from 'ngx-permissions';
import { AdminPolicyPermissionsEnum } from '@shared/policy';

@Component({
  selector: 'admin-route-tree-menu',
  templateUrl: './route-tree-menu.component.html',
  styleUrls: ['./route-tree-menu.component.scss']
})
export class RouteTreeMenuComponent implements OnInit {
  @Input() activeRootRoute;

  @Input() buttonColor = 'primary';

  AdminPolicyPermissionsEnum: typeof AdminPolicyPermissionsEnum = AdminPolicyPermissionsEnum;

  permissions: any;

  constructor(private _ngxPermissionsService: NgxPermissionsService) {}

  ngOnInit() {
    this.checkPermissions();
  }

  checkPermissions() {
    this.permissions = Object.values(this._ngxPermissionsService.getPermissions()).map(permission => permission.name);
  }
}
