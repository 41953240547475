import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { CalendarScheduleModel, ServiceWindowCalendarTriggerData } from '../../calendar-schedule/model';
import { LocationServiceWindowDataInterface } from './location-service-window-data.interface';

export class LocationServiceWindowModel extends EntityModel implements LocationServiceWindowDataInterface {
  public location: TypedEntityInterface;
  public capacity: number;
  public service_type: 'delivery' | 'pickup' | 'curbside';
  public start_at: string;
  public end_at: string;
  public expire_at: string;
  public status: 'active' | 'inactive';
  public calendar_schedule: CalendarScheduleModel<ServiceWindowCalendarTriggerData>;
  public remaining_capacity?: number;

  constructor(locationServiceWindow: Partial<LocationServiceWindowDataInterface>) {
    super();
    if (locationServiceWindow) Object.assign(this, locationServiceWindow);
  }

  get isActive(): boolean {
    return this.status === 'active';
  }

  updateStatus(status: 'active' | 'inactive') {
    this.status = status;
  }
}
