import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { DiscountModel } from '../../discount/model';
import { AppliedDiscountModel } from '../../discount/model/applied-discount.model';

export class OrderProductModel extends EntityModel {
  name: string;
  attribute_name: string;
  image: UploadModel;
  brand: TypedEntityInterface;
  sku: string;
  batch: string;
  category: TypedEntityInterface;
  thc_mg: number;
  thc_percent: number;
  cbd_mg: number;
  cbd_percent: number;
  cbn_mg: number;
  cbn_percent: number;
  weight_grams: number;
  flower_size: TypedEntityInterface;
  pack_size: number;
  unit_label: string;
  pack_label: string;
  serving_size: string;
  serving_count: number;
  ingredients: string;
  allergens: string;
  terpene_levels: any[];
  extraction_method: string;
  batch_date: string;
  test_date: string;
  expiration_date: string;
  package_date: string;
  discounts: AppliedDiscountModel[];
  taxes: any[];
  refunds: any[];
  quantity: number;
  original_price_each: number;
  originating_location: TypedEntityInterface;
  originating_location_license: TypedEntityInterface;
  price_each: number;
  subtotal: number;
  discount_total: number;
  sales_tax_total: number;
  excise_tax_total: number;
  tax_total: number;
  total: number;
  zone?: number;

  // Frontend Additions
  all_discounts: DiscountModel[];
  reward_discounts: AppliedDiscountModel[];
  non_reward_discounts: AppliedDiscountModel[];
  reward_total: number;

  constructor(orderProduct: OrderProductModel) {
    super();
    Object.assign(this, orderProduct);
    this.resetRewardTotals();
    this.instantiateDiscountModels();
  }

  private instantiateDiscountModels() {
    this.all_discounts = [];
    this.discounts = this.discounts.map(appliedDiscount => {
      this.all_discounts.push(appliedDiscount.discount);
      const appliedDiscountInstance = new AppliedDiscountModel(appliedDiscount);
      this.calculateRewardDiscounts(appliedDiscountInstance);
      return appliedDiscountInstance;
    });
  }

  private resetRewardTotals() {
    this.reward_discounts = [];
    this.non_reward_discounts = [];
    this.reward_total = 0;
    this.discount_total = 0;
  }

  private calculateRewardDiscounts(appliedDiscount: AppliedDiscountModel) {
    appliedDiscount.isFromReward()
      ? this.reward_discounts.push(appliedDiscount)
      : this.non_reward_discounts.push(appliedDiscount);

    // since the applied discounts have no delineation of whether or not
    // it was added from a reward, we calculate the totals here
    if (appliedDiscount.isFromReward()) {
      this.reward_total += appliedDiscount.total;
    } else {
      this.discount_total += appliedDiscount.total;
    }
  }
}
