import { TypedEntityInterface } from '@app/myblackbird/src/app/shared/contract/typed-entity.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import { BrandStatusEnum } from '../enumeration';

type BrandOrderByTypes = 'brand.name';

export class BrandParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    name: string;
    organization: string;
    status: BrandStatusEnum[];
  }> = {};

  constructor(params?: Partial<BrandParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: BrandOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON,  name };
    return this;
  }

  setWhereOrganization(organization?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, organization: organization ? organization.uuid  : undefined };
    return this;
  }

  setWhereStatus(statuses?: BrandStatusEnum[]): this {
    this.searchJSON = { ...this.searchJSON, status: statuses && statuses.length ? statuses : undefined };
    return this;
  }

  getParams() {
    return {
      ...super.getParams(),
      query: JSON.stringify(this.searchJSON)
    };
  }
}
