import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { DiscountModel } from '../../discount/model';
import { OrderProductModel } from '../../order-product/model';
import { OrderModel } from '../../order/model';

@Injectable({ providedIn: 'root' })
export class OrderProductDiscountService extends BaseApiService {
  basePath = '/order/{order-uuid}/product/{product-uuid}/discount/';

  constructor(order: OrderModel, product: OrderProductModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{order-uuid}', order.uuid).replace('{product-uuid}', product.uuid);
  }

  load(discount: DiscountModel): Observable<DiscountModel> {
    return this.http.get<DiscountModel>(this.getPath(discount.uuid));
  }

  delete(discount: DiscountModel): Observable<{ success: boolean; message: string }> {
    return this.http.delete<{ success: boolean; message: string }>(this.getPath(discount.uuid));
  }
}
