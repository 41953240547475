import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { OrderModel } from '../../order/model';
import { DeliveryModel } from '../../delivery/model/delivery.model';

@Injectable({ providedIn: 'root' })
export class OrderDeliveryService extends RestApiService<DeliveryModel> {
  basePath = '/order/{order-uuid}/delivery/';
  readonly uniqueIdentifier = 'uuid';

  constructor(order: OrderModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{order-uuid}', order.uuid);
  }
}
