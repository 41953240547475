<mat-date-range-input [formGroup]="dateRangeCtrl" [rangePicker]="picker">
  <input matStartDate
         formControlName="start"
         placeholder="Start date"
         [min]="minDate"
         [max]="maxDate"
         [required]="required"
         (focus)="onFocus($event); picker.open()">
  <input matEndDate
         formControlName="end"
         placeholder="End date"
         [min]="minDate"
         [max]="maxDate"
         [required]="required"
         (dateChange)="onDateChange()"
         (focus)="onFocus($event);picker.open()">
</mat-date-range-input>

<mat-date-range-picker #picker></mat-date-range-picker>
<mat-datepicker-toggle matSuffix [for]="picker">
  <mat-icon matDatepickerToggleIcon fontSet="fal" fontIcon="fa-calendar"></mat-icon>
</mat-datepicker-toggle>
