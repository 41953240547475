import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { CommentService } from '../../comment/service';
import { DeliveryLogService } from '../../delivery-log/service';
import { DeliveryModel } from '../model/delivery.model';
import { DeliveryUploadService } from './delivery-upload.service';
import { DeliverySurchargeService } from '../../delivery-surcharge';
import { DeliveryConsumerService } from '../../delivery-consumer/delivery-consumer.service';
import { DeliveryConsumerAddressService } from '../../delivery-consumer/delivery-consumer-address.service';

@Injectable({ providedIn: 'root' })
export class DeliveryService extends RestApiService<DeliveryModel> {
  readonly basePath = '/delivery/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  printPdf(delivery: DeliveryModel) {
    return this.http.get(this.getPath(delivery.uuid + '/pdf/'), { responseType: 'blob' });
  }

  getLogService(delivery: DeliveryModel): DeliveryLogService {
    return new DeliveryLogService(delivery, this.config, this.http);
  }

  getCommentService(delivery: { uuid: string }): CommentService {
    return new CommentService(this.getDoloresPath(delivery.uuid), this.http);
  }

  getDeliverySurchargeService(delivery: { uuid: string }) {
    return new DeliverySurchargeService(delivery, this.config, this.http);
  }

  getUploadService(delivery: DeliveryModel) {
    return new DeliveryUploadService(delivery, this.config, this.http);
  }

  getDeliveryConsumerService(delivery: { uuid: string }): DeliveryConsumerService {
    return new DeliveryConsumerService(delivery, this.config, this.http);
  }

  getDeliveryConsumerAddressService(delivery: { uuid: string }): DeliveryConsumerAddressService {
    return new DeliveryConsumerAddressService(delivery, this.config, this.http);
  }
}
