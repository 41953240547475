import { StatusModel } from '../status/status.model';

export enum DaysEnum {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

const Sunday: StatusModel = {
  id: DaysEnum.Sunday,
  name: 'Sunday'
};

const Monday: StatusModel = {
  id: DaysEnum.Monday,
  name: 'Monday'
};

const Tuesday: StatusModel = {
  id: DaysEnum.Tuesday,
  name: 'Tuesday'
};

const Wednesday: StatusModel = {
  id: DaysEnum.Wednesday,
  name: 'Wednesday'
};

const Thursday: StatusModel = {
  id: DaysEnum.Thursday,
  name: 'Thursday'
};

const Friday: StatusModel = {
  id: DaysEnum.Friday,
  name: 'Friday'
};

const Saturday: StatusModel = {
  id: DaysEnum.Saturday,
  name: 'Saturday'
};

export const AllDays = [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday];
