/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

type ServiceOrderByTypes = 'service.name';

export class ServiceParameters extends OffsetHttpParameters {

  protected searchJSON: Partial<{
    service: { name: string };
  }> = {};

  constructor(params?: Partial<ServiceParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: ServiceOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, service: name ? { name: name } : undefined };
    return this;
  }
  
  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
