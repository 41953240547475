import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';

@Injectable({ providedIn: 'root' })
export abstract class BaseApiService {
  protected abstract basePath: string;

  protected constructor(@Inject(ApiConfig) protected config: ApiConfig, protected http: HttpClient) {
  }

  getPath(identifier?: string): string {
    return ((this.config.baseUrl ? this.config.baseUrl : '') + this.basePath + (identifier || '')).replace(/\/$/, '');
  }

  getDoloresPath(identifier?: string): string {
    return ((this.config.doloresUrl ? this.config.doloresUrl : '') + this.basePath + (identifier || '')).replace(/\/$/, '');
  }
}
