import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { LocationModel } from '../model/location.model';
import { OffsetHttpParameters, CursorHttpParameters } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { CollectionInterface } from '@shared/model/collection/collection.interface';

@Injectable({ providedIn: 'root' })
export class CheckinUtilitiesService extends RestApiService<LocationModel> {
  basePath = '/v2/checkin_utils/';
  readonly uniqueIdentifier = 'uuid';

  constructor(protected config: ApiConfig, protected http: HttpClient) {
    super(config, http);
  }

  loadCollection<P extends OffsetHttpParameters | CursorHttpParameters>(
    params?: P
  ): Observable<{ results: CollectionInterface<LocationModel> } | any> {
    return this.http.get<{ results: LocationModel[] }>(this.getDoloresPath('available_locations'), {
      params: params ? params.getParams() : undefined
    });
  }

  loadAclLocations(): Observable<LocationModel[]> {
    return this.http.get<LocationModel[]>(this.getDoloresPath('find_user_access_control_locations'));
  }
}
