import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { AutocompleteResponseInterface, BrandInterface, OrganizationInterface } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class OrganizationApiService {
  private basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/organization`;
  }

  public loadBrands(): Observable<BrandInterface[]> {
    return this.http.get<BrandInterface[]>(`${this.basePath}/brands`);
  }

  public autocomplete(params: any): Observable<AutocompleteResponseInterface<OrganizationInterface>> {
    return this.http.get<AutocompleteResponseInterface<OrganizationInterface>>(`${this.basePath}/autocomplete`, { params });
  }
}
