/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';

type LocationProductOrderByTypes = 'product.name';

export class LocationProductParameters extends OffsetHttpParameters {
  private normalized: boolean;
  private has_active_inventory: boolean;
  private has_stock_in_zones: string;
  private searchJSON: Partial<{
    product: { name: string };
    location: { distinct: string };
    parent: { distinct: string };
    brand: { distinct: string };
    category: { distinct: string };
    type: { distinct: string };
  }> = {};

  constructor(params?: Partial<LocationProductParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: LocationProductOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, product: name ? { name: name } : undefined };
    return this;
  }

  setWhereLocation(location?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereParent(parent?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, parent: parent ? { distinct: parent.uuid } : undefined };
    return this;
  }

  setWhereBrand(brand?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, brand: brand ? { distinct: brand.uuid } : undefined };
    return this;
  }

  setWhereCategory(category?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, category: category ? { distinct: category.uuid } : undefined };
    return this;
  }

  setWhereType(type?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, type: type ? { distinct: type.uuid } : undefined };
    return this;
  }

  setWhereNormalized(normalized?: boolean): this {
    this.normalized = normalized !== null ? normalized : undefined;
    return this;
  }

  setWhereHasActiveInventory(hasActiveInventory?: boolean): this {
    this.has_active_inventory = hasActiveInventory ? hasActiveInventory : undefined;
    return this;
  }

  setWhereHasStockInZones(hasStockInZones?: Array<number>): this {
    if (hasStockInZones && hasStockInZones.length) {
      this.has_stock_in_zones = `[${hasStockInZones.toString()}]`;
    } else {
      this.has_stock_in_zones = undefined;
    }
    return this;
  }

  getParams(): { [param: string]: any } {
    return this.removeUndefinedValues({
      ...super.getParams(),
      normalized: this.normalized,
      has_active_inventory: this.has_active_inventory,
      has_stock_in_zones: this.has_stock_in_zones,
      search: JSON.stringify(this.searchJSON)
    });
  }
}
