
export enum InventoryZoneEnum {
  SAMPLE = 1,
  FRONT = 2,
  BACK = 3,
  UNSELLABLE = 4,
  DESTROY = 5,
  RECEIVING = 6,
  SHIPPING = 7,
  QUARANTINE = 8,
  ORDERED = 9
}

export const SampleZone = {
  id: InventoryZoneEnum.SAMPLE,
  name: 'Sample',
  key: 'stock_sample'
};

export const FrontZone = {
  id: InventoryZoneEnum.FRONT,
  name: 'Front',
  key: 'stock_front'
};

export const BackZone = {
  id: InventoryZoneEnum.BACK,
  name: 'Back',
  key: 'stock_back'
};

export const UnsellableZone = {
  id: InventoryZoneEnum.UNSELLABLE,
  name: 'Unsellable',
  key: 'stock_unsellable'
};

export const DestroyedZone = {
  id: InventoryZoneEnum.DESTROY,
  name: 'Destroyed',
  key: 'stock_destroyed'
};

export const ReceivingZone = {
  id: InventoryZoneEnum.RECEIVING,
  name: 'Receiving',
  key: 'stock_receiving'
};

export const ShippingZone = {
  id: InventoryZoneEnum.SHIPPING,
  name: 'Shipping',
  key: 'stock_shipping'
};

export const QuarantineZone = {
  id: InventoryZoneEnum.QUARANTINE,
  name: 'Quarantine',
  key: 'stock_quarantine'
};

export const OrderedZone = {
  id: InventoryZoneEnum.ORDERED,
  name: 'Ordered',
  key: 'stock_ordered'
};

export const AllInventoryZones = [
  FrontZone,
  BackZone,
  SampleZone,
  UnsellableZone,
  ReceivingZone,
  ShippingZone,
  QuarantineZone,
  DestroyedZone,
  OrderedZone
];

export const RefundZones = [
  FrontZone,
  BackZone,
  UnsellableZone,
  DestroyedZone
];

export const SellableZones = [
  FrontZone,
  BackZone
];

export const RetailTransferZones = [
  FrontZone,
  BackZone,
  SampleZone,
  UnsellableZone,
  QuarantineZone
];

export const WholesaleTransferZones = [
  FrontZone,
  BackZone,
  SampleZone,
  ShippingZone,
  UnsellableZone,
  QuarantineZone
];

export const AllAvailableInventoryZones = [
  FrontZone,
  BackZone,
  SampleZone,
  UnsellableZone,
  ReceivingZone,
  ShippingZone,
  QuarantineZone
];


export const AllInventoryHistoryZones = [
  FrontZone,
  BackZone,
  SampleZone,
  UnsellableZone,
  ReceivingZone,
  ShippingZone,
  QuarantineZone,
  DestroyedZone
];
