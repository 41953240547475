import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { CashBalanceUploadService } from '../../cash-balance-upload/service';

import { CashBalanceModel } from '../model/cash-balance.model';

@Injectable({ providedIn: 'root' })
export class CashBalanceService extends RestApiService<CashBalanceModel> {
  readonly basePath = '/cash-job/';
  readonly uniqueIdentifier = 'uuid';

  constructor(protected config: ApiConfig, protected http: HttpClient) {
    super(config, http);
  }

  getUploadService(cashBalance: CashBalanceModel) {
    return new CashBalanceUploadService(cashBalance, this.config, this.http);
  }
}
