<nav mat-tab-nav-bar class="slim-layered-navbar home-navbar">
  <admin-route-tree-menu
    [activeRootRoute]="'Home'"
    [buttonColor]="null"
    style="margin-right: auto;"
  ></admin-route-tree-menu>

  <a mat-tab-link class="tab-link-user">
    <span class="navbar-text-smaller nav-text" [matMenuTriggerFor]="userMenu">
      Account
      <mat-icon>exit_to_app</mat-icon>
    </span>

    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="logout()">
        Sign out
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </mat-menu>
  </a>
</nav>

<admin-theme-menu #themeMenuComponent></admin-theme-menu>
