import { OffsetHttpParameters } from '@shared/utility/api';

type TerpeneOrderByTypes = 'terpene.name';

export class TerpeneParameters extends OffsetHttpParameters {
  private searchJSON: Partial<{ terpene: { name: string }; status: { in: number[] } }> = {};

  constructor(params?: Partial<TerpeneParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: TerpeneOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, terpene: name ? { name: name } : undefined };
    return this;
  }

  setWhereStatus(statuses: number[]) {
    this.searchJSON = { ...this.searchJSON, status: statuses && statuses.length ? { in: statuses } : undefined };
  }

  getParams(): { [param: string]: any } {
    return this.removeUndefinedValues({
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    });
  }
}
