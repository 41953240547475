/**
 * THIS FILE IS AUTOGENERATED
 */

import { Action } from '@ngrx/store';
import { AclInterface } from '@shared/model/acl/acl.interface';
import { ErrorModel } from '@shared/model/error/error.model';
import { SessionModel } from '../../model/session.model';

export enum SessionActionTypes {
  ClearSessionState = '[Session] Clear Session State',

  LoadSession = '[Session] Load  Session',
  LoadSessionSuccess = '[Session] Load Session Success',
  LoadSessionFailure = '[Session] Load Session Failure',

  Login = '[Session] Login',
  LoginSuccess = '[Session] Login Success',
  LoginFailure = '[Session] Login Failure',

  UpdateSession = '[Session] Update Session',
  UpdateSessionSuccess = '[Session] Update Session Success',
  UpdateSessionFailure = '[Session] Update Session Failure',

  DeleteSession = '[Session] Delete  Session',
  DeleteSessionSuccess = '[Session] Delete Session Success',
  DeleteSessionFailure = '[Session] Delete Session Failure',

  LoadAclsByOrganization = '[Session] Load Acls By Organization',
  LoadAclsByOrganizationSuccess = '[Session] Load Acls By Organization Success',
  LoadAclsByOrganizationFailure = '[Session] Load Acls By Organization Failure'
}

export class ClearSessionState implements Action {
  readonly type = SessionActionTypes.ClearSessionState;
}

export class LoadSession implements Action {
  readonly type = SessionActionTypes.LoadSession;
}

export class LoadSessionSuccess implements Action {
  readonly type = SessionActionTypes.LoadSessionSuccess;

  constructor(public payload: { session: SessionModel }) {}
}

export class LoadSessionFailure implements Action {
  readonly type = SessionActionTypes.LoadSessionFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class Login implements Action {
  readonly type = SessionActionTypes.Login;

  constructor(public payload: { credentials: { email: string; password: string } }) {}
}

export class LoginSuccess implements Action {
  readonly type = SessionActionTypes.LoginSuccess;

  constructor(public payload: { session: SessionModel }) {}
}

export class LoginFailure implements Action {
  readonly type = SessionActionTypes.LoginFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class UpdateSession implements Action {
  readonly type = SessionActionTypes.UpdateSession;

  constructor(public payload: { acl: AclInterface }) {}
}

export class UpdateSessionSuccess implements Action {
  readonly type = SessionActionTypes.UpdateSessionSuccess;

  constructor(public payload: { session: SessionModel }) {}
}

export class UpdateSessionFailure implements Action {
  readonly type = SessionActionTypes.UpdateSessionFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class DeleteSession implements Action {
  readonly type = SessionActionTypes.DeleteSession;
}

export class DeleteSessionSuccess implements Action {
  readonly type = SessionActionTypes.DeleteSessionSuccess;
}

export class DeleteSessionFailure implements Action {
  readonly type = SessionActionTypes.DeleteSessionFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class LoadAclsByOrganization implements Action {
  readonly type = SessionActionTypes.LoadAclsByOrganization;
}

export class LoadAclsByOrganizationSuccess implements Action {
  readonly type = SessionActionTypes.LoadAclsByOrganizationSuccess;
  // TODO TYPE
  constructor(public payload: unknown) {}
}

export class LoadAclsByOrganizationFailure implements Action {
  readonly type = SessionActionTypes.LoadAclsByOrganizationFailure;
}

export type SessionActions =
  | ClearSessionState
  | LoadSession
  | LoadSessionSuccess
  | LoadSessionFailure
  | Login
  | LoginSuccess
  | LoginFailure
  | UpdateSession
  | UpdateSessionSuccess
  | UpdateSessionFailure
  | DeleteSession
  | DeleteSessionSuccess
  | DeleteSessionFailure
  | LoadAclsByOrganization
  | LoadAclsByOrganizationSuccess
  | LoadAclsByOrganizationFailure;
