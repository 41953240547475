import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { SessionModel } from '@shared/entity/session/model';
import { LoadSession, sessionQuery } from '@shared/entity/session/state';
import { AdminPolicyService } from '@admin/policy';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private _store: Store<unknown>,
    private _router: Router,
    private _adminPolicyService: AdminPolicyService
  ) {}

  canActivate(): Observable<boolean> {
    return this.getFromStoreOrAPI().pipe(
      switchMap(() => observableOf(true)),
      catchError(() => observableOf(false))
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate();
  }

  canLoad(route: Route) {
    return this.canActivate();
  }

  private getFromStoreOrAPI(): Observable<any> {
    return this._store.pipe(
      select(sessionQuery.getSession),
      tap((session: SessionModel) => {
        if (session === undefined) {
          this._store.dispatch(new LoadSession());
          return;
        }

        if (session === null) {
          this._router.navigate(['/auth']);
          return;
        }

        if (session && session.role) {
          this._adminPolicyService.setRoles(session.role);
        }
      }),
      filter((session: SessionModel) => session !== undefined),
      take(1)
    );
  }
}
