import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, of } from 'rxjs';
import {
  VehicleInterface,
  PagyCollectionInterface
} from '../interface';
import { BaseApiService, OffsetHttpParameters } from '@shared/utility/api';


@Injectable({
  providedIn: 'root'
})
export class VehicleApiService {

  constructor(private config: ApiConfig, private http: HttpClient) {
  }

  public loadCollection(params: OffsetHttpParameters): Observable<PagyCollectionInterface<VehicleInterface>> {
    let data = { ...params.getParams() };
    data.query = JSON.stringify({ license_plate: params.getGlobalSearch() });

    return this.http.get<PagyCollectionInterface<VehicleInterface>>(`${this.config.baseUrl}/vehicles`,  { params: data } );
  }
}
