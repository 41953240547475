import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { UploadService } from '@shared/utility/api';
import { InventoryModel } from '../../inventory/model';

@Injectable({ providedIn: 'root' })
export class InventoryUploadService extends UploadService<UploadModel> {
  readonly uniqueIdentifier = 'uuid';
  protected basePath = '/inventory/{inventory}/upload/';

  constructor(inventory: InventoryModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{inventory}', inventory.uuid);
  }
}
