import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../../form';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IntegrationConfigurationAutocompleteComponent } from './integration-configuration-autocomplete.component';
import {
  IntegrationConfigurationMultiselectAutocompleteComponent
} from './integration-configuration-autocomplete-multiselect.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [IntegrationConfigurationAutocompleteComponent, IntegrationConfigurationMultiselectAutocompleteComponent],
  imports: [
    CommonModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule, FormModule, MatProgressBarModule, MatChipsModule, MatIconModule, MatSelectModule
  ],
  exports: [IntegrationConfigurationAutocompleteComponent, IntegrationConfigurationMultiselectAutocompleteComponent]
})
export class IntegrationConfigurationAutocompleteModule {
}
