import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { ConsumerModel, IConsumerData } from '../consumer/model';
import { RestApiService } from '@shared/utility/api';

export class DeliveryConsumerService extends RestApiService<IConsumerData> {
  baseUrl = this.config.baseUrl;
  basePath = `/delivery/${this.delivery}/consumer`;
  readonly uniqueIdentifier = 'uuid';

  constructor(
    private delivery: { uuid: string },
    config: ApiConfig,
    http: HttpClient
  ) {
    super(config, http);
  }

  loadConsumer(delivery: { uuid: string }): Observable<ConsumerModel>{
    return this.http.get<ConsumerModel>(this.baseUrl.concat(this.basePath));
  }

}
