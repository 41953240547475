export enum MeasurementTypeEnum {
  EACH = 1,
  WEIGHT = 2,
  VOLUME = 3,
  LENGTH = 4
}

export const EachMeasurement = {
  id: MeasurementTypeEnum.EACH,
  name: 'Each'
};

export const WeightMeasurement = {
  id: MeasurementTypeEnum.WEIGHT,
  name: 'Weight'
};

export const VolumeMeasurement = {
  id: MeasurementTypeEnum.VOLUME,
  name: 'Volume'
};

export const LengthMeasurement = {
  id: MeasurementTypeEnum.LENGTH,
  name: 'Length'
};

export const AllMeasurementTypes = [EachMeasurement, WeightMeasurement, VolumeMeasurement, LengthMeasurement];
