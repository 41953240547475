/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

type CategoryOrderByTypes = 'category.name' | 'category.sort_order';

export class ProductCategoryParameters extends OffsetHttpParameters {
  private root: boolean;
  private parent: string;
  private searchJSON: Partial<{
    category: { name: string };
  }> = {};

  constructor(params?: Partial<ProductCategoryParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: CategoryOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereCategoryName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, category: name ? { name: name } : undefined };
    return this;
  }

  setWhereName(name?: string): this {
    return this.setWhereCategoryName(name);
  }

  setWhereParent(uuid?: string): this {
    this.parent = uuid ? uuid : undefined;
    return this;
  }

  setWhereRoot(isRoot?: boolean): this {
    this.root = isRoot;
    return this;
  }

  getParams(): { [p: string]: any } {
    return this.removeUndefinedValues({
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON),
      parent: this.parent,
      root: this.root
    });
  }
}
