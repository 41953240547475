/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { DeliveryLogModel } from '../model/delivery-log.model';
import { DeliveryModel } from '../../delivery/model';
import { DeliveryLogUploadService } from '../../delivery-log-upload/service';

@Injectable({ providedIn: 'root' })
export class DeliveryLogService extends RestApiService<DeliveryLogModel> {
  basePath = '/delivery/{delivery-uuid}/log/';
  readonly uniqueIdentifier = 'uuid';

  constructor(private delivery: DeliveryModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{delivery-uuid}', delivery.uuid);
  }

  getUploadService(log: DeliveryLogModel): DeliveryLogUploadService {
    return new DeliveryLogUploadService(this.delivery, log, this.config, this.http);
  }
}
