import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { fromPromise } from 'rxjs/internal-compatibility';
import { AdminPolicyPermissionsEnum } from '@shared/policy';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  canViewSettingsCard$: Observable<boolean>;
  canViewMenusCard$: Observable<boolean>;

  constructor(private _ngxPermissionsService: NgxPermissionsService) {}

  ngOnInit() {
    this.checkPermissions();
  }

  checkPermissions() {
    this.canViewSettingsCard$ = fromPromise(
      this._ngxPermissionsService.hasPermission([AdminPolicyPermissionsEnum.VIEW_SETTINGS_CARD])
    );

    this.canViewMenusCard$ = fromPromise(
      this._ngxPermissionsService.hasPermission([AdminPolicyPermissionsEnum.VIEW_MENUS_CARD])
    );
  }
}
