/**
 * THIS FILE IS AUTOGENERATED
 */
import { OffsetHttpParameters } from '@shared/utility/api';

import { LicenseClassModel } from '../../license-class/model';


export class ActorAclParameters extends OffsetHttpParameters {
  protected searchJSON: { [key: string]: any } = {
    object: {
      distinct: {
        uuid: '',
        type: ''
      }
    },
    target: {
      distinct: {
        uuid: '',
        type: ''
      }
    },
    ability: {
      distinct: {
        name: ''
      }
    }
  };

  constructor(params?: Partial<ActorAclParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereObject(object?: LicenseClassModel) {
    this.searchJSON.object.distinct = object ? { uuid: object.uuid, type: 25 } : '';
    return this;
  }

  setWhereTarget(target?: LicenseClassModel) {
    this.searchJSON.target.distinct = target ? { uuid: target.uuid, type: 25 } : '';
    return this;
  }

  setWhereAbility(ability?: string) {
    this.searchJSON.ability.distinct.name = ability ? ability : '';
    return this;
  }

  getParams() {
    return {
      ...super.getParams(),
      ...this.getSearchJSON()
    };
  }

  getSearchJSON() {
    let search = {};

    if (this.searchJSON.target.distinct.uuid) {
      search['target'] = this.searchJSON.target;
    }

    if (this.searchJSON.object.distinct.uuid) {
      search['object'] = this.searchJSON.object;
    }

    if (this.searchJSON.ability.distinct.name) {
      search['ability'] = this.searchJSON.ability;
    }

    return { search: JSON.stringify(search) };
  }
}
