/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { ProductModel } from '../model/product.model';
import { ProductUploadService } from './upload/product-upload.service';

@Injectable({ providedIn: 'root' })
export class ProductService extends RestApiService<ProductModel> {
  readonly basePath = '/product/';
  readonly uniqueIdentifier = 'uuid';
  constructor(
    config: ApiConfig,
    http: HttpClient,
  ) {
    super(config, http);
  }

  getUploadService(product: ProductModel) {
    return new ProductUploadService(product, this.config, this.http);
  }
}
