/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';
import { LocationsMessageTriggersQueryModel } from '../../model/locations-message-triggers-query.model';
import { LocationsMessageTriggersUpsertModel } from '../../model/locations-message-triggers-upsert.model';
import { Observable } from 'rxjs';
import { LocationModel } from '../../../location/model/location.model';

@Injectable({ providedIn: 'root' })
export class LocationsMessageTriggersService extends BaseApiService {
  basePath = '/v2/locations/{location-uuid}/message_triggers';

  readonly uniqueIdentifier = 'uuid';

  constructor(location: LocationModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{location-uuid}', location.uuid);
  }

  loadMessageTriggers(): Observable<LocationsMessageTriggersQueryModel> {
    return this.http.get<LocationsMessageTriggersQueryModel>(this.getDoloresPath(), {});
  }

  upsertMessageTriggers(triggers: LocationsMessageTriggersUpsertModel) {
    return this.http.post<any>(this.getDoloresPath(), triggers);
  }
}
