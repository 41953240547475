import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { DeliverySurchargeModel, DeliverySurchargeTemplate } from './delivery-surcharge.model';
import { Observable } from 'rxjs';
import { BaseApiService } from '@shared/utility/api';

export class DeliverySurchargeService extends BaseApiService {
  basePath = '/v2/deliveries/{delivery-uuid}';
  readonly uniqueIdentifier = 'uuid';

  constructor(
    private delivery: { uuid: string },
    config: ApiConfig,
    http: HttpClient
  ) {
    super(config, http);
  }

  getSurcharges(delivery_uuid: string): Observable<{ records: [ DeliverySurchargeModel ] }> {
    this.basePath = '/v2/deliveries/' + this.delivery.uuid;
    return this.http.get<{ records: [ DeliverySurchargeModel ] }>(this.getDoloresPath('/surcharges'));
  }

  getTemplates(): Observable<[DeliverySurchargeTemplate]> {
    this.basePath = '/v2/delivery_surcharge_templates';
    return this.http.get<[DeliverySurchargeTemplate]>(this.getDoloresPath());
  }

  deleteSurcharge(uuid: string) {
    this.basePath = '/v2/deliveries/' + this.delivery.uuid;

    return this.http.delete(
      this.getDoloresPath('/remove_surcharge'),
      {
        params: {
          uuid: uuid
        }
      }
    );
  }

  createSurcharges(surcharge: DeliverySurchargeModel): Observable<DeliverySurchargeModel> {
    this.basePath = '/v2/deliveries/' + this.delivery.uuid;
    return this.http.post<DeliverySurchargeModel>(this.getDoloresPath('/add_surcharge'), surcharge);
  }
}
