import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DynamicFormModule } from '@frontend/dynamic-form';
import { MccColorPickerModule } from 'material-community-components';
import { FormFieldErrorModule } from '@shared/component/form';

import { RouteTreeMenuComponent } from './component/route-tree-menu/route-tree-menu.component';
import { ThemeMenuComponent } from './component/theme-menu/theme-menu.component';
import { ImageHoverViewerComponent } from './component/image-hover-viewer/image-hover-viewer.component';
import { ImageInputComponent } from './component/image-input/image-input.component';
import { SendEmailDialogComponent } from './component/send-email-dialog/send-email-dialog.component';
import { StateInputComponent } from './component/state-input/state-input.component';
import { ThemeEmitterComponent } from './component/theme-emitter/theme-emitter.component';
import { MaterialModule } from './material.module';
import { SelectSearchModule } from '@shared/material-inputs/select-search';
import { EntityHeaderComponent } from './component/entity-header/entity-header.component';
import { DialogContentDirective } from './directives/dialog-content.directive';
import { EntityDialogEntryComponent } from './component/entity-dialog-entry/entity-dialog-entry.component';
import { EntityDialogComponent } from './component/entity-dialog/entity-dialog.component';
import { AddressFormGroupComponent } from './component/address-form-group/address-form-group.component';
import { EntityPageComponent } from './component/entity-page/entity-page.component';
import { ImagePreviewInputComponent } from './component/image-preview-input/image-preview-input.component';
import { ScheduleFormGroupComponent } from './component/schedule-form-group/schedule-form-group.component';
import { PortalComponent } from './component/portal/portal.component';
import { ImageZoomModule } from '@shared/image-zoom';
import { EntityDialogDeleteConfirmComponent } from './component/entity-dialog-delete-confirm/entity-dialog-delete-confirm.component';
import { PipesModule } from '@shared/pipes';
import { TablePaginationComponent } from './component/table/table-pagination.component';
import { TableResetFilterButtonComponent } from './component/table/table-reset-filter-button.component';
import { ConfirmDialogComponent } from './component/confirm-dialog';
import { OrganizationAutocompleteModule } from './autocomplete/organization/organization-autocomplete.module';
import { LicenseClassAutocompleteModule } from './autocomplete/license-class/license-class-autocomplete.module';
import { StrainAutocompleteModule } from './autocomplete/strain/strain-autocomplete.module';
import { FormModule } from './form';
import { LocationAutocompleteModule } from './autocomplete/location-autocomplete/location-autocomplete.module';
import {
  LocationProductAutocompleteModule
} from './autocomplete/location-product-autocomplete/location-product-autocomplete.module';
import { BrandAutocompleteModule } from './autocomplete/brand/brand-autocomplete.module';
import {
  ProductCategoryAutocompleteModule
} from './autocomplete/product-category/product-category-autocomplete.module';
import { ProductTypeAutocompleteModule } from './autocomplete/product-type/product-type-autocomplete.module';
import { IntegrationConfigurationAutocompleteModule } from './autocomplete/integration-configuration/integration-configuration-autocomplete.module';
import { S3UploadModule } from './upload';
import { TableSortButtonsComponent } from './component/table/table-sort-buttons.component';

@NgModule({
  imports: [
    DynamicFormModule,
    SelectSearchModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    ImageZoomModule,
    PipesModule,
    FormFieldErrorModule,
    MccColorPickerModule.forRoot({ empty_color: 'transparent' }),
    LocationAutocompleteModule,
    LocationProductAutocompleteModule,
    BrandAutocompleteModule,
    ProductCategoryAutocompleteModule,
    OrganizationAutocompleteModule,
    StrainAutocompleteModule,
    ProductTypeAutocompleteModule,
    IntegrationConfigurationAutocompleteModule
  ],
  declarations: [
    PortalComponent,
    ThemeMenuComponent,
    ThemeEmitterComponent,
    ImageInputComponent,
    SendEmailDialogComponent,
    StateInputComponent,
    RouteTreeMenuComponent,
    ImageHoverViewerComponent,
    EntityHeaderComponent,
    DialogContentDirective,
    EntityPageComponent,
    EntityDialogComponent,
    EntityDialogEntryComponent,
    EntityDialogDeleteConfirmComponent,
    AddressFormGroupComponent,
    ScheduleFormGroupComponent,
    ImagePreviewInputComponent,
    TablePaginationComponent,
    TableResetFilterButtonComponent,
    ConfirmDialogComponent,
    TableSortButtonsComponent
  ],
  exports: [
    SelectSearchModule,
    DynamicFormModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    MccColorPickerModule,
    PipesModule,
    ThemeMenuComponent,
    ImageInputComponent,
    SendEmailDialogComponent,
    ThemeEmitterComponent,
    StateInputComponent,
    RouteTreeMenuComponent,
    ImageHoverViewerComponent,
    EntityHeaderComponent,
    DialogContentDirective,
    EntityDialogComponent,
    EntityDialogEntryComponent,
    EntityDialogDeleteConfirmComponent,
    AddressFormGroupComponent,
    ScheduleFormGroupComponent,
    ImagePreviewInputComponent,
    TablePaginationComponent,
    TableSortButtonsComponent,
    TableResetFilterButtonComponent,
    FormFieldErrorModule,
    OrganizationAutocompleteModule,
    LicenseClassAutocompleteModule,
    IntegrationConfigurationAutocompleteModule,
    FormModule,
    S3UploadModule
  ],
  providers: []
})
export class SharedModule {}
