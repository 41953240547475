import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { AbilityService } from '@shared/entity/ability/service';
import { ActorAclService } from '@shared/entity/actor-acl/service';
import { BrandService } from '@shared/entity/brand/service';
import { CampaignBillingPlanService } from '@shared/entity/campaign-billing-plan/service';
import { CashBalanceService } from '@shared/entity/cash-balance/service';
import { CheckinService } from '@shared/entity/checkin/service';
import { ConsumerService } from '@shared/entity/consumer/service';
import { DeliveryService } from '@shared/entity/delivery/service';
import { DiscountService } from '@shared/entity/discount/service';
import { DrawingService } from '@shared/entity/drawing/service';
import { EmployeeService } from '@shared/entity/employee/service';
import { EventService } from '@shared/entity/event/service';
import { FlowerSizeService } from '@shared/entity/flower-size/service';
import { IdentityService } from '@shared/entity/identity/service';
import { IntegrationConfigurationService } from '@shared/entity/integration-configuration/service';
import { IntegrationOrderService } from '@shared/entity/integration-order/service';
import { IntegrationProductService } from '@shared/entity/integration-product/service';
import { InventoryService } from '@shared/entity/inventory/service';
import { LicenseClassService } from '@shared/entity/license-class/service';
import { LicenseService } from '@shared/entity/license/service';
import { LocationProductService } from '@shared/entity/location-product/service';
import { LocationService } from '@shared/entity/location/service';
import { MenuService } from '@shared/entity/menu/service';
import { MessageTriggerService } from '@shared/entity/message-trigger/service';
import { OrderService } from '@shared/entity/order/service';
import { OrganizationInterestService } from '@shared/entity/organization-interest/service';
import { OrganizationService } from '@shared/entity/organization/service';
import { ProductCategoryService } from '@shared/entity/product-category/service';
import { ProductTypeService } from '@shared/entity/product-type/service';
import { ProductService } from '@shared/entity/product/service';
import { PurchaseOrderService } from '@shared/entity/purchase-order/service';
import { RateFunctionService } from '@shared/entity/rate-function/service';
import { RegionService } from '@shared/entity/region/service';
import { ServiceService } from '@shared/entity/service/service';
import { StationService } from '@shared/entity/station/service';
import { StrainService } from '@shared/entity/strain/service';
import { TaxService } from '@shared/entity/tax/service';
import { TerpeneService } from '@shared/entity/terpene/service';
import { UnitService } from '@shared/entity/unit/service';
import { UserAclService } from '@shared/entity/user-acl/service';
import { UserService } from '@shared/entity/user/service';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { LoyaltyProgramService } from '../../../../../entity/src/lib/loyalty-program/service';
import { LoyaltyTierApiService, VehicleApiService } from '@myblackbird/api/service';

// ATTN: Only add services generated from the @shared/entity lib

export class ApiServiceFactory {
  constructor(@Inject(ApiConfig) public apiConfig: ApiConfig, public http: HttpClient) {}

  public create(type: TypesEnum, dependentEntity?: TypedEntityInterface) {

    switch (type) {
      case TypesEnum.ORDERS:
        return new OrderService(this.apiConfig, this.http);

      case TypesEnum.CONSUMERS:
        return new ConsumerService(this.apiConfig, this.http);

      case TypesEnum.PRODUCTS:
        return new ProductService(this.apiConfig, this.http);

      case TypesEnum.INVENTORY:
        return new InventoryService(this.apiConfig, this.http);

      case TypesEnum.BRANDS:
        return new BrandService(this.apiConfig, this.http);

      case TypesEnum.STATIONS:
        return new StationService(this.apiConfig, this.http);

      case TypesEnum.PRODUCT_CATEGORIES:
        return new ProductCategoryService(this.apiConfig, this.http);

      case TypesEnum.DISCOUNTS:
        return new DiscountService(this.apiConfig, this.http);

      case TypesEnum.USER_ACCESS_CONTROL:
        return new UserAclService(this.apiConfig, this.http);

      case TypesEnum.EMPLOYEES:
        return new EmployeeService(this.apiConfig, this.http);

      case TypesEnum.LOCATIONS:
        return new LocationService(this.apiConfig, this.http);

      case TypesEnum.LICENSE_CLASSES:
        return new LicenseClassService(this.apiConfig, this.http);

      case TypesEnum.DELIVERIES:
        return new DeliveryService(this.apiConfig, this.http);

      case TypesEnum.CASH_JOBS:
        return new CashBalanceService(this.apiConfig, this.http);

      case TypesEnum.LOCATION_CHECKINS:
        return new CheckinService(this.apiConfig, this.http);

      case TypesEnum.TERPENES:
        return new TerpeneService(this.apiConfig, this.http);

      case TypesEnum.FLOWER_SIZES:
        return new FlowerSizeService(this.apiConfig, this.http);

      case TypesEnum.PRODUCT_TYPES:
        return new ProductTypeService(this.apiConfig, this.http);

      case TypesEnum.TAXES:
        return new TaxService(this.apiConfig, this.http);

      case TypesEnum.ACCESS_CONTROL:
        return new ActorAclService(this.apiConfig, this.http);

      case TypesEnum.SERVICES:
        return new ServiceService(this.apiConfig, this.http);

      case TypesEnum.ORGANIZATIONS:
        return new OrganizationService(this.apiConfig, this.http);

      case TypesEnum.INTEGRATION_CONFIGURATIONS:
        return new IntegrationConfigurationService(this.apiConfig, this.http);

      case TypesEnum.INTEGRATION_ORDERS:
        return new IntegrationOrderService(this.apiConfig, this.http);

      case TypesEnum.USERS:
        return new UserService(this.apiConfig, this.http);

      case TypesEnum.LICENSES:
        return new LicenseService(this.apiConfig, this.http);

      case TypesEnum.REGIONS:
        return new RegionService(this.apiConfig, this.http);

      case TypesEnum.RATE_FUNCTIONS:
        return new RateFunctionService(this.apiConfig, this.http);

      case TypesEnum.CAMPAIGN_BILLING_PLANS:
        return new CampaignBillingPlanService(this.apiConfig, this.http);

      case TypesEnum.INTEGRATION_INVENTORY:
        return new IntegrationProductService(this.apiConfig, this.http);

      case TypesEnum.UNITS:
        return new UnitService(this.apiConfig, this.http);

      case TypesEnum.STRAINS:
        return new StrainService(this.apiConfig, this.http);

      case TypesEnum.ABILITIES:
        return new AbilityService(this.apiConfig, this.http);

      case TypesEnum.MENUS:
        return new MenuService(this.apiConfig, this.http);

      case TypesEnum.LOCATION_INVENTORY:
        return new LocationProductService(this.apiConfig, this.http);

      case TypesEnum.DELIVERY_LOGS:
        this.checkDependentEntity(dependentEntity, 'DeliveryLogsService', 'delivery');
        return new DeliveryService(this.apiConfig, this.http).getLogService(<any>dependentEntity);

      case TypesEnum.DRAWINGS:
        return new DrawingService(this.apiConfig, this.http);

      case TypesEnum.EVENTS:
        return new EventService(this.apiConfig, this.http);

      case TypesEnum.IDENTITIES:
        return new IdentityService(this.apiConfig, this.http);

      case TypesEnum.PURCHASE_ORDERS:
        return new PurchaseOrderService(this.apiConfig, this.http);

      case TypesEnum.MESSAGE_TRIGGERS:
        return new MessageTriggerService(this.apiConfig, this.http);

      case TypesEnum.ORGANIZATION_INTERESTS:
        return new OrganizationInterestService(this.apiConfig, this.http);

      case TypesEnum.LOYALTY_PROGRAMS:
        return new LoyaltyProgramService(this.apiConfig, this.http).setAdminApiBasePath();

      case TypesEnum.LOCATION_CONTACTS:
        this.checkDependentEntity(dependentEntity, 'LocationContactsService', 'location');
        return new LocationService(this.apiConfig, this.http).getContactService(<any>dependentEntity);

      case TypesEnum.ORGANIZATION_BRANDS:
        this.checkDependentEntity(dependentEntity, 'LocationBrandsService', 'location');
        return new LocationService(this.apiConfig, this.http).getBrandService(<any>dependentEntity);

      case TypesEnum.MENU_BRANDS:
        this.checkDependentEntity(dependentEntity, 'MenuBrandsService', 'menu');
        return new MenuService(this.apiConfig, this.http).getBrandService(<any>dependentEntity);

      case TypesEnum.BRAND_PRODUCTS:
        this.checkDependentEntity(dependentEntity, 'BrandProductService', 'brand');
        return new BrandService(this.apiConfig, this.http).getBrandProductService(<any>dependentEntity);

      case TypesEnum.VEHICLES:
        return new VehicleApiService(this.apiConfig, this.http);

      case TypesEnum.LOYALTY_TIERS:
        return new LoyaltyTierApiService(this.apiConfig, this.http);

      default:
        throw new TypeError(`The service for type ${type} has not been added to the factory`);
    }
  }

  private checkDependentEntity(entity: TypedEntityInterface, serviceName: string, dependentEntity: string) {
    try {
      if (entity === null || entity === undefined) {
        throw new TypeError(`The ${serviceName} service requires a ${dependentEntity} to be instantiated`);
      }
    } catch (err) {
      console.error('API SERVICE FACTORY ERROR:', err);
    }
  }
}
