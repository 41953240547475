import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { EntityInterface } from '@shared/model/entity/entity.interface';
import { CursorHttpParameters, OffsetHttpParameters, UploadService } from '@shared/utility/api';
import { forkJoin, Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

export enum MultiRestMethods {
  LOAD = 'load',
  INSERT = 'insert',
  UPDATE = 'update',
  UPSERT = 'upsert',
  DELETE = 'delete'
}

@Injectable({ providedIn: 'root' })
export abstract class RestApiService<T extends EntityInterface> extends BaseApiService {
  protected abstract basePath: string;
  protected abstract uniqueIdentifier: string;

  protected constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  loadCollection<P extends OffsetHttpParameters | CursorHttpParameters>(
    params?: P
  ): Observable<CollectionInterface<T>> {
    return this.http.get<CollectionInterface<T>>(this.getPath(), {
      params: params ? params.getParams() : undefined
    });
  }

  load(entity: T | T[]): Observable<T | T[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.LOAD);
    return this.http.get<T>(this.getPath(this.getIdentifier(entity)));
  }

  insert(entity: T | T[]): Observable<T | T[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.INSERT);
    return this.http.post<T>(this.getPath(), entity);
  }

  update(entity: T | T[], method: 'put' | 'patch' = 'patch'): Observable<T | T[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.UPDATE, method);
    return this.http[method]<T>(this.getPath(this.getIdentifier(entity)), entity);
  }

  upsert(entity: T | T[]): Observable<T | T[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.UPSERT);
    return entity.uuid ? this.update(entity) : this.insert(entity);
  }

  upsertForm(form: FormData) {
    const uuid = form.get('uuid');
    if (uuid) {
      form.append('_method', 'PATCH');
    }
    return this.http.post<T>(this.getPath(<string>uuid), form);
  }

  delete(entity: T | T[]): Observable<T | T[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.DELETE);
    return this.http.delete<T>(this.getPath(this.getIdentifier(entity)));
  }

  getUploadService(entity: T): UploadService<any> | void | unknown {
    return;
  }

  protected handleMultiple(
    entities: T[],
    method: MultiRestMethods,
    updateMethod: 'put' | 'patch' = 'patch'
  ): Observable<T[]> {
    const operations = entities.map(entity => this[method](entity, updateMethod) as Observable<T>);
    return forkJoin(operations);
  }

  protected getIdentifier(entity: T) {
    return entity[this.uniqueIdentifier];
  }
}
