<admin-navbar></admin-navbar>

<div class="view-grid">
  <a [routerLink]="['customer-service']" title="Users, Roles, & Integration Orders">
    <mat-card class="view-grid-item" matRipple>
      <div class="mat-card-image customer-service">
        <mat-icon class="view-icon">people</mat-icon>
      </div>
      <mat-card-title>Customer Service</mat-card-title>
    </mat-card>
  </a>

  <a [routerLink]="['clients']" title="Licenses, Locations, Brands & Organizations">
    <mat-card class="view-grid-item" matRipple>
      <div class="mat-card-image clients">
        <mat-icon class="view-icon">location_city</mat-icon>
      </div>
      <mat-card-title>Clients</mat-card-title>
    </mat-card>
  </a>

  <a [routerLink]="['menus']" *ngIf="canViewMenusCard$ | async" title="Menus, Inventory, & All Products">
    <mat-card class="view-grid-item" matRipple>
      <div class="mat-card-image menus">
        <mat-icon class="view-icon">map</mat-icon>
      </div>
      <mat-card-title>Menus</mat-card-title>
    </mat-card>
  </a>

  <a [routerLink]="['settings']" title="Everything Else" *ngIf="canViewSettingsCard$ | async">
    <mat-card class="view-grid-item" matRipple>
      <div class="mat-card-image settings">
        <mat-icon class="view-icon">settings</mat-icon>
      </div>
      <mat-card-title> Settings </mat-card-title>
    </mat-card>
  </a>
</div>
