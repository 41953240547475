import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Observable } from 'rxjs';

import { ThemeService } from 'libs/admin/shared/src/lib/services/theme/theme.service';

@Component({
  selector: 'admin-theme-menu',
  templateUrl: `./theme-menu.component.html`,
  styleUrls: ['./theme-menu.component.scss']
})
export class ThemeMenuComponent implements OnInit {
  @ViewChild('menu', { read: MatMenu, static: true })
  menu: MatMenu;

  theme: Observable<string>;
  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.theme = this.themeService.theme;
  }

  setTheme(theme: string) {
    this.themeService.setTheme(theme);
  }
}
