
export enum CashBalanceTypesEnum {
  ProductPayment  = 1,
  ExciseTaxRemittance = 2
}

const ProductPayment = {
  id: CashBalanceTypesEnum.ProductPayment,
  name: 'Product',
};

const ExcisePayment = {
  id: CashBalanceTypesEnum.ExciseTaxRemittance,
  name: 'Excise Tax',
};

export const AllCashBalanceTypes = [
  ProductPayment,
  ExcisePayment
];
