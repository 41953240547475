import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, of } from 'rxjs';
import {
  AutocompleteResponseInterface,
  ListItemInterface,
  LoyaltyProgramInterface,
  LoyaltyTierInterface,
  PagyCollectionInterface
} from '../interface';

export interface PageDataInterface {
  canEdit: boolean;
  loyalty_program: LoyaltyProgramInterface | null;
}

@Injectable({
  providedIn: 'root'
})
export class LoyaltyTierApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/loyalty-tiers`;
  }

  public list(params: any): Observable<PagyCollectionInterface<LoyaltyTierInterface>> {
    return this.http.get<PagyCollectionInterface<LoyaltyTierInterface>>(`${this.basePath}`, { params });
  }

  pageData(): Observable<PageDataInterface> {
    return this.http.get<PageDataInterface>(`${this.basePath}/page-data`);
  }

  public show(uuid: string | null): Observable<LoyaltyTierInterface> {
    if (!uuid) {
      return of(null);
    }
    return this.http.get<LoyaltyTierInterface>(`${this.basePath}/${uuid}`);
  }

  public upsert(params: any): Observable<LoyaltyTierInterface> {
    return this.http.post<LoyaltyTierInterface>(`${this.basePath}`, params);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }

  public autocomplete(params: any): Observable<AutocompleteResponseInterface<LoyaltyTierInterface>> {
    return this.http.get<AutocompleteResponseInterface<LoyaltyTierInterface>>(`${this.basePath}/autocomplete`, { params });
  }

  public dropdown(): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.basePath}/dropdown`);
  }

  // a temp fix for old js code
  public loadCollection(params: any): Observable<PagyCollectionInterface<any>> {
    return this.http.get<PagyCollectionInterface<any>>(`${this.basePath}`, { params });
  }
}
