import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { OffsetHttpParameters, RestApiService } from '@shared/utility/api';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OrderDeliveryService } from '../../order-delivery/service';
import { OrderDiscountService } from '../../order-discount/service';
import { OrderProductService } from '../../order-product/service';
import { OrderRefundService } from '../../order-refund/service';
import { PreauthorizedOrderTransactionService } from './preauthorized-order-transaction.service';
import { OrderModel } from '../model';
import { OrderSnapshotInterface } from '@shared/entity/order/model';
import { Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrderService extends RestApiService<OrderModel> {
  readonly basePath = '/order/';
  readonly uniqueIdentifier = 'uuid';

  constructor(protected config: ApiConfig, protected http: HttpClient) {
    super(config, http);
  }

  loadSnapshot<r extends OffsetHttpParameters>(params?: r) {
    const filteredParams = params ? { params: params.getFilteredParams(['search', 'timezone']) } : undefined;
    return this.http
      .get<{ customer_count: number; order_count: number }>(this.getPath('snapshot'), filteredParams)
      .pipe(catchError(err => of({ customer_count: undefined, order_count: undefined })));
  }

  getSnapShot(params: any): Observable<OrderSnapshotInterface> {
    let data = { query: JSON.stringify(params) };
    return this.http.get<OrderSnapshotInterface>(this.getPath('snapshot/all'), { params: data });
  }

  getDeliveryService(order: OrderModel): OrderDeliveryService {
    return new OrderDeliveryService(order, this.config, this.http);
  }

  getDiscountService(order: OrderModel): OrderDiscountService {
    return new OrderDiscountService(order, this.config, this.http);
  }

  getProductService(order: OrderModel): OrderProductService {
    return new OrderProductService(order, this.config, this.http);
  }

  getRefundService(order: OrderModel): OrderRefundService {
    return new OrderRefundService(order, this.config, this.http);
  }

  getPreauthorizedTransactionService(order: OrderModel): PreauthorizedOrderTransactionService {
    return new PreauthorizedOrderTransactionService(order, this.config, this.http);
  }
}
