/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';

type LicenseOrderByTypes = 'license.number' | 'license.expiration_date';

export class LicenseParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    license: { number: string };
    organization: { distinct: string };
    license_class: { distinct: string };
    expires_at: DateRangeFieldInterface;
  }> = {};

  constructor(params?: Partial<LicenseParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: LicenseOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereNumber(number?: string): this {
    this.searchJSON = { ...this.searchJSON, license: number ? { number: number } : undefined };
    return this;
  }

  setWhereOrganization(organization?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, organization: organization ? { distinct: organization.uuid } : undefined };
    return this;
  }

  setWhereLicenseClass(licenseClass?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, license_class: licenseClass ? { distinct: licenseClass.uuid } : undefined };
    return this;
  }

  setWhereExpiresAt(dateRange?: DateRangeFieldInterface): this {
    this.searchJSON = { ...this.searchJSON, expires_at: dateRange };
    return this;
  }

  public setGlobalSearch(query?: string): this {
    return this.setWhereNumber(query);
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
