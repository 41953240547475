/**
 * THIS FILE IS AUTOGENERATED
 */
import { EntityModel } from '@shared/model/entity/entity.model';
import { ProductModel } from '../../product/model';
import { AddressModel } from '@shared/model/location/address.model';

export class MenuInventoryModel extends EntityModel {
  // Add additional properties here
  public product: ProductModel;
  public unit_label: string;
  public weight_grams: number;
  public pack_size: number;
  public batch: string;
  public price: number;
  public price_each: number;
  public case_size: number;
  public stock: number;
  public thc_percent: number;
  public cbd_percent: number;
  public cbn_percent: number;
  public thc_mg: number;
  public cbd_mg: number;
  public cbn_mg: number;
  public harvest_date: string;
  public expiration_date: string;
  public test_date: string;
  public package_date: string;
  public serving_size: string;
  public extraction_method: string;
  public ingredients: string;
  public allergens: string;
  public terpene_levels: Object;
  public included: any[];
  public uploads: any[];
  public address: AddressModel;

  constructor(menuInventory?: Partial<MenuInventoryModel>) {
    super();
    if (menuInventory) Object.assign(this, menuInventory);
  }
}
