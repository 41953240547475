import { Pipe, PipeTransform } from '@angular/core';

/*
 * Obfuscates all but the last four digits of a phone number
 * Usage:
 *   value | obfuscatephone
 * Example:
 *
 *   {{ +17025550123 | obfuscatephone }}
 *   formats to: '(***) ***-0720'
 */

@Pipe({ name: 'obfuscatephone' })
export class ObfuscatePhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || !value.startsWith('+1')) {
      return value;
    }
    return this.formatPhoneNumber(value);
  }

  formatPhoneNumber(phoneNumber: string) {
    return '(***) ***-' +  phoneNumber.slice(phoneNumber.length - 4, phoneNumber.length);
  }
}
