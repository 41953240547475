/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';

type IdentityOrderByTypes = 'identity.first_name' | 'identity.last_name' | 'identity.email' | 'identity.cell_phone';

export class IdentityParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    identity: { distinct: string };
    any: { like: string };
    first_name: { like: string };
    last_name: { like: string };
    email: { like: string };
    cell_phone: { like: string };
  }> = {};

  constructor(params?: Partial<IdentityParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: IdentityOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereIdentity(identity?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, identity: identity ? { distinct: identity.uuid } : undefined };
    return this;
  }

  setWhereName(any?: string): this {
    this.searchJSON = { ...this.searchJSON, any: any ? { like: any } : undefined };
    return this;
  }

  setWhereFirstNameLike(firstName?: string): this {
    this.searchJSON = { ...this.searchJSON, first_name: firstName ? { like: firstName } : undefined };
    return this;
  }

  setWhereLastNameLike(lastName?: string): this {
    this.searchJSON = { ...this.searchJSON, last_name: lastName ? { like: lastName } : undefined };
    return this;
  }

  setWhereEmailLike(email?: string): this {
    this.searchJSON = { ...this.searchJSON, email: email ? { like: email } : undefined };
    return this;
  }

  setWhereCellPhoneLike(cellPhone?: string): this {
    this.searchJSON = { ...this.searchJSON, cell_phone: cellPhone ? { like: cellPhone } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
