import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldErrorComponent } from './field-error.component';
import { UnprocessableFormComponent } from './unprocessable-form.component';

@NgModule({
  declarations: [FieldErrorComponent, UnprocessableFormComponent],
  imports: [
    CommonModule
  ],
  exports: [FieldErrorComponent, UnprocessableFormComponent]
})
export class FormModule {
}
