export enum AdminPolicyPermissionsEnum {
  // Cards
  VIEW_CUSTOMER_SERVICE_CARD = 'view_customer_service_card',
  VIEW_MENUS_CARD = 'view_menus_card',
  VIEW_CLIENTS_CARD = 'view_clients_card',
  VIEW_SETTINGS_CARD = 'view_settings_card',

  // Customer service
  VIEW_USER_CONSUMERS = 'view_user_consumers',
  UPDATE_USER_CONSUMERS = 'update_user_consumers',
  VIEW_LOCATION_CONSUMERS = 'view_location_consumers',
  UPDATE_LOCATION_CONSUMERS = 'update_location_consumers',
  VIEW_INTEGRATION_CONSUMERS = 'view_integration_consumers',
  VIEW_INTEGRATION_ORDERS = 'view_integration_orders',
  UPDATE_INTEGRATION_ORDERS = 'update_integration_orders',
  VIEW_IDENTITIES = 'view_identities',
  VIEW_USERS = 'view_users',
  UPDATE_USERS = 'update_users',
  VIEW_USER_ACL = 'view_user_acl',
  UPDATE_USER_ACL = 'update_user_acl',

  // Clients
  VIEW_LICENSES = 'view_licenses',
  UPDATE_LICENSES = 'update_licenses',
  DELETE_LICENSES = 'delete_licenses',
  VIEW_LOCATIONS = 'view_locations',
  VIEW_VEHICLES = 'view_vehicles',
  UPDATE_LOCATIONS = 'update_locations',
  DELETE_LOCATIONS = 'delete_locations',
  VIEW_ORGANIZATIONS = 'view_organizations',
  UPDATE_ORGANIZATIONS = 'update_organizations',
  DELETE_ORGANIZATIONS = 'delete_organizations',
  VIEW_BRANDS = 'view_brands',
  UPDATE_BRANDS = 'update_brands',
  VIEW_CAMPAIGN_BILLING_PLANS = 'view_campaign_billing_plans',
  UPDATE_CAMPAIGN_BILLING_PLANS = 'update_campaign_billing_plans',

  TOGGLE_LOYALTY_PROGRAMS = 'toggle_loyalty_programs',
  VIEW_CLIENT_SEARCH = 'view_client_search',

  VIEW_SERVICE_TYPES = 'view_service_types',
  UPDATE_SERVICE_TYPES = 'update_service_types',

  VIEW_SUBSCRIPTIONS = 'view_subscriptions',

  VIEW_RATE_FUNCTIONS = 'view_rate_functions',
  UPDATE_RATE_FUNCTIONS = 'update_rate_functions',

  VIEW_CAMPAIGN_BILLINGS = 'view_campaign_billings',
  INVOICE_CAMPAIGN_BILLINGS = 'invoice_campaign_billings',

  VIEW_ADDONS = 'view_addons',

  // Menus
  VIEW_MENUS = 'VIEW_MENUS',
  VIEW_LISTINGS = 'VIEW_LISTINGS',
  UPDATE_MENUS = 'UPDATE_MENUS',
  VIEW_INVENTORY = 'VIEW_INVENTORY',
  UPDATE_INVENTORY = 'UPDATE_INVENTORY',
  VIEW_INTEGRATION_INVENTORY = 'VIEW_INTEGRATION_INVENTORY',
  UPDATE_INTEGRATION_INVENTORY = 'UPDATE_INTEGRATION_INVENTORY',
  VIEW_LOCATION_PRODUCT = 'VIEW_LOCATION_PRODUCT',
  ARCHIVE_LOCATION_PRODUCT = 'ARCHIVE_LOCATION_PRODUCT',
  UPDATE_LOCATION_PRODUCT = 'UPDATE_LOCATION_PRODUCT',
  VIEW_PRODUCTS = 'VIEW_PRODUCTS',
  UPDATE_PRODUCTS = 'UPDATE_PRODUCTS',
  VIEW_STRAINS = 'VIEW_STRAINS',
  UPDATE_STRAINS = 'UPDATE_STRAINS',
  VIEW_PRODUCT_CATEGORIES = 'VIEW_PRODUCT_CATEGORIES',
  UPDATE_PRODUCT_CATEGORIES = 'UPDATE_PRODUCT_CATEGORIES',
  VIEW_PRODUCT_TYPES = 'VIEW_PRODUCT_TYPES',
  UPDATE_PRODUCT_TYPES = 'UPDATE_PRODUCT_TYPES',
  VIEW_FLOWER_SIZES = 'VIEW_FLOWER_SIZES',
  UPDATE_FLOWER_SIZES = 'UPDATE_FLOWER_SIZES',
  VIEW_UNITS = 'VIEW_UNITS',
  UPDATE_UNITS = 'UPDATE_UNITS',
  UPDATE_STOCK_IMAGES = 'UPDATE_STOCK_IMAGES',

  // Settings
  VIEW_TERPENES = 'view_terpenes',
  UPDATE_TERPENES = 'update_terpenes',
  VIEW_LICENSE_CLASSES = 'view_license_classes',
  UPDATE_LICENSE_CLASSES = 'update_license_classes',
  VIEW_TAXES = 'view_taxes',
  UPDATE_TAXES = 'update_taxes',
  VIEW_REGIONS = 'view_regions',
  UPDATE_REGIONS = 'update_regions',
  VIEW_MESSAGE_TRIGGERS = 'view_message_triggers',
  UPDATE_MESSAGE_TRIGGERS = 'update_message_triggers',
  VIEW_INTEGRATION_CONFIGURATIONS = 'view_integration_configurations',
  UPDATE_INTEGRATION_CONFIGURATIONS = 'update_integration_configurations',
  VIEW_INTEGRATION_ERRORS = 'view_integration_errors',
  UPDATE_INTEGRATION_ERRORS = 'update_integration_errors',

  VIEW_INTEGRATION_ACTIVITY_LOGS = 'view_integration_activity_logs',

  VIEW_MESSAGE_ACCOUNTS = 'view_message_accounts',
  UPDATE_MESSAGE_ACCOUNTS = 'update_message_accounts',

  VIEW_MESSAGE_PHONE_NUMBERS = 'view_message_phone_numbers',
  UPDATE_MESSAGE_PHONE_NUMBERS = 'update_message_phone_numbers',

  VIEW_MS_MESSAGES = 'view_ms_messages',

  VIEW_CAMPAIGN_MESSAGES = 'view_campaign_messages',

  VIEW_FEATURE_FLAGS = 'view_feature_flags',
  UPDATE_FEATURE_FLAGS = 'update_feature_flags',

  VIEW_ENTITY_ACLS = 'view_entity_acls',
  UPDATE_ENTITY_ACLS = 'update_entity_acls',

  VIEW_IMPORTS = 'view_imports',
  UPDATE_IMPORTS = 'update_imports',

  VIEW_CANCELLATION_REASONS = 'view_cancellation_reasons',
}
