import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LocationModel } from '@shared/entity/location/model';
import { LocationParameters, CheckinUtilitiesService } from '@shared/entity/location/service';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { AclInterface } from '@shared/model/acl/acl.interface';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { ErrorModel } from '@shared/model/error/error.model';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { SessionService } from '../../service';
import * as _session from '../actions/session.actions';
import { SessionActionTypes } from '../actions/session.actions';
import { sessionQuery } from '../selectors/session.selectors';

@Injectable()
export class SessionEffects {
  @Effect()
  loadSession$ = this.actions$.pipe(
    ofType(SessionActionTypes.LoadSession),
    switchMap(() => {
      return this.sessionService.load().pipe(
        map(res => new _session.LoadSessionSuccess({ session: res })),
        catchError((error: ErrorModel) => of(new _session.LoadSessionFailure({ error })))
      );
    })
  );

  @Effect()
  login$ = this.actions$.pipe(
    ofType(SessionActionTypes.Login),
    map((action: _session.Login) => action.payload),
    switchMap(payload => {
      const { credentials } = payload;
      return this.sessionService.insert(credentials).pipe(
        map(res => new _session.LoginSuccess({ session: res })),
        catchError((error: ErrorModel) => of(new _session.LoginFailure({ error })))
      );
    })
  );

  @Effect()
  updateSession$ = this.actions$.pipe(
    ofType(SessionActionTypes.UpdateSession),
    map((action: _session.UpdateSession) => action.payload),
    switchMap(payload => {
      const { acl } = payload;
      return this.sessionService.update(acl).pipe(
        map(res => new _session.UpdateSessionSuccess({ session: res })),
        catchError((error: ErrorModel) => of(new _session.UpdateSessionFailure({ error })))
      );
    })
  );

  @Effect()
  deleteSession$ = this.actions$.pipe(
    ofType(SessionActionTypes.DeleteSession),
    switchMap(() => {
      return this.sessionService.delete().pipe(
        map(() => new _session.DeleteSessionSuccess()),
        catchError((error: ErrorModel) => of(new _session.DeleteSessionFailure({ error })))
      );
    })
  );

  @Effect()
  loadAclsByOrganization$ = this.actions$.pipe(
    ofType(SessionActionTypes.LoadAclsByOrganization),
    switchMap(() => {
      return this._store.pipe(
        select(sessionQuery.getAcls),
        switchMap((acls: AclInterface[]) => {
          return this.checkinUtilitiesService.loadAclLocations().pipe(
            map((locations: LocationModel[]) => {
              const mappedLocations = locations.map(location => ({
                uuid: location.uuid,
                name: location.name,
                _type: TypesEnum.LOCATIONS,
                acl: acls.find(acl => acl.target.uuid === location.uuid),
                organization: location.organization
              }));

              const locationsByOrganization = mappedLocations.reduce((result, location) => {
                const orgIndex = result.findIndex(item => item.uuid === location.organization.uuid);
                if (orgIndex === -1) {
                  result.push({
                    uuid: location.organization.uuid,
                    name: location.organization._name,
                    _type: TypesEnum.ORGANIZATIONS,
                    acl: acls.find(acl => acl.target.uuid?.toLowerCase() === location.organization.uuid?.toLowerCase()),
                    locations: [location]
                  });
                } else {
                  result[orgIndex].locations.push(location);
                }
                return result;
              }, []);

              return new _session.LoadAclsByOrganizationSuccess(locationsByOrganization);
            }),
            catchError(() => of(new _session.LoadAclsByOrganizationFailure()))
          );
        }),
        take(1)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<unknown>,
    private sessionService: SessionService,
    private checkinUtilitiesService: CheckinUtilitiesService
  ) {}
}
