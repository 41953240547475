import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { AddressModel } from '@shared/model/entity/address/address.model';
import { RestApiService } from '@shared/utility/api';
import { ConsumerModel } from '../model';

@Injectable({ providedIn: 'root' })
export class ConsumerAddressService extends RestApiService<AddressModel> {
  basePath = '/consumer/{consumer-uuid}/address/';
  readonly uniqueIdentifier = 'uuid';

  constructor(consumer: { uuid: string }, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{consumer-uuid}', consumer.uuid);
  }
}
