import { LoyaltyRewardModel } from '@shared/entity/loyalty-reward/model';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { AddressModel } from '@shared/model/entity/address/address.model';
import { EntityInterface } from '@shared/model/entity/entity.interface';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { NameFieldInterface } from '@shared/model/field/name/name-field.interface';
import * as moment from 'moment';
import { LicenseClassModel } from '../../license-class/model';
import {
  ConsumerSourceEnum,
  ConsumerStatusEnum,
  ConsumerTypeEnum,
  ConsumerVerificationStatusEnum
} from '../enumeration';
import { LoyaltyRewardStatusEnum } from '../../loyalty-reward/enumeration';

export interface IConsumerData extends EntityInterface {
  _name: string;
  _type: TypesEnum.CONSUMERS;
  owner: TypedEntityInterface;
  loyalty_tier: TypedEntityInterface;
  identity: TypedEntityInterface;
  name: NameFieldInterface;
  email: string;
  home_phone: string;
  cell_phone: string;
  card: UploadModel;
  card_number: string;
  card_expiration_date: Date;
  card_state: string;
  card_country: string;
  caregiver: boolean;
  caregiver_consumer: TypedEntityInterface;
  caregiver_card: UploadModel;
  caregiver_card_expiration_date: Date;
  caregiver_card_number: string;
  government_identification: UploadModel;
  government_identification_expiration_date: Date;
  government_identification_number: string;
  delivery_address: AddressModel;
  home_address: AddressModel;
  license_class: LicenseClassModel;
  gender: 'm' | 'f';
  date_of_birth: Date;
  agreement: UploadModel;
  out_of_state_agreement: UploadModel;
  gram_allotment: number;
  gram_allotment_updated_at: string;
  notes: string;
  active_checkin: TypedEntityInterface;
  active_order: TypedEntityInterface;
  discount: TypedEntityInterface;
  points_available: number;
  points_earned: number;
  points_spent: number;
  type: ConsumerTypeEnum;
  source: ConsumerSourceEnum;
  subscribed: boolean;
  subscribed_at: string;
  unsubscribed_at: string;
  verification_status: ConsumerVerificationStatusEnum;
  has_password: string;
  status: ConsumerStatusEnum;
}

export class ConsumerModel extends EntityModel implements IConsumerData {
  readonly _name: string;
  readonly _type = TypesEnum.CONSUMERS;

  owner: TypedEntityInterface;
  loyalty_tier: TypedEntityInterface;
  identity: TypedEntityInterface;
  name: NameFieldInterface;
  email: string;
  home_phone: string;
  cell_phone: string;
  card: UploadModel;
  card_number: string;
  card_expiration_date: Date;
  card_state: string;
  card_country: string;
  caregiver: boolean;
  caregiver_consumer: TypedEntityInterface;
  caregiver_card: UploadModel;
  caregiver_card_expiration_date: Date;
  caregiver_card_number: string;
  government_identification: UploadModel;
  government_identification_expiration_date: Date;
  government_identification_number: string;
  delivery_address: AddressModel;
  home_address: AddressModel;
  license_class: LicenseClassModel;
  gender: 'm' | 'f';
  date_of_birth: Date;
  agreement: UploadModel;
  out_of_state_agreement: UploadModel;
  gram_allotment: number;
  gram_allotment_updated_at: string;
  notes: string;
  active_checkin: TypedEntityInterface;
  active_order: TypedEntityInterface;
  discount: TypedEntityInterface;
  points_available: number;
  points_earned: number;
  points_spent: number;
  type: ConsumerTypeEnum;
  source: ConsumerSourceEnum;
  subscribed: boolean;
  subscribed_at: string;
  unsubscribed_at: string;
  verification_status: ConsumerVerificationStatusEnum;
  has_password: string;
  status: ConsumerStatusEnum;

  loyalty_rewards: LoyaltyRewardModel[] = [];
  adjusted_points_available: number;

  private today: moment.Moment;
  private nextMonth: moment.Moment;
  private _alerts: { message: string, severity: 'error' | 'warning' | 'info' }[] = [];

  constructor(consumer?: Partial<ConsumerModel>) {
    super();
    if (consumer) {
      Object.assign(this, consumer);

      this.delivery_address = consumer.delivery_address ? new AddressModel(consumer.delivery_address) : null;
      this.home_address = consumer.home_address ? new AddressModel(consumer.home_address) : null;

      this.today = moment();
      this.nextMonth = moment().add(30, 'days');

      this.setPendingRewardTotals();
      this.setAlerts();
    }
  }

  upsertLoyaltyReward(reward: LoyaltyRewardModel) {
    const index = this.loyalty_rewards.findIndex(pendingReward => pendingReward.uuid === reward.uuid);

    if (index > -1) {
      this.loyalty_rewards[index] = reward;
    } else {
      this.loyalty_rewards.push(reward);
    }

    this.setPendingRewardTotals();
    return this;
  }

  removeLoyaltyReward(reward: LoyaltyRewardModel) {
    this.loyalty_rewards = this.loyalty_rewards.filter(r => r.uuid != reward.uuid);
    this.setPendingRewardTotals();
    return this;
  }

  private setPendingRewardTotals() {
    this.adjusted_points_available = 0;

    if (!this.loyalty_rewards) return;

    this.adjusted_points_available = this.points_available - this.loyalty_rewards
      .filter(r => r.status === LoyaltyRewardStatusEnum.PENDING)
      .reduce((acc, curr) => acc + curr.points, 0);
  }

  get alerts(): { message: string, severity: 'warning' | 'error' | 'info' }[] {
    return this._alerts;
  }

  setAlerts() {
    const alerts: { message: string, severity: 'warning' | 'error' | 'info' }[] = [];

    if (this.license_class) {
      if (!this.government_identification) {
        alerts.push({ message: 'Missing Government ID', severity: 'warning' });
      }

      if (this.government_identification_expiration_date) {
        if (this.isExpired(this.government_identification_expiration_date)) {
          alerts.push({ message: 'Government ID has expired', severity: 'warning' });
        } else if (this.willExpire(this.government_identification_expiration_date)) {
          alerts.push({ message: 'Government ID expiring soon', severity: 'info' });
        }
      } else {
        alerts.push({ message: 'Missing Government ID expiration date', severity: 'warning' });
      }

      if (this.license_class.medical) {
        if (!this.card) {
          alerts.push({ message: 'Missing Medical Card', severity: 'warning' });
        }

        if (this.card_expiration_date) {
          if (this.isExpired(this.card_expiration_date)) {
            alerts.push({ message: 'Medical Card has expired', severity: 'warning' });
          } else if (this.willExpire(this.card_expiration_date)) {
            alerts.push({ message: 'Medical Card expiring soon', severity: 'info' });
          }
        } else {
          alerts.push({ message: 'Missing Medical Card expiration date', severity: 'warning' });
        }
      }

    } else {
      alerts.push({ message: 'No customer type defined', severity: 'warning' });
    }

    if (this.caregiver) {
      if (!this.caregiver_card) {
        alerts.push({ message: 'Missing Caregiver ID', severity: 'warning' });
      }

      if (this.caregiver_card_expiration_date) {
        if (this.isExpired(this.caregiver_card_expiration_date)) {
          alerts.push({ message: 'Caregiver ID has expired', severity: 'warning' });
        } else if (this.willExpire(this.caregiver_card_expiration_date)) {
          alerts.push({ message: 'Caregiver ID expiring soon', severity: 'info' });
        }
      } else {
        alerts.push({ message: 'Missing Caregiver ID expiration date', severity: 'warning' });
      }
    }

    this._alerts = alerts;
  }

  private isExpired(date: Date): boolean {
    return date ? this.today.isAfter(date) : false;
  }

  private willExpire(date: Date) {
    return date ? this.nextMonth.isAfter(date) : false;
  }
}
