import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { DateRangeFieldInterface as DateRangeFieldModel } from '@shared/model/field/date-range/date-range.interface';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { MaterialInputBase } from '../base/material-input';

@Component({
  selector: 'frontend-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [MatIconRegistry, { provide: MatFormFieldControl, useExisting: DateRangePickerComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
  }
})
export class DateRangePickerComponent extends MaterialInputBase
  implements OnInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {
  stateChanges = new Subject<void>();
  controlType = 'date-range-picker';

  dateRangeCtrl = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  _disabled: boolean = false;
  _placeholder: string;
  _required: boolean = false;
  errorState = false;
  focused: boolean;
  id: string;
  value: DateRangeFieldModel;

  @ViewChild(MatStartDate) startDateRef: MatStartDate<any>;
  @ViewChild(MatEndDate) endDateRef: MatEndDate<any>;

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() name: string;
  @Input() label: string;
  @Input() hint: string;
  @Input() includeTime: boolean;

  @Output() dateChanged = new EventEmitter<DateRangeFieldModel>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private _matIconRegistry: MatIconRegistry,
    private _fm: FocusMonitor,
    private _elRef: ElementRef<HTMLElement>,
    private _cdRef: ChangeDetectorRef
  ) {
    super(ngControl, _parentForm, _parentFormGroup, _defaultErrorStateMatcher);

    if (this.ngControl) this.ngControl.valueAccessor = this;

    _matIconRegistry.registerFontClassAlias('fal');
  }

  ngOnInit() {
    this.subscribeFocus();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._fm.stopMonitoring(this._elRef.nativeElement);
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    dis ? this.dateRangeCtrl.disable() : this.dateRangeCtrl.enable();
    this.stateChanges.next();
  }

  get empty() {
    return !this.value && !this.startDateRef?.value && !this.endDateRef?.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return (this.focused && !this._disabled) || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() !== 'input') {
        this._elRef.nativeElement.querySelector('input')?.focus();
      }
      this.onTouched(true);
    }
  }

  subscribeFocus() {
    this._fm.monitor(this._elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  writeValue(value: DateRangeFieldModel): void {
    if (value && 'start' in value && 'end' in value) {
      this.dateRangeCtrl.setValue({
        start: value.start,
        end: value.end
      });
      this.value = value;
    } else {
      this.dateRangeCtrl.reset();
      this.value = undefined;
    }

    if (!this._cdRef['destroyed']) {
      this._cdRef.detectChanges();
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateChange() {
    let { start, end } = this.dateRangeCtrl.value;

    if (start && end) {
      start = start.format('YYYY-MM-DD');
      end = end.format('YYYY-MM-DD');

      const value = this.includeTime ? this.convertUTC({ start, end }) : { start, end };

      this.onChange(value);
      this.dateChanged.emit(value);
    }
  }

  onChange(value: { start: string; end: string }) {}

  onTouched(value: any) {}

  onFocus($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  onClose() {
    this.onTouched(true);
  }

  convertUTC(value: { start: string; end: string; }) {
    return {
      start: moment(value.start)
        .startOf('day')
        .toISOString(),
      end: moment(value.end)
        .endOf('day')
        .toISOString()
    };
  }
}
