import { BrandParameters } from '@shared/entity/brand/service';
import { CashBalanceParameters } from '@shared/entity/cash-balance/service';
import { ProductCategoryParameters } from '@shared/entity/product-category/service';
import { ConsumerParameters } from '@shared/entity/consumer/service';
import { DeliveryParameters } from '@shared/entity/delivery/service';
import { DiscountParameters } from '@shared/entity/discount/service';
import { EmployeeParameters } from '@shared/entity/employee/service';
import { InventoryParameters } from '@shared/entity/inventory/service';
import { LicenseClassParameters } from '@shared/entity/license-class/service';
import { LocationParameters } from '@shared/entity/location/service';
import { OrderParameters } from '@shared/entity/order/service';
import { ProductParameters } from '@shared/entity/product/service';
import { StationParameters } from '@shared/entity/station/service';
import { UserAclParameters } from '@shared/entity/user-acl/service';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { OrganizationParameters } from '@shared/entity/organization/service';
import { MenuParameters } from '@shared/entity/menu/service';
import { ActorAclParameters } from '@shared/entity/actor-acl/service';
import { RegionParameters } from '@shared/entity/region/service';
import { ServiceParameters } from '@shared/entity/service/service';
import { RateFunctionParameters } from '@shared/entity/rate-function/service';
import { TaxParameters } from '@shared/entity/tax/service';
import { LocationProductParameters } from '@shared/entity/location-product/service';
import { FlowerSizeParameters } from '@shared/entity/flower-size/service';
import { UnitParameters } from '@shared/entity/unit/service';
import { TerpeneParameters } from '@shared/entity/terpene/service';
import { StrainParameters } from '@shared/entity/strain/service';
import { ProductTypeParameters } from '@shared/entity/product-type/service';
import { IntegrationProductParameters } from '@shared/entity/integration-product/service';
import { IntegrationOrderParameters } from '@shared/entity/integration-order/service';
import { IntegrationConfigurationParameters } from '@shared/entity/integration-configuration/service';
import { IntegrationErrorParameters } from '@shared/entity/integration-error/service';
import { CampaignBillingPlanParameters } from '@shared/entity/campaign-billing-plan/service';
import { MessageTriggerParameters } from '@shared/entity/message-trigger/service';
import { LoyaltyProgramParameters } from '@shared/entity/loyalty-program/service';
import { AbilityParameters } from '@shared/entity/ability/service';
import { LicenseParameters } from '@shared/entity/license/service';
import { LoyaltyTierParameters } from '@shared/entity/loyalty-tier/service';
import { UserParameters } from '@shared/entity/user/service';
import { EventParameters } from '@shared/entity/event/service';
import { DrawingParameters } from '@shared/entity/drawing/service';
import { IdentityParameters } from '@shared/entity/identity/service';
import { OffsetHttpParameters } from '../parameters/offset.parameters';
import { LoyaltyRewardParameters } from '@shared/entity/loyalty-reward/service';

// ATTN: Only add parameters generated from the @shared/entity lib

export class ApiParametersFactory {
  constructor() {}

  public create(type: TypesEnum) {

    switch (type) {
      case TypesEnum.ORDERS:
        return new OrderParameters();

      case TypesEnum.CONSUMERS:
        return new ConsumerParameters();

      case TypesEnum.PRODUCTS:
        return new ProductParameters();

      case TypesEnum.INVENTORY:
        return new InventoryParameters();

      case TypesEnum.BRANDS:
        return new BrandParameters();

      case TypesEnum.STATIONS:
        return new StationParameters();

      case TypesEnum.PRODUCT_CATEGORIES:
        return new ProductCategoryParameters();

      case TypesEnum.DISCOUNTS:
        return new DiscountParameters();

      case TypesEnum.USERS:
        return new UserParameters();

      case TypesEnum.EMPLOYEES:
        return new EmployeeParameters();

      case TypesEnum.LOCATIONS:
        return new LocationParameters();

      case TypesEnum.LICENSE_CLASSES:
        return new LicenseClassParameters();

      case TypesEnum.DELIVERIES:
        return new DeliveryParameters();

      case TypesEnum.CASH_JOBS:
        return new CashBalanceParameters();

      case TypesEnum.ORGANIZATIONS:
        return new OrganizationParameters();

      case TypesEnum.MENUS:
        return new MenuParameters();

      case TypesEnum.ACCESS_CONTROL:
        return new ActorAclParameters();

      case TypesEnum.USER_ACCESS_CONTROL:
        return new UserAclParameters();

      case TypesEnum.REGIONS:
        return new RegionParameters();

      case TypesEnum.SERVICES:
        return new ServiceParameters();

      case TypesEnum.RATE_FUNCTIONS:
        return new RateFunctionParameters();

      case TypesEnum.TAXES:
        return new TaxParameters();

      case TypesEnum.LICENSES:
        return new LicenseParameters();

      case TypesEnum.LOCATION_INVENTORY:
        return new LocationProductParameters();

      case TypesEnum.LOYALTY_REWARDS:
        return new LoyaltyRewardParameters();

      case TypesEnum.FLOWER_SIZES:
        return new FlowerSizeParameters();

      case TypesEnum.UNITS:
        return new UnitParameters();

      case TypesEnum.TERPENES:
        return new TerpeneParameters();

      case TypesEnum.STRAINS:
        return new StrainParameters();

      case TypesEnum.PRODUCT_TYPES:
        return new ProductTypeParameters();

      case TypesEnum.INTEGRATION_INVENTORY:
        return new IntegrationProductParameters();

      case TypesEnum.INTEGRATION_ORDERS:
        return new IntegrationOrderParameters();

      case TypesEnum.INTEGRATION_CONFIGURATIONS:
        return new IntegrationConfigurationParameters();

      case TypesEnum.INTEGRATION_ERRORS:
        return new IntegrationErrorParameters();

      case TypesEnum.CAMPAIGN_BILLING_PLANS:
        return new CampaignBillingPlanParameters();

      case TypesEnum.MESSAGE_TRIGGERS:
        return new MessageTriggerParameters();

      case TypesEnum.LOYALTY_PROGRAMS:
        return new LoyaltyProgramParameters();

      case TypesEnum.ABILITIES:
        return new AbilityParameters();

      case TypesEnum.LOYALTY_TIERS:
        return new LoyaltyTierParameters();

      case TypesEnum.EVENTS:
        return new EventParameters();

      case TypesEnum.DRAWINGS:
        return new DrawingParameters();

      case TypesEnum.IDENTITIES:
        return new IdentityParameters();

      case TypesEnum.BRAND_PRODUCTS:
        return new ProductParameters();

      default:
        return new OffsetHttpParameters();
    }
  }
}
