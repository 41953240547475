import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { OrganizationInterestModel } from '../model/organization-interest.model';
import { PagyCollectionInterface } from '@myblackbird/api/interface';
import { OrganizationInterestParameters } from './organization-interest.parameters';

@Injectable({ providedIn: 'root' })
export class OrganizationInterestService extends BaseApiService {
  readonly basePath = '/v2/organization_interests/';
  readonly uniqueIdentifier: string = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  loadCollection(
    params: OrganizationInterestParameters
  ): Observable<PagyCollectionInterface<OrganizationInterestModel>> {
    params.setLimit(1000);
    return this.http.post<PagyCollectionInterface<OrganizationInterestModel>>(
      `${this.getDoloresPath()}/search`,
      params?.getParams()
    );
  }

  insert(organizationInterest: OrganizationInterestModel) {
    return this.http.post<OrganizationInterestModel>(this.getDoloresPath(), organizationInterest);
  }

  update(organizationInterest: OrganizationInterestModel) {
    return this.http.patch<OrganizationInterestModel>(
      this.getDoloresPath(organizationInterest.uuid),
      organizationInterest
    );
  }

  delete(organizationInterest: OrganizationInterestModel) {
    return this.http.delete<OrganizationInterestModel>(this.getDoloresPath(organizationInterest.uuid));
  }

  enable(organizationInterest: OrganizationInterestModel) {
    return this.http.post<OrganizationInterestModel>(
      this.getDoloresPath(organizationInterest.uuid + '/enable'),
      organizationInterest
    );
  }

  disable(organizationInterest: OrganizationInterestModel) {
    return this.http.post<OrganizationInterestModel>(
      this.getDoloresPath(organizationInterest.uuid + '/disable'),
      organizationInterest
    );
  }
}
