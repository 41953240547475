export enum InventoryStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1,
  ARCHIVED = 2,
  PENDING = 255
}

export const ActiveStatus = {
  id: InventoryStatusEnum.ACTIVE,
  name: 'Active',
  color: 'green-600'
};

export const InactiveStatus = {
  id: InventoryStatusEnum.INACTIVE,
  name: 'Inactive',
  color: 'orange-600'
};

export const ArchivedStatus = {
  id: InventoryStatusEnum.ARCHIVED,
  name: 'Archived',
  color: 'red-600'
};

export const PendingStatus = {
  id: InventoryStatusEnum.PENDING,
  name: 'Pending',
  color: 'purple-600'
};

export const AllInventoryStatuses = [ActiveStatus, InactiveStatus, ArchivedStatus, PendingStatus];
