/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

type OrganizationOrderByTypes = 'organization.name' | 'organization.campaign_billing_plan';

export class OrganizationParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    organization: { name: string };
  }> = {};

  constructor(params?: Partial<OrganizationParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: OrganizationOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, organization: name ? { name: name } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
