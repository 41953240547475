import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormFieldErrorComponent } from '@shared/component/form';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule],
  declarations: [FormFieldErrorComponent],
  exports: [FormFieldErrorComponent]
})
export class FormFieldErrorModule {}
