import { EnumNameInterface } from "@shared/model/status/enum-name.interface";

export interface DeliveryStatusInterface extends EnumNameInterface {
  color: string;
}

export enum DeliveryStatusEnum {
  UNASSIGNED = 0,
  ASSIGNED   = 1,
  PICKED     = 2,
  ONTHEWAY   = 3,
  DELIVERED  = 4,
  PENDING    = 6,
  CANCELLED  = 7,
  REJECTED   = 8,
  ARCHIVED   = 255
}

export const UnassignedStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.UNASSIGNED,
  name: 'Unassigned',
  color: 'red-600'
};

export const AssignedStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.ASSIGNED,
  name: 'Assigned',
  color: 'orange-600'
};

export const PickedStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.PICKED,
  name: 'Picked',
  color: 'brown-500'
};

export const OnTheWayStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.ONTHEWAY,
  name: 'On The Way',
  color: 'cyan-700'
};

export const DeliveredStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.DELIVERED,
  name: 'Delivered',
  color: 'green-600'
};

export const RejectedStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.REJECTED,
  name: 'Rejected',
  color: 'pink-400'
};

export const PendingStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.PENDING,
  name: 'Pending',
  color: 'purple-600'
};

export const CancelledStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.CANCELLED,
  name: 'Cancelled',
  color: 'grey-400'
};

export const ArchivedStatus: DeliveryStatusInterface = {
  id: DeliveryStatusEnum.ARCHIVED,
  name: 'Archived',
  color: 'grey-400'
};


export const SelectableDeliveryStatuses: DeliveryStatusInterface[] = [
  UnassignedStatus,
  AssignedStatus,
  PickedStatus,
  OnTheWayStatus,
  DeliveredStatus,
  RejectedStatus,
  CancelledStatus
];

export const ViableDeliveryStatuses: DeliveryStatusInterface[] = [
  UnassignedStatus,
  AssignedStatus,
  PickedStatus,
  OnTheWayStatus,
  RejectedStatus
];

export const AllDeliveryStatuses: DeliveryStatusInterface[] = [
  UnassignedStatus,
  AssignedStatus,
  PickedStatus,
  OnTheWayStatus,
  DeliveredStatus,
  RejectedStatus,
  CancelledStatus,
  PendingStatus,
  ArchivedStatus
];
