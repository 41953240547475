import { Injectable } from '@angular/core';
import { AdminPolicyPermissionsEnum, AdminPolicyRolesEnum } from '@shared/policy';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject, Observable } from 'rxjs';
import { DATA_PERMISSIONS } from '../permissions/data.policy';
import { SUPPORT_PERMISSIONS } from '../permissions/support.policy';
import { SUPERADMIN_PERMISSIONS } from '../permissions/superadmin.policy';

export const AllAdminRoles = [{ name: 'superadmin' }, { name: 'data' }, { name: 'support' }];

@Injectable({ providedIn: 'root' })
export class AdminPolicyService {
  private loadingPolicies$ = new BehaviorSubject<boolean>(false);

  constructor(private ngxRolesService: NgxRolesService, private ngxPermissionsService: NgxPermissionsService) {}

  public get roles$() {
    return this.ngxRolesService.roles$;
  }

  public get policiesLoading$(): Observable<boolean> {
    return this.loadingPolicies$.asObservable();
  }

  public setLoading(): void {
    this.loadingPolicies$.next(true);
  }

  public setRoles(role: { name: string }): void {
    let AdminRole;

    switch (role.name) {
      case 'support':
        AdminRole = AdminPolicyRolesEnum.SUPPORT;
        break;

      case 'data':
        AdminRole = AdminPolicyRolesEnum.DATA;
        break;

      case 'superadmin':
        AdminRole = AdminPolicyRolesEnum.SUPERADMIN;
        break;
    }

    this.ngxRolesService.flushRoles();
    this.ngxPermissionsService.flushPermissions();

    const permissions = this.getPermissions(AdminRole);

    this.ngxPermissionsService.addPermission(permissions);
    this.ngxRolesService.addRole(AdminRole, permissions);

    this.loadingPolicies$.next(false);
  }

  private getPermissions(role: AdminPolicyRolesEnum): AdminPolicyPermissionsEnum[] {
    const permissions = {
      [AdminPolicyRolesEnum.DATA]: DATA_PERMISSIONS,
      [AdminPolicyRolesEnum.SUPPORT]: SUPPORT_PERMISSIONS,
      [AdminPolicyRolesEnum.SUPERADMIN]: SUPERADMIN_PERMISSIONS
    };

    return permissions[role];
  }
}
