/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { ConsumerInterestModel } from '../model/consumer-interest.model';
import { Observable } from 'rxjs';
import { CollectionInterface } from '@shared/model/collection/collection.interface';

@Injectable({ providedIn: 'root' })
export class ConsumerInterestService extends RestApiService<ConsumerInterestModel> {
  basePath = '/v2/consumers/{consumer-uuid}/interests';
  readonly uniqueIdentifier = 'uuid';

  constructor(private consumer: { uuid: string }, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{consumer-uuid}', consumer.uuid);
  }

  loadCollection(params?: any) {
    return this.http.get<CollectionInterface<ConsumerInterestModel>>(this.getDoloresPath());
  }

  put(entities: ConsumerInterestModel[] | any): Observable<ConsumerInterestModel[]> {
    return this.http.put<ConsumerInterestModel[]>(this.getDoloresPath(), { interests: entities });
  }
}
