<ng-template #singular>
  <input
    #searchInput
    matInput
    type="text"
    autocomplete="disabled"
    name="selectSearch"
    [placeholder]="placeholder"
    [matAutocomplete]="autoComplete"
    [disabled]="ngControl?.disabled"
    (input)="onSearch($event.target.value)"
    (focus)="onInputFocus($event)"
    [value]="displaySelectedOrDefaultFn(_value)"
  />

  <div *ngIf="_value && !ngControl?.disabled" matRipple class="clear-button" (click)="remove(_value)" data-cy="clear-field-button">
    <mat-icon fontSet="fal" fontIcon="fa-times" inline="true"></mat-icon>
  </div>
</ng-template>

<mat-chip-list *ngIf="multiple; else singular" #chipList [selectable]="false">
  <mat-chip *ngFor="let val of _value" [removable]="true" (removed)="remove(val)">
    {{ displayFn(val) }}
    <mat-icon fontSet="fal" fontIcon="fa-times-circle" matChipRemove></mat-icon>
  </mat-chip>

  <input
    #searchInput
    matInput
    type="text"
    name="selectSearchMultiple"
    autocomplete="disabled"
    [placeholder]="placeholder"
    [matChipInputFor]="chipList"
    [matAutocomplete]="autoComplete"
    [disabled]="ngControl?.disabled"
    (input)="onSearch($event.target.value)"
    (focus)="onInputFocus($event)"
  />
</mat-chip-list>

<mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelect($event)">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <mat-option *ngIf="defaultOption" [value]="defaultOption.value">{{ defaultOption.label }}</mat-option>
  <mat-option *ngIf="onCreateEntity" (click)="createSelected()">Create New</mat-option>
  <mat-option disabled *ngIf="!loading && records.length === 0 && !noResultsLink">{{ noResultsLabel }}</mat-option>
  <mat-option disabled *ngIf="!loading && records.length === 0 && noResultsLink">
    <a style="cursor: pointer;" (click)="onNoResultsLinkClick($event)">{{ noResultsLink }}</a>
  </mat-option>

  <ng-container *ngIf="recordTpl; else defaults">
    <mat-option *ngFor="let record of records" class="product" [value]="record">
      <ng-container [ngTemplateOutlet]="recordTpl" [ngTemplateOutletContext]="{ record: record }"></ng-container>
    </mat-option>
  </ng-container>

  <ng-template #defaults>
    <ng-container *ngFor="let record of records" [ngSwitch]="type">
      <mat-option class="product" [value]="record" *ngSwitchCase="types.PRODUCTS" [attr.data-cy]="record.name">
        <mat-card>
          <mat-card-title-group>
            <div
              *ngIf="record.image"
              mat-card-avatar
              [ngStyle]="{ 'background-image': 'url(' + record.image.url + ')' }"
            ></div>
            <mat-card-title>{{ record.name }}</mat-card-title>
            <mat-card-subtitle class="brand">{{ record.brand._name }}</mat-card-subtitle>
            <mat-card-subtitle class="category mat-small">Category: {{ record.category._name }}</mat-card-subtitle>
            <mat-card-subtitle class="category mat-small nowrap" *ngIf="record.parent">
              Blackbird Library Name: {{ record.parent._name }}
            </mat-card-subtitle>
          </mat-card-title-group>
        </mat-card>
      </mat-option>

      <mat-option class="product" [value]="record" *ngSwitchCase="types.INVENTORY">
        <mat-card>
          <mat-card-title-group>
            <div
              *ngIf="record.image"
              mat-card-avatar
              [ngStyle]="{ 'background-image': 'url(' + record.image.url + ')' }"
            ></div>
            <mat-card-title>{{ record.name }}</mat-card-title>
            <mat-card-subtitle class="unit-label">{{ record.unit_label }}</mat-card-subtitle>
            <mat-card-subtitle class="category mat-small" *ngIf="record.batch">Batch: {{ record.batch }}</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card>
      </mat-option>

      <mat-option class="product" [value]="record" *ngSwitchCase="types.BRAND_PRODUCTS">
        <mat-card>
          <mat-card-title-group>
            <div *ngIf="record.image" mat-card-avatar [ngStyle]="{ 'background-image': 'url(' + getImageUrl(record.image.url) + ')' }">
            </div>
            <mat-card-title>{{ record.name }}</mat-card-title>
            <mat-card-subtitle class="category mat-small">{{ record.category.name }}</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card>
      </mat-option>

      <mat-option *ngSwitchCase="types.QUERIES" [value]="record" class="query-option">
        <div>{{ record.name }}</div>
        <div>(est. phones: {{ record.result_phone_count | number: '1.0-0' }}, e-mails: {{ record.result_email_count | number: '1.0-0' }})</div>
      </mat-option>

      <mat-option class="default-option" [value]="record" *ngSwitchDefault [attr.data-cy]="record.name"> {{ displayFn(record) }}</mat-option>
    </ng-container>
  </ng-template>

  <mat-progress-bar *ngIf="loading && parameters.getOffset() > 0" mode="indeterminate"></mat-progress-bar>
</mat-autocomplete>
