import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { ThemeService } from '@admin/shared/services/theme';
import { AdminPolicyService } from '@admin/policy';
import { Store, select } from '@ngrx/store';
import { SessionModel } from '@shared/entity/session/model';
import { sessionQuery } from '@shared/entity/session/state';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  loading = true;

  themeWrapper: HTMLBodyElement = document.querySelector('body');

  theme: string;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private titleService: Title,
    private _adminPolicyService: AdminPolicyService,
    private _store: Store<unknown>
  ) {}

  ngOnInit() {
    this.subscribeSessionRoles();
    this.mapActiveRouteToTitle();
    this.initRouteLoader();
    this.listenForThemeChanges();
    this.themeService.loadLocalThemeOrDefault();
  }

  mapActiveRouteToTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.titleService.setTitle(event['label']);
      });
  }

  initRouteLoader() {
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  listenForThemeChanges() {
    this.themeService.theme.subscribe(res => {
      this.theme = res;
    });
  }

  handleThemeColorMapChange(colors) {
    this.themeWrapper.style.setProperty('--primaryThemeColor', colors.primaryThemeColor);
    this.themeWrapper.style.setProperty('--accentThemeColor', colors.accentThemeColor);
    this.themeWrapper.style.setProperty('--warnThemeColor', colors.warnThemeColor);
  }

  subscribeSessionRoles() {
    this._store.pipe(
      select(sessionQuery.getSession),
      tap((session: SessionModel) => {
        if (session && session.role) {
          this._adminPolicyService.setRoles(session.role);
        }
      }),
      filter((session: SessionModel) => session !== undefined)
      // take(1)
    );
  }
}
