/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

type LicenseClassOrderByTypes = 'license_class.name';

export class LicenseClassParameters extends OffsetHttpParameters {
  protected medical: boolean;
  protected searchJSON: Partial<{
    license_class: {
      name: string;
      type: number;
    };
    uuid: {
      in: string[]
    }
  }> = {};

  constructor(params?: Partial<LicenseClassParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: LicenseClassOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = {
      ...this.searchJSON,
      license_class: name
                     ? { ...this.searchJSON.license_class, name: name }
                     : { ...this.searchJSON.license_class, name: undefined }
    };
    return this;
  }

  setWhereType(type: number): this {
    this.searchJSON = {
      ...this.searchJSON,
      license_class: type
                     ? { ...this.searchJSON.license_class, type: type }
                     : { ...this.searchJSON.license_class, type: undefined }
    };
    return this;
  }

  setWhereMedical(medical?: boolean): this {
    this.medical = medical === true || medical === false ? medical : undefined;
    return this;
  }

  setWhereUuidIn(uuids?: string[]) {
    this.searchJSON = {
      ...this.searchJSON,
      uuid: uuids?.length ? { in: uuids } : undefined
    };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      medical: this.medical,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
