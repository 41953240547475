<mat-menu #menu="matMenu" class="theme-menu">
  <mat-grid-list cols="2" rowHeight="1:1">
    <mat-grid-tile>
      <button class="theme-button" mat-menu-item (click)="setTheme('blackbird-light-theme')">
        <svg viewBox="0 0 30 30" width="40" height="40">
          <defs>
            <clipPath id="left-half">
              <rect x="6" y="-15" width="15" height="30" transform="rotate(45)"></rect>
            </clipPath>
            <clipPath id="right-half"><rect x="15" y="0" width="15" height="30" /></clipPath>
          </defs>
          <g stroke="#F8F8F8">
            <circle cx="15" cy="15" r="13.5" style="fill:#353F3F;stroke-width:2" />
            <circle cx="15" cy="15" r="13.5" style="fill:#F8F8F8;" clip-path="url(#left-half)" />
          </g>
        </svg>
      </button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button class="theme-button" mat-menu-item (click)="setTheme('blackbird-dark-theme')">
        <svg viewBox="0 0 30 30" width="40" height="40">
          <defs>
            <clipPath id="left-half">
              <rect x="6" y="-15" width="15" height="30" transform="rotate(45)" />
            </clipPath>
            <clipPath id="right-half"><rect x="15" y="0" width="15" height="30" /></clipPath>
          </defs>
          <g stroke="#252525">
            <circle cx="15" cy="15" r="13.5" style="fill:#353F3F;stroke-width:2" />
            <circle cx="15" cy="15" r="13.5" style="fill:#252525;" clip-path="url(#left-half)" />
          </g>
        </svg>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</mat-menu>
