import { OffsetHttpParameters } from '@shared/utility/api';
import { CampaignBillingPlanStatusEnum } from '../enumeration';

export class CampaignBillingPlanParameters extends OffsetHttpParameters {
  private status: CampaignBillingPlanStatusEnum[];
  private searchJSON: Partial<{ name: { like: string } }> = {};

  constructor(params?: Partial<CampaignBillingPlanParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: 'name'): this {
    return super.setOrderBy(`campaign_billing_plan.${orderBy}`);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, name: name ? { like: name } : undefined };
    return this;
  }

  setWhereStatusIn(statuses: CampaignBillingPlanStatusEnum[]) {
    this.status = statuses && statuses.length ? statuses : undefined;
  }

  getParams(): { [p: string]: any } {
    return this.removeUndefinedValues({
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON),
      'status[]': this.status
    });
  }
}
