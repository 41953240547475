import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OffsetHttpParameters } from '@shared/utility/api';

export class EmployeeParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    has_role: { in: string[] };
    email: { distinct?: string; like?: string };
    has_access_control: { in: TypedEntityInterface[] };
    has_cell_phone: boolean;
    has_email: boolean;
    name: { like?: string; first?: { like: string }; last?: { like: string } };
  }> = {};

  constructor(params?: Partial<EmployeeParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereRoleIn(roles?: Array<{ name: string }>) {
    this.searchJSON = { ...this.searchJSON, has_role: roles ? { in: roles.map(role => role.name) } : undefined };
    return this;
  }

  setWhereEmail(email?: string) {
    this.searchJSON = {
      ...this.searchJSON,
      email: { ...this.searchJSON.email, distinct: email ? email : undefined }
    };
    return this;
  }

  setWhereEmailLike(email?: string) {
    this.searchJSON = {
      ...this.searchJSON,
      email: { ...this.searchJSON.email, like: email ? email : undefined }
    };
    return this;
  }

  setWhereAccessControlIn(target?: TypedEntityInterface[]) {
    this.searchJSON = { ...this.searchJSON, has_access_control: target ? { in: target } : undefined };
    return this;
  }

  setWhereFirstNameLike(name?: string) {
    this.searchJSON = {
      ...this.searchJSON,
      name: { ...this.searchJSON.name, first: name ? { like: name } : undefined }
    };
    return this;
  }

  setWhereLastNameLike(name?: string) {
    this.searchJSON = {
      ...this.searchJSON,
      name: { ...this.searchJSON.name, last: name ? { like: name } : undefined }
    };
    return this;
  }

  setWhereNameLike(name?: string) {
    this.searchJSON = {
      ...this.searchJSON,
      name: { ...this.searchJSON.name, like: name ? name : undefined }
    };
    return this;
  }

  setWhereName(name?: string) {
    this.setWhereNameLike(name);
    return this;
  }

  setWhereHasPhone(has_cell_phone?: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_cell_phone };
    return this;
  }

  setWhereHasEmail(has_email?: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_email };
    return this;
  }

  getParams() {
    return this.removeUndefinedValues({
      ...super.getParams(),
      ...(this.searchJSON ? { search: JSON.stringify(this.searchJSON) } : undefined)
    });
  }
}
