import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../../form';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrandAutocompleteComponent } from './brand-autocomplete.component';

@NgModule({
  declarations: [BrandAutocompleteComponent],
  imports: [
    CommonModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule, FormModule, MatProgressBarModule
  ],
  exports: [BrandAutocompleteComponent]
})
export class BrandAutocompleteModule {
}
