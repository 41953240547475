import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { InventoryModel } from '../../inventory/model';
import { InventoryTransferInterface } from '../model/inventory-transfer.interface';
import { InventoryTransferParameters } from './inventory-transfer.parameters';

@Injectable({ providedIn: 'root' })
export class InventoryTransferService extends BaseApiService {
  protected basePath = '/inventory/{inventory}/transfer/';

  constructor(inventory: InventoryModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{inventory}', inventory.uuid);
  }

  loadCollection(params?: InventoryTransferParameters): Observable<CollectionInterface<InventoryTransferInterface>> {
    return this.http.get<CollectionInterface<InventoryTransferInterface>>(this.getPath(), {
      params: params ? params.getParams() : undefined
    });
  }
}
