/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

export class TaxParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    tax: { name?: string };
  }> = {};

  constructor(params?: Partial<TaxParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereName(name?: string) {
    this.searchJSON = { ...this.searchJSON, tax: name ? { name: name } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
