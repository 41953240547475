import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { EntityInterface } from '@shared/model/entity/entity.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ErrorModel } from '@shared/model/error/error.model';
import { Observable, Subject } from 'rxjs';

import { RestApiService } from './rest-api.service';

export interface UploadProgressMapInterface {
  [key: string]: Observable<UploadProgressInterface>;
}

export interface UploadProgressInterface {
  percent: number;
  result: any;
  error: ErrorModel | null;
  file: File;
}

@Injectable()
export abstract class UploadService<T extends EntityInterface> extends RestApiService<T> {
  protected constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  bulkUpload(files: File[]): UploadProgressMapInterface {
    // this will be the our resulting map
    const status = {};

    files.forEach((file, index) => {
      // Generate a progress-observable for each file
      const progress$ = this.upload(file);

      // Save every progress-observable in a map of all observables
      status[file.name] = progress$;
    });

    // return the map of progress.observables
    return status;
  }

  upload(file: File, metaType: string = ''): Observable<UploadProgressInterface> {
    // create a new multipart-form for the file
    const formData: FormData = new FormData();
    formData.append('file', file, file.name ? file.name : 'file');
    formData.append('meta_type', metaType);

    // create a http-post request and pass the form
    // tell it to report the upload progress
    const req = new HttpRequest('POST', this.getPath(), formData, {
      reportProgress: true
    });

    // create a new progress-subject for the file
    const progress = new Subject<{
      percent: number;
      result: UploadModel;
      error: ErrorModel;
      file: File;
    }>();

    // send the http-request and subscribe for progress-updates
    this.http.request(req).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage
          const percentDone = Math.round((100 * event.loaded) / event.total);

          // pass the percentage into the progress-stream

          progress.next({
            percent: percentDone,
            result: null,
            error: null,
            file: null
          });
        } else if (event instanceof HttpResponse) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.next({
            percent: 100,
            result: <UploadModel>event.body,
            error: null,
            file: file
          });
          progress.complete();
        }
      },
      err => progress.next({ percent: 0, result: null, error: err, file: file })
    );

    return progress.asObservable();
  }

  // this is equivalent to bulkUpload's implementation, only rather than an array of files
  // it takes a k/v store w/ a file and it's associated meta type
  // not commonly used
  bulkUploadMWithMeta(files: {
    [key: string]: {
      file: File;
      metaType: string;
    };
  }): UploadProgressMapInterface {
    // this will be the our resulting map
    const status: UploadProgressMapInterface = {};

    const keys = Object.keys(files);

    for (const key of keys) {
      // Generate a progress-observable for each file
      const progress$ = this.upload(files[key].file, files[key].metaType);

      // Save every progress-observable in a map of all observables
      status[files[key].file.name] = progress$;
    }

    // return the map of progress.observables
    return status;
  }
}
