import { OffsetHttpParameters } from '@shared/utility/api';

type RateOrderByTypes = 'rate.name';

export class RateFunctionParameters extends OffsetHttpParameters {

  protected searchJSON: Partial<{ name: string }> = {};

  constructor(params?: Partial<RateFunctionParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: RateOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, name };
    return this;
  }
  
  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      query: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
