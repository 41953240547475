import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { LocationModel } from '../model/location.model';
import { LocationBrandService } from './brand/location-brand.service';
import { LocationContactService } from './contact/location-contact.service';
import { LocationMetaService } from './meta/location-meta.service';
import { LocationServiceService } from './service/location-service.service';
import { LocationTaxService } from './tax/location-tax.service';
import { LocationUploadService } from './upload/location-upload.service';
import { LocationMessageTriggerService } from './message-trigger/location-message-trigger.service';
import { LocationsMessageTriggersService } from './locations-message-triggers/locations-message-triggers.service';

@Injectable({ providedIn: 'root' })
export class LocationService extends RestApiService<LocationModel> {
  basePath = '/location/';
  readonly uniqueIdentifier = 'uuid';

  constructor(protected config: ApiConfig, protected http: HttpClient) {
    super(config, http);
  }

  getMetaService(location: LocationModel) {
    return new LocationMetaService(location, this.config, this.http);
  }

  getTaxService(location: LocationModel) {
    return new LocationTaxService(location, this.config, this.http);
  }

  getServiceService(location: LocationModel) {
    return new LocationServiceService(location, this.config, this.http);
  }

  getUploadService(location: LocationModel) {
    return new LocationUploadService(location, this.config, this.http);
  }

  getBrandService(location: LocationModel) {
    return new LocationBrandService(location, this.config, this.http);
  }

  getContactService(location: LocationModel) {
    return new LocationContactService(location, this.config, this.http);
  }

  getMessageTriggerService(location: LocationModel): LocationMessageTriggerService {
    return new LocationMessageTriggerService(location, this.config, this.http);
  }

  getLocationMessageTriggerService(location: LocationModel): LocationsMessageTriggersService {
    return new LocationsMessageTriggersService(location, this.config, this.http);
  }
}
