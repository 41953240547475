<button
  mat-flat-button
  [matMenuTriggerFor]="menu"
  [color]="buttonColor"
  [style.background]="buttonColor ? 'initial' : 'none'"
  class="active-root-route-button"
>
  {{ activeRootRoute | titlecase }} <mat-icon> arrow_drop_down </mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_CUSTOMER_SERVICE_CARD)">
    <a mat-menu-item [matMenuTriggerFor]="customerService" [routerLink]="'/customer-service'">
      Customer Service
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_CLIENTS_CARD)">
    <a mat-menu-item [matMenuTriggerFor]="clients" [routerLink]="'/clients'">
      Clients
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_MENUS_CARD)">
    <a mat-menu-item [matMenuTriggerFor]="menus" [routerLink]="'/menus'">
      Menus
    </a>
  </ng-container>
  <ng-container>
    <a
      *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_SETTINGS_CARD)"
      mat-menu-item
      [matMenuTriggerFor]="settings"
      [routerLink]="'/settings'"
    >
      Settings
  </a>
  </ng-container>
</mat-menu>

<mat-menu #customerService="matMenu">
  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_USER_CONSUMERS)">
    <a mat-menu-item [routerLink]="'/customer-service/user-consumers'">
      User Consumers
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_LOCATION_CONSUMERS)">
    <a mat-menu-item [routerLink]="'/customer-service/location-consumers'">
      Location Consumers
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_INTEGRATION_CONSUMERS)">
    <a mat-menu-item [routerLink]="'/customer-service/integration-consumers'">
      Integration Consumers
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_INTEGRATION_ORDERS)">
    <a mat-menu-item [routerLink]="'/customer-service/integration-orders'">
      Integration Orders
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_USERS)">
    <a mat-menu-item [routerLink]="'/customer-service/users'">
      Users
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_USER_ACL)">
    <a mat-menu-item [routerLink]="'/customer-service/user-access-control'">
      User Access
    </a>
  </ng-container>
</mat-menu>

<mat-menu #clients="matMenu">
  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_LICENSES)">
    <a mat-menu-item [routerLink]="'/clients/licenses'">Licenses</a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_LOCATIONS)">
    <a mat-menu-item [routerLink]="'/clients/locations'">Locations</a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_VEHICLES)">
    <a mat-menu-item [routerLink]="'/clients/vehicles'">Vehicles</a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_ORGANIZATIONS)">
    <a mat-menu-item [routerLink]="'/clients/organizations'">
      Organizations
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_CAMPAIGN_BILLING_PLANS)">
    <a mat-menu-item [routerLink]="'/clients/campaign-billing-plans'">
      Campaign Billing Plans
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_INTEGRATION_CONFIGURATIONS)">
    <a mat-menu-item [routerLink]="'/clients/integration-configurations'">
      Integration Configurations
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_INTEGRATION_ACTIVITY_LOGS)">
    <a mat-menu-item [routerLink]="'/clients/integration-activity-logs'">
      Integration Activity Logs
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_MESSAGE_ACCOUNTS)">
    <a mat-menu-item [routerLink]="'/clients/ms'">
      Message Service
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_SERVICE_TYPES)">
    <button mat-menu-item [routerLink]="'/clients/services'">
      Services
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_RATE_FUNCTIONS)">
    <button mat-menu-item [routerLink]="'/clients/rates'">
      Rates
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_SUBSCRIPTIONS)">
    <button mat-menu-item [routerLink]="'/clients/subscriptions'">
      Subscriptions
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_CAMPAIGN_BILLINGS)">
    <button mat-menu-item [routerLink]="'/clients/campaign-billings'">
      Campaigns
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_ADDONS)">
    <button mat-menu-item [routerLink]="'/clients/addons'">
      Addons
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_IDENTITIES)">
    <button mat-menu-item [routerLink]="'/clients/identities'">
      Identities
    </button>
  </ng-container>
</mat-menu>

<mat-menu #menus="matMenu">
  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_MENUS)">
    <a mat-menu-item [routerLink]="'/menus/menus'">
      Menus
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_LISTINGS)">
    <a mat-menu-item [routerLink]="'/menus/listings'">
      Listings
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_INVENTORY)">
    <a mat-menu-item [routerLink]="'/menus/inventory'">
      Inventory
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_INTEGRATION_INVENTORY)">
    <a mat-menu-item [routerLink]="'/menus/integration-inventory'">
      Integration Inventory
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_INTEGRATION_INVENTORY)">
    <a mat-menu-item [routerLink]="'/menus/integration-categories'">
      Integration Categories
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_LOCATION_PRODUCT)">
    <a mat-menu-item [routerLink]="'/menus/location-products'">
      POS Products
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_PRODUCTS)">
    <a mat-menu-item [routerLink]="'/menus/products/products'">
      Products
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_BRANDS)">
    <a mat-menu-item [routerLink]="'/menus/products/brands'">
      Brands
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_STRAINS)">
    <a mat-menu-item [routerLink]="'/menus/products/strains'">
      Strains
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_TERPENES)">
    <a mat-menu-item [routerLink]="'/menus/products/terpenes'">
      Terpenes
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_PRODUCT_CATEGORIES)">
    <a mat-menu-item [routerLink]="'/menus/products/categories'">
      Categories
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_PRODUCT_TYPES)">
    <a mat-menu-item [routerLink]="'/menus/products/product-types'">
      Types
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_FLOWER_SIZES)">
    <a mat-menu-item [routerLink]="'/menus/products/flower-sizes'">
      Flower Sizes
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_UNITS)">
    <a mat-menu-item [routerLink]="'/menus/products/units'">
      Units
    </a>
  </ng-container>
</mat-menu>

<mat-menu #settings="matMenu">
  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_LICENSE_CLASSES)">
    <a mat-menu-item [routerLink]="'/settings/license-classes'">
      License Classes
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_TAXES)">
    <a mat-menu-item [routerLink]="'/settings/location-settings/taxes'">
      Taxes
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_REGIONS)">
    <a mat-menu-item [routerLink]="'/settings/location-settings/regions'">
      Regions
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_MESSAGE_TRIGGERS)">
    <a mat-menu-item [routerLink]="'/settings/message-triggers'">
      Message Triggers
    </a>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_FEATURE_FLAGS)">
    <button mat-menu-item [routerLink]="'/settings/feature-flag'">
      Feature Flag
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_CANCELLATION_REASONS)">
    <button mat-menu-item [routerLink]="'/settings/cancellation-reasons'">
      Cancellation Reasons
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_ENTITY_ACLS)">
    <button mat-menu-item [routerLink]="'/settings/entity-acls'">
      Entity ACLs
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.VIEW_IMPORTS)">
    <button mat-menu-item [routerLink]="'/settings/imports'">
      Imports
    </button>
  </ng-container>

  <ng-container *ngIf="permissions?.includes(AdminPolicyPermissionsEnum.UPDATE_STOCK_IMAGES)">
    <a mat-menu-item [routerLink]="'/settings/stock-images/'">
      Stock Images
    </a>
  </ng-container>
</mat-menu>
