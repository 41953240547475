/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { IntegrationConfigurationModel } from '../../integration-configuration/model';
import { IntegrationOrderModel } from '../../integration-order/model';
import { TypesEnum } from '@shared/enumeration/type/types.enum';

export class IntegrationErrorParameters extends OffsetHttpParameters {

  protected searchJSON: { [key: string]: any } = {
    integration: undefined,
    regarding: undefined
  };

  constructor(params?: Partial<IntegrationErrorParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setWhereIntegration(
    integration?: Partial<IntegrationConfigurationModel>
  ) {
    this.searchJSON.integration = integration ? { distinct: integration.uuid } : undefined;
    return this;
  }

  setWhereRegarding(regarding?: Partial<IntegrationOrderModel>) {
    this.searchJSON.regarding = regarding ? { distinct: { uuid: regarding.uuid , type: TypesEnum.INTEGRATION_ORDERS }} : undefined;
    return this;
  }

  getParams(): { [p: string]: any } {
    return {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
  }
}
