import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OffsetHttpParameters } from '@shared/utility/api';

type LocationOrderByTypes = 'location.name';

export class LocationParameters extends OffsetHttpParameters {
  protected searchJSON: { [key: string]: any } = {
    location: undefined,
    organization: undefined,
    has_location_inventory: undefined,
    uuid: undefined,
    license_class: undefined,
    client: undefined
  };

  constructor(params?: Partial<LocationParameters>) {
    super();
    this.setOrderBy('location.name');
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: LocationOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string) {
    this.searchJSON.location = name ? { name } : undefined;
    return this;
  }

  setWhereHasLocationInventory(location?: Partial<TypedEntityInterface>) {
    this.searchJSON.has_location_inventory = location ? { distinct: location.uuid } : undefined;
    return this;
  }

  setWhereUuidIn(uuids?: string[]) {
    this.searchJSON.uuid = uuids ? { in: uuids } : undefined;
    return this;
  }

  setWhereLicenseClassIn(lcs?: { uuid: string }[]) {
    this.searchJSON.license_class = lcs ? { in: lcs } : undefined;
    return this;
  }

  setWhereStatusIn(status?: number[]) {
    this.searchJSON.status = status ? { in: status } : undefined;
    return this;
  }

  setWhereOrganization(organization?: Partial<TypedEntityInterface>) {
    this.searchJSON.organization = organization ? { distinct: organization.uuid } : undefined;
    return this;
  }

  setWhereClient(client?: boolean) {
    this.searchJSON.client = client ? 1 : undefined;
    return this;
  }

  getParams() {
    return {
      ...super.getParams(),
      ...this.getSearchJSON()
    };
  }

  getSearchJSON() {
    return { search: JSON.stringify(this.searchJSON) };
  }
}
