/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { LoyaltyProgramModel } from '../model/loyalty-program.model';

@Injectable({ providedIn: 'root' })
export class LoyaltyProgramService extends RestApiService<LoyaltyProgramModel> {
  basePath = '/loyalty/program/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  setAdminApiBasePath() {
    this.basePath = '/loyalty-program/';
    return this;
  }
}
