/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { ConsumerMessageModel } from '../model/consumer-message.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';

@Injectable({ providedIn: 'root' })
export class ConsumerMessageService extends RestApiService<ConsumerMessageModel> {
  basePath = '/consumer/{consumer-uuid}/message';
  readonly uniqueIdentifier = 'uuid';

  constructor(consumer: { uuid: string }, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{consumer-uuid}', consumer.uuid);
  }

  sendMessage(message: { body: string; regarding: TypedEntityInterface }) {
    return this.http.post<{status: string}>(this.getPath(), message);
  }
}
