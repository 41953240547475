import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'app-theme-emitter',
  template: `
    <div class="primary" #primary></div>
    <div class="accent" #accent></div>
    <div class="warn" #warn></div>
  `,
  styles: [':host { display: none; }']
})
export class ThemeEmitterComponent implements AfterViewInit {
  @Output() themeColorMapChange = new EventEmitter<any>();

  primaryThemeColor: string;
  accentThemeColor: string;
  warnThemeColor: string;

  @ViewChild('primary', { static: true })
  primaryElement: ElementRef;

  @ViewChild('accent', { static: true })
  accentElement: ElementRef;

  @ViewChild('warn', { static: true })
  warnElement: ElementRef;

  constructor(private themeService: ThemeService) {
    this.listenForThemeChanges();
  }

  listenForThemeChanges() {
    this.themeService.theme.subscribe(res => {
      this.updateColors();
    });
  }

  ngAfterViewInit() {
    this.updateColors();
  }

  updateColors() {
    this.primaryThemeColor = getComputedStyle(this.primaryElement.nativeElement).color;
    this.accentThemeColor = getComputedStyle(this.accentElement.nativeElement).color;
    this.warnThemeColor = getComputedStyle(this.warnElement.nativeElement).color;

    this.themeColorMapChange.emit({
      primaryThemeColor: this.primaryThemeColor,
      accentThemeColor: this.accentThemeColor,
      warnThemeColor: this.warnThemeColor
    });
  }
}
