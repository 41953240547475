import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { IConsumerData } from '../model/consumer.model';
import { ConsumerAddressService } from './consumer-address.service';
import { ConsumerInterestService } from '../../consumer-interest/service';
import { ConsumerUploadService } from './consumer-upload.service';
import { ConsumerMessageService } from '../../consumer-message/service';

@Injectable({ providedIn: 'root' })
export class ConsumerService extends RestApiService<IConsumerData> {
  basePath = '/consumer/';
  readonly uniqueIdentifier = 'uuid';

  constructor(protected config: ApiConfig, protected http: HttpClient) {
    super(config, http);
  }

  loadAllSnapshots(params: any): any {
    return this.http.get(this.getPath('snapshot/all'), { params });
  }

  getAddressService(consumer: { uuid: string }) {
    return new ConsumerAddressService(consumer, this.config, this.http);
  }

  getUploadService(consumer: { uuid: string }) {
    return new ConsumerUploadService(consumer, this.config, this.http);
  }

  getInterestService(consumer: { uuid: string }) {
    return new ConsumerInterestService(consumer, this.config, this.http);
  }

  getMessageService(consumer: { uuid: string }) {
    return new ConsumerMessageService(consumer, this.config, this.http);
  }
}
