import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { LocationContactModel, LocationModel } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class LocationContactService extends RestApiService<LocationContactModel> {
  basePath: string = '/location/{location}/contact/';
  readonly uniqueIdentifier: string = 'uuid';

  constructor(location: LocationModel, protected apiConfig: ApiConfig, protected http: HttpClient) {
    super(apiConfig, http);
    this.setBasePath(location);
  }

  setBasePath(location: LocationModel) {
    this.basePath = this.basePath.split('{location}').join(location.uuid);
  }
}
