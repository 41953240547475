/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { IntegrationOrderStatusEnum } from '../enumeration/integration-order-status.enum';
import { IntegrationOrderOriginationEnum } from '../enumeration/integration-order-origination.enum';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';

type IntegrationOrderOrderByTypes = 'customer.name' | 'updated_at';

export class IntegrationOrderParameters extends OffsetHttpParameters {

  protected searchJSON: Partial<{
    customer: { name: string };
    integration_configuration: { distinct: string };
    origination: { distinct: IntegrationOrderOriginationEnum };
    status: { distinct: IntegrationOrderStatusEnum };
  }> = {};

  constructor(params?: Partial<IntegrationOrderParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: IntegrationOrderOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereCustomerName(customerName?: string): this {
    this.searchJSON = { ...this.searchJSON, customer: customerName ? { name: customerName } : undefined };
    return this;
  }

  setWhereIntegrationConfiguration(integrationConfiguration?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, integration_configuration: integrationConfiguration ? { distinct: integrationConfiguration.uuid } : undefined };
    return this;
  }

  setWhereStatus(status?: IntegrationOrderStatusEnum): this {
    this.searchJSON = { ...this.searchJSON, status: status ? { distinct: status } : undefined };
    return this;
  }

  setWhereOrigination(origination?: IntegrationOrderOriginationEnum): this {
    this.searchJSON = { ...this.searchJSON, origination: origination ? { distinct: origination } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
