export enum TypesEnum {
  ABILITIES = 1,
  ACCESS_CONTROL = 2,
  ADDRESSES = 3,
  APPLIED_DISCOUNTS = 4,
  APPLIED_TAXES = 5,
  BRANDS = 6,
  CART_PRODUCTS = 7,
  CARTS = 8,
  CASHBANKS = 9,
  CONSUMER_ADDRESSES = 10,
  CONSUMER_UPLOADS = 11,
  CONSUMERS = 12,
  CULTIVATORS = 13,
  CUSTOMER_TYPE_TAXES = 14,
  CUSTOMER_TYPES = 15,
  DISCOUNTS = 16,
  DISPENSARIES = 17,
  FLOWER_SIZES = 18,
  INTEGRATION_CONFIGURATIONS = 19,
  INTEGRATION_ERRORS = 20,
  INTEGRATION_INVENTORY = 21,
  INTEGRATION_ORDERS = 22,
  INVENTORY = 23,
  LICENSE_CLASS_TAXES = 24,
  LICENSE_CLASSES = 25,
  LICENSES = 26,
  LOCATION_CHECKINS = 27,
  LOCATION_CONFIGURATION = 28,
  LOCATION_CUSTOMER_TYPES = 29,
  LOCATION_INVENTORY = 30,
  LOCATION_PRODUCT_CATEGORIES = 31,
  LOCATION_PURCHASE_ORDER_UPLOADS = 32,
  LOCATION_PURCHASE_ORDERS = 33,
  LOCATION_TAXES = 34,
  LOCATIONS = 35,
  MENU_PRODUCT_GROUPS = 36,
  MENU_PRODUCTS = 37,
  MENUS = 38,
  MESSAGE_USERS = 39,
  MIGRATIONS = 41,
  ORDER_PRODUCTS = 42,
  ORDERS = 43,
  ORGANIZATION_BRANDS = 44,
  ORGANIZATION_LICENSES = 45,
  ORGANIZATIONS = 46,
  PRODUCERS = 47,
  PRODUCT_CATEGORIES = 48,
  PRODUCT_CATEGORY_TAXES = 49,
  PRODUCT_FIELD_VALUE_TERPENE_LEVELS = 50,
  PRODUCT_FIELD_VALUES = 51,
  PRODUCT_TYPES = 52,
  PRODUCTS = 53,
  PURCHASE_ORDER_UPLOADS = 54,
  PURCHASE_ORDERS = 55,
  RATE_FUNCTIONS = 56,
  REFUND_ORDER_PRODUCTS = 57,
  REFUNDS = 58,
  ROLES = 59,
  SAFES = 60,
  SCHEDULES = 61,
  STATIONS = 62,
  STRAINS = 63,
  TAXES = 64,
  TERPENES = 65,
  TRANSACTIONS = 66,
  UPLOADS = 67,
  USER_ACCESS_CONTROL = 68,
  USER_ROLES = 69,
  USER_UPLOADS = 70,
  USERS = 71,
  VENDORS = 72,
  USER_CONSUMER = 73,
  CASH_JOBS = 74,
  DELIVERIES = 75,
  DELIVERY_LOGS = 76,
  AUTHENTICATION_METHODS = 78,
  LOCATION_CONTACTS = 79,
  REGIONS = 80,
  SERVICES = 81,
  UNITS = 82,
  LOYALTY_PROGRAMS = 83,
  LOYALTY_TRIGGERS = 84,
  LOYALTY_MULTIPLIERS = 85,
  LOYALTY_TRANSACTIONS = 86,
  LOYALTY_TIERS = 88,
  ICONS = 89,
  ICON_CATEGORIES = 90,
  INTERESTS = 91,
  INTEREST_CATEGORIES = 92,
  DRAWINGS = 94,
  DRAWING_WINNERS = 95,
  META = 96,
  VEHICLE_MAKES = 97,
  VEHICLE_MODELS = 98,
  VEHICLES = 99,
  EMPLOYEES = 100,
  LOYALTY_REWARDS = 101,
  COLLECT_EMBEDS = 102,
  RATES = 103,
  RATE_CONDITIONS = 104,
  PRODUCT_NOTIFICATIONS = 105,
  MESSAGE_TRIGGERS = 107,
  QUERIES = 109,
  CAMPAIGN_MESSAGES = 110,
  QUERY_CONDITIONS = 111,
  CAMPAIGN_BILLING_PLANS = 112,
  CAMPAIGN_BILLING_STATEMENTS = 113,
  EVENTS = 114,
  CHAINS = 115,
  IDENTITIES = 116,
  IMPORTS = 120,
  IMPORT_UPLOADS = 121,
  IMPORT_RESULTS = 122,
  ORGANIZATION_INTERESTS = 137,
  CONSUMER_INTEREST = 138,
  LOYALTY_IDENTITY_POINT = 139,

  // fake type ids for admin UI or for the API factory
  MENU_BRANDS = 999,
  BRAND_PRODUCTS = 998,
  LOCATIONS_SEARCH = 997,
  ENTITY_ACLS = 995,
  CAMPAIGN_BILLINGS = 994,
  MS_ACCOUNTS = 993,
  FEATURE_FLAGS = 992,
  MS_MESSAGES = 991,
}

export const TypesDisplayNames: { [key: number]: string } = {
  1: 'Ability',
  2: 'Access Control',
  3: 'Address',
  4: 'Applied Discount',
  5: 'Applied Tax',
  6: 'Brand',
  7: 'Cart Product',
  8: 'Cart',
  9: 'Cashbank',
  10: 'Consumer Address',
  11: 'Consumer Upload',
  12: 'Customer',
  13: 'Cultivator',
  14: 'Customer Type Tax',
  15: 'Customer Type',
  16: 'Discount',
  17: 'Dispensary',
  18: 'Flower Size',
  19: 'Integration Configuration',
  20: 'Integration Error',
  21: 'Integration Inventory',
  22: 'Integration Order',
  23: 'Inventory',
  24: 'License Class Tax',
  25: 'License Class',
  26: 'License',
  27: 'Location Checkin',
  28: 'Location Configuration',
  29: 'Location Customer Type',
  30: 'Location Inventory',
  31: 'Location Product Category',
  32: 'Location Purchase Order Upload',
  33: 'Location Purchase Order',
  34: 'Location Tax',
  35: 'Location',
  36: 'Menu Product Group',
  37: 'Menu Product',
  38: 'Menu',
  39: 'Message User',
  41: 'Migration',
  42: 'Order Product',
  43: 'Order',
  44: 'Organization Brand',
  45: 'Organization License',
  46: 'Organization',
  47: 'Producer',
  48: 'Category',
  49: 'Product Category Tax',
  50: 'Product Field Value Terpene Level',
  51: 'Product Field Value',
  52: 'Product Type',
  53: 'Product',
  54: 'Purchase Order Upload',
  55: 'Purchase Order',
  56: 'Rate Function',
  57: 'Refund Order Product',
  58: 'Refund',
  59: 'Role',
  60: 'Safe',
  61: 'Schedule',
  62: 'Station',
  63: 'Strain',
  64: 'Tax',
  65: 'Terpene',
  66: 'Transaction',
  67: 'Upload',
  68: 'User Access Control',
  69: 'User Role',
  70: 'User Upload',
  71: 'User',
  72: 'Vendor',
  73: 'User Consumer',
  74: 'Cash Job',
  75: 'Delivery',
  76: 'Delivery Log',
  77: 'Site',
  78: 'Authentication Method',
  79: 'Location Contact',
  80: 'Region',
  81: 'Service',
  82: 'Unit',
  83: 'Loyalty Program',
  84: 'Loyalty Trigger',
  85: 'Loyalty Multiplier',
  86: 'Loyalty Transaction',
  87: 'Loyalty Point',
  88: 'Loyalty Tier',
  89: 'Icon',
  90: 'Icon Category',
  91: 'Interest',
  92: 'Interest Category',
  94: 'Drawings',
  95: 'Drawing Winners',
  96: 'Meta',
  97: 'Vehicle Make',
  98: 'Vehicle Model',
  99: 'Vehicle',
  100: 'Employee',
  101: 'Loyalty Reward',
  102: 'Collect Embed',
  103: 'Rate',
  104: 'Rate Condition',
  105: 'Product Notification',
  107: 'Message Trigger',
  109: 'Query',
  110: 'Campaign Message',
  111: 'Query Condition',
  112: 'Campaign Billing Plan',
  113: 'Campaign Billing Statement',
  114: 'Event',
  115: 'Chain',
  116: 'Identity',
  117: 'Price Tag Groups',
  118: 'Price Tags',
  119: 'Price Tag Prices',
  120: 'Imports',
  121: 'Import Uploads',
  122: 'Import Results',
  137: 'Interest Tags',
  999: 'Menu Brands',
  998: 'Brand Products',
  995: 'Entity ACLs',
  994: 'Campaign Billings',
};
