import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextMaskModule } from 'angular2-text-mask';
import {
  ColorPickerInputModule,
  DatepickerModule,
  ImageUploaderModule,
  ScheduleInputModule,
  SmsTextareaModule,
  TabsModule,
  PhoneInputModule
} from '@frontend/component-lib';
import { CheckboxInputComponent } from './component/input-types/checkbox/input-checkbox.component';
import { ColorPickerInputComponent } from './component/input-types/color-picker/input-color-picker.component';
import { ContentComponent } from './component/input-types/content/content.component';
import { DateInputComponent } from './component/input-types/date/input-date.component';
import { DateRangeInputComponent } from './component/input-types/date-range/input-date-range.component';
import { DynamicFormComponent } from './component/form/dynamic-form.component';
import { DynamicFormInputComponent } from './component/input/dynamic-form-input.component';
import { SelectInputComponent } from './component/input-types/select/input-select.component';
import { SelectSearchInputComponent } from './component/input-types/ng-select-search/input-ng-select-search.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RadioInputComponent } from './component/input-types/radio/radio.component';
import { ScheduleInputComponent } from './component/input-types/schedule/input-schedule.component';
import { SmsTextareaInputComponent } from './component/input-types/sms-textarea/input-sms-textarea.component';
import { TextareaInputComponent } from './component/input-types/textarea/input-textarea.component';
import { TextboxInputComponent } from './component/input-types/textbox/input-textbox.component';
import { TimeInputComponent } from './component/input-types/time/input-time.component';
import { ToggleInputComponent } from './component/input-types/toggle/input-toggle.component';
import { UploadInputComponent } from './component/input-types/upload/input-upload.component';
import { WholeNumberInputComponent } from './component/input-types/whole-number/input-whole-number.component';
import { DecimalInputComponent } from './component/input-types/decimal/input-decimal.component';
import { ValidationModule } from '@shared/validation';
import { PhoneInputComponent } from './component/input-types/phone/input-phone.component';

import { DigitOnlyModule, DecimalModule } from '@frontend/component/shared';

import { DateRangePickerModule } from '@shared/material-inputs/date-range-picker';
import { SelectSearchModule } from '@shared/material-inputs/select-search';

@NgModule({
  imports: [
    ColorPickerInputModule,
    CommonModule,
    DatepickerModule,
    ImageUploaderModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    ScheduleInputModule,
    SelectSearchModule,
    SmsTextareaModule,
    TabsModule,
    TextMaskModule,
    ValidationModule,
    MatSelectModule,
    DateRangePickerModule,
    DigitOnlyModule,
    DecimalModule,
    PhoneInputModule
  ],
  declarations: [
    CheckboxInputComponent,
    ColorPickerInputComponent,
    ContentComponent,
    DateInputComponent,
    DateRangeInputComponent,
    DecimalInputComponent,
    DynamicFormComponent,
    DynamicFormInputComponent,
    ScheduleInputComponent,
    SelectInputComponent,
    SelectSearchInputComponent,
    RadioInputComponent,
    SmsTextareaInputComponent,
    TextareaInputComponent,
    TextboxInputComponent,
    TimeInputComponent,
    ToggleInputComponent,
    UploadInputComponent,
    WholeNumberInputComponent,
    PhoneInputComponent
  ],
  exports: [DynamicFormComponent],
  entryComponents: [
    CheckboxInputComponent,
    ColorPickerInputComponent,
    ContentComponent,
    DateInputComponent,
    DateRangeInputComponent,
    DecimalInputComponent,
    SelectInputComponent,
    SelectSearchInputComponent,
    RadioInputComponent,
    ScheduleInputComponent,
    SmsTextareaInputComponent,
    TextareaInputComponent,
    TextboxInputComponent,
    TimeInputComponent,
    ToggleInputComponent,
    UploadInputComponent,
    WholeNumberInputComponent,
    PhoneInputComponent
  ]
})
export class DynamicFormModule {}
