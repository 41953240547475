import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValidationModule } from '@shared/validation';
import { SmsTextareaComponent } from './sms-textarea.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ValidationModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatTooltipModule
  ],
  declarations: [SmsTextareaComponent],
  exports: [SmsTextareaComponent]
})
export class SmsTextareaModule {}
