import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { UploadService } from '@shared/utility/api';
import { DeliveryLogModel } from '../../delivery-log/model';
import { DeliveryModel } from './../../delivery/model/delivery.model';

@Injectable({ providedIn: 'root' })
export class DeliveryLogUploadService extends UploadService<UploadModel> {
  basePath = '/delivery/{delivery-uuid}/log/{log-uuid}/upload';
  readonly uniqueIdentifier = 'uuid';

  constructor(delivery: DeliveryModel, log: DeliveryLogModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{delivery-uuid}', delivery.uuid).replace('{log-uuid}', log.uuid);
  }
}
