/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { OrganizationModel } from '../model/organization.model';
import { OrganizationUploadService } from './upload/organization-upload.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationService extends RestApiService<OrganizationModel> {
  readonly basePath = '/organization/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  getUploadService(organization: OrganizationModel) {
    return new OrganizationUploadService(organization, this.config, this.http);
  }

  getMe(): Observable<OrganizationModel> {
    return this.http.get<OrganizationModel>(this.getPath('me'));
  }
}
