/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { MenuOrderModel } from '../model/menu-order.model';
import { MenuModel } from '../../menu/model';
import { MenuOrderProductService } from '../../menu-order-product/service';

@Injectable({ providedIn: 'root'})
export class MenuOrderService extends RestApiService<MenuOrderModel> {

  basePath = '/menu/{menu-uuid}/order/';
  readonly uniqueIdentifier = 'uuid';

  constructor(private menu: MenuModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{menu-uuid}', menu.uuid);
  }

  getProductService(order: MenuOrderModel): MenuOrderProductService {
    return new MenuOrderProductService(this.menu, order, this.config, this.http);
  }
}
