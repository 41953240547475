/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { PurchaseOrderModel } from '../model/purchase-order.model';
import { PurchaseOrderUploadService } from './upload/purchase-order-upload.service';

@Injectable({ providedIn: 'root'})
export class PurchaseOrderService extends RestApiService<PurchaseOrderModel> {

  readonly basePath = '/purchase-order/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  getUploadService(purchaseOrder: PurchaseOrderModel) {
    return new PurchaseOrderUploadService(purchaseOrder, this.config, this.http);
  }

}
