import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorsComponent } from './component/validation-errors/validation-errors.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [ValidationErrorsComponent],
  exports: [ValidationErrorsComponent]
})
export class ValidationModule {}
