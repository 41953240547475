import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';
import { NameFieldInterface } from '@shared/model/field/name/name-field.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import { ConsumerSourceEnum, ConsumerTypeEnum, ConsumerVerificationStatusEnum } from '../enumeration';
import { ConsumerModel } from '../model';

type ConsumerOrderByTypes = 'name.first' | 'name.last' | 'email' | 'home_phone' | 'status' | 'cell_phone';

export class ConsumerParameters extends OffsetHttpParameters {
  owner_type: { in: TypesEnum[] };
  timezone: string;
  protected checked_in: boolean;
  protected searchJSON: Partial<{
    location: { distinct: string };
    integration_configuration: { distinct: string };
    license_class: { distinct: string };
    loyalty_tier: { distinct: string };
    identity: { distinct: string };
    email: string;
    cell_phone: { equals: string } | { like: string };
    home_phone: { like: string };
    name: Partial<{ first: string; last: string }>;
    card_numbers: { like: string };
    type: ConsumerTypeEnum;
    source: ConsumerSourceEnum;
    created_at: DateRangeFieldInterface;
    subscribed_at: DateRangeFieldInterface;
    unsubscribed_at: DateRangeFieldInterface;
    subscribed: boolean;
    has_subscribed_at: boolean;
    has_unsubscribed_at: boolean;
    verification_status: { distinct: ConsumerVerificationStatusEnum };
    medical: boolean;
    owner_type: { in: TypesEnum[] };
    timezone: string;
    caregiver_consumer: { distinct: string };
    caregiver: boolean;
  }> = {};

  constructor(params?: Partial<ConsumerParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: ConsumerOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereLocation(location?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereIntegrationConfiguration(integrationConfiguration?: TypedEntityInterface): this {
    this.searchJSON = {
      ...this.searchJSON,
      integration_configuration: integrationConfiguration ? { distinct: integrationConfiguration.uuid } : undefined
    };
    return this;
  }

  setWhereLicenseClass(licenseClass?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, license_class: licenseClass ? { distinct: licenseClass.uuid } : undefined };
    return this;
  }

  setWhereLoyaltyTier(loyaltyTier?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, loyalty_tier: loyaltyTier ? { distinct: loyaltyTier.uuid } : undefined };
    return this;
  }

  setWhereIdentity(identity?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, identity: identity ? { distinct: identity.uuid } : undefined };
    return this;
  }

  setWhereEmail(email?: string): this {
    this.searchJSON = { ...this.searchJSON, email };
    return this;
  }

  setWhereCellPhoneEquals(phoneNumber?: string): this {
    this.searchJSON = { ...this.searchJSON, cell_phone: phoneNumber ? { equals: phoneNumber } : undefined };
    return this;
  }

  setWhereCellPhonelike(phoneNumber?: string): this {
    this.searchJSON = { ...this.searchJSON, cell_phone: phoneNumber ? { like: phoneNumber } : undefined };
    return this;
  }

  setWhereHomePhonelike(phoneNumber?: string): this {
    this.searchJSON = { ...this.searchJSON, home_phone: phoneNumber ? { like: phoneNumber } : undefined };
    return this;
  }

  setWhereNameLike(name?: Partial<NameFieldInterface>): this {
    this.searchJSON = { ...this.searchJSON, name };
    return this;
  }

  setWhereCardNumberlike(cardNumber?: string): this {
    this.searchJSON = { ...this.searchJSON, card_numbers: cardNumber ? { like: cardNumber } : undefined };
    return this;
  }

  setWhereType(type?: ConsumerTypeEnum): this {
    this.searchJSON = { ...this.searchJSON, type };
    return this;
  }

  setWhereSource(source?: ConsumerSourceEnum): this {
    this.searchJSON = { ...this.searchJSON, source };
    return this;
  }

  setWhereCreatedAt(created_at?: DateRangeFieldInterface): this {
    this.searchJSON = { ...this.searchJSON, created_at };
    return this;
  }

  setWhereSubscribedAt(subscribed_at?: DateRangeFieldInterface): this {
    this.searchJSON = { ...this.searchJSON, subscribed_at };
    return this;
  }

  setWhereUnsubscribedAt(unsubscribed_at?: DateRangeFieldInterface): this {
    this.searchJSON = { ...this.searchJSON, unsubscribed_at };
    return this;
  }

  setWhereSubscribed(subscribed?: boolean): this {
    this.searchJSON = { ...this.searchJSON, subscribed };
    return this;
  }

  setWhereHasSubscribed(has_subscribed_at?: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_subscribed_at };
    return this;
  }

  setWhereHasUnsubscribed(has_unsubscribed_at?: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_unsubscribed_at };
    return this;
  }

  setWhereVerificationStatus(status?: ConsumerVerificationStatusEnum): this {
    this.searchJSON = { ...this.searchJSON, verification_status: status ? { distinct: status } : undefined };
    return this;
  }

  setWhereMedical(medical?: boolean): this {
    this.searchJSON = { ...this.searchJSON, medical };
    return this;
  }

  setWhereOwnerType(status?: TypesEnum | TypesEnum[]) {
    if (Array.isArray(status))
      this.searchJSON = {
        ...this.searchJSON,
        owner_type: status.length ? { in: status } : undefined
      };
    else this.searchJSON = { ...this.searchJSON, owner_type: status ? { in: [status] } : undefined };
    return this;
  }

  setWhereTimezone(timezone?: string) {
    this.searchJSON = { ...this.searchJSON, timezone };
    return this;
  }

  setWhereCaregiverConsumer(consumer?: ConsumerModel | TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, caregiver_consumer: consumer ? { distinct: consumer.uuid } : undefined };
    return this;
  }

  setWhereCaregiver(caregiver?: boolean): this {
    this.searchJSON = { ...this.searchJSON, caregiver };
    return this;
  }

  getParams(): { [param: string]: any } {
    let search = undefined;
    if (this.searchJSON && Object.keys(this.removeUndefinedValues(this.searchJSON)).length > 0) {
      search = JSON.stringify(this.searchJSON);
    }

    const params = {
      ...super.getParams(),
      search
    };
    return this.removeUndefinedValues(params);
  }
}
