import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ColorPickerModule } from 'ngx-color-picker';
import { ValidationModule } from '@shared/validation';
import { ColorPickerInputComponent } from './color-picker-input.component';

@NgModule({
  imports: [
    ColorPickerModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ValidationModule
  ],
  declarations: [ColorPickerInputComponent],
  exports: [ColorPickerInputComponent]
})
export class ColorPickerInputModule {}
