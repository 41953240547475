import { UserModel } from '@app/myblackbird/src/app/shared/model/user/user.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import { ConsumerSourceEnum } from '../../consumer/enumeration';
import { StationModel } from '../../station/model';
import { OrderOrderByEnum, OrderStatusEnum } from '../enumeration';
import { LocationServiceWindowModel } from '../../location-service-window/model';

export class OrderParameters extends OffsetHttpParameters {
  protected timezone: string;
  protected status: OrderStatusEnum[] = [];
  protected transfer_method: number[] = [];

  protected searchJSON: Partial<{
    has_menu: boolean;
    has_delivery: boolean;
    updated_at: DateRangeFieldInterface;
    created_at: DateRangeFieldInterface;
    transacted_at: DateRangeFieldInterface;
    station: { distinct: { uuid: string } };
    license_class: { in: { uuid: string }[] };
    loyalty_tier: { distinct: string };
    transactor: { distinct: string } | { name: string };
    customer: Partial<{ name: string; source: ConsumerSourceEnum }> | { distinct: { uuid: string; _type: number } };
    user: { distinct: { uuid: string } };
    service_window: { in: { uuid: string }[] };
    customer_or_recipient: { in: TypedEntityInterface[] } | { name: string };
  }> = {};

  constructor(params?: Partial<OrderParameters | OffsetHttpParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  // TODO replace orderby string references with enum
  setOrderBy(orderBy?: OrderOrderByEnum | string): this {
    return super.setOrderBy(orderBy);
  }

  setWhereStatus(statuses?: OrderStatusEnum[]) {
    this.status = statuses ? statuses : [];
    return this;
  }

  setWhereStation(station?: StationModel) {
    this.searchJSON = { ...this.searchJSON, station: station ? { distinct: { uuid: station.uuid } } : undefined };
    return this;
  }

  setWhereHasDelivery(hasDelivery?: boolean) {
    this.searchJSON = { ...this.searchJSON, has_delivery: hasDelivery !== undefined ? hasDelivery : undefined };
    return this;
  }

  setWhereTransferMethod(transfer_methods?: number[]) {
    this.transfer_method = transfer_methods ? transfer_methods : [];
    return this;
  }

  setWhereHasMenu(hasMenu?: boolean) {
    this.searchJSON = { ...this.searchJSON, has_menu: hasMenu !== undefined ? hasMenu : undefined };
    return this;
  }

  setWhereCustomerName(name?: string) {
    this.searchJSON = {
      ...this.searchJSON,
      customer: name ? { ...this.searchJSON.customer, name } : this.searchJSON.customer
    };
    return this;
  }

  setWhereCustomerSource(source?: ConsumerSourceEnum) {
    this.searchJSON = {
      ...this.searchJSON,
      customer: source ? { ...this.searchJSON.customer, source } : this.searchJSON.customer
    };
    return this;
  }

  setWhereCustomer(customer?: TypedEntityInterface) {
    const distinctCustomer = customer ? { uuid: customer.uuid, _type: customer._type } : null;
    this.searchJSON = {
      ...this.searchJSON,
      customer: customer ? { distinct: distinctCustomer } : this.searchJSON.customer
    };
    return this;
  }

  setWhereCustomerLicenseClassIn(licenseClasses?: TypedEntityInterface[]) {
    const licenseClassesByUuid =
      licenseClasses && licenseClasses.length
        ? licenseClasses.map(licenseClass => ({ uuid: licenseClass.uuid }))
        : undefined;
    this.searchJSON = {
      ...this.searchJSON,
      license_class: licenseClassesByUuid ? { in: licenseClassesByUuid } : undefined
    };
    return this;
  }

  setWhereLoyaltyTier(loyaltyTier?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, loyalty_tier: loyaltyTier ? { distinct: loyaltyTier.uuid } : undefined };
    return this;
  }

  setWhereTimeZone(timezone?: string) {
    this.timezone = timezone;
    return this;
  }

  setWhereTransactedAt(dateRange?: DateRangeFieldInterface) {
    this.searchJSON = { ...this.searchJSON, transacted_at: dateRange ? dateRange : undefined };
    return this;
  }

  setWhereTransactor(transactor?: TypedEntityInterface) {
    this.searchJSON = {
      ...this.searchJSON,
      customer: transactor ? { distinct: { uuid: transactor.uuid, _type: transactor._type } } : this.searchJSON.customer
    };
    return this;
  }

  setWhereUser(user?: UserModel) {
    this.searchJSON = { ...this.searchJSON, user: user ? { distinct: { uuid: user.uuid } } : undefined };
    return this;
  }

  setWhereServiceWindowIn(serviceWindows?: LocationServiceWindowModel[]) {
    const serviceWindowsByUuid =
      serviceWindows && serviceWindows.length
        ? serviceWindows.map(serviceWindow => ({ uuid: serviceWindow.uuid }))
        : undefined;
    this.searchJSON = {
      ...this.searchJSON,
      service_window: serviceWindowsByUuid ? { in: serviceWindowsByUuid } : undefined
    };
    return this;
  }

  setWhereCreatedAt(dateRange?: DateRangeFieldInterface) {
    this.searchJSON = { ...this.searchJSON, created_at: dateRange ? dateRange : undefined };
    return this;
  }

  setWhereUpdatedAt(dateRange?: DateRangeFieldInterface) {
    this.searchJSON = { ...this.searchJSON, updated_at: dateRange ? dateRange : undefined };
    return this;
  }

  setWhereOrderNumber(orderNumber?: string) {
    if (orderNumber) {
      try {
        const orderCreatedDate = new Date(+orderNumber.slice(0, -3) * 1000).toISOString();
        this.searchJSON = { ...this.searchJSON, created_at: { start: orderCreatedDate, end: orderCreatedDate } };
      } catch (e) {
        if (e instanceof RangeError) {
          this.searchJSON = { ...this.searchJSON, created_at: undefined };
        }
      }
    } else {
      this.searchJSON = { ...this.searchJSON, created_at: undefined };
    }

    return this;
  }

  getParams(): any {
    return this.removeUndefinedValues({
      ...super.getParams(),
      ...(this.status ? { 'status[]': this.status } : undefined),
      ...(this.transfer_method ? { 'transfer_method[]': this.transfer_method } : undefined),
      ...(this.timezone ? { timezone: this.timezone } : undefined),
      ...(this.searchJSON ? { search: JSON.stringify(this.searchJSON) } : undefined)
    });
  }
}
