import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ProductCategoryModel } from '../../product-category/model';

export class ProductModel extends EntityModel {
  readonly _type = TypesEnum.PRODUCTS;

  public name: string;
  public slug: string;
  public description: string;
  public location: TypedEntityInterface;
  public parent: TypedEntityInterface;
  public strain: TypedEntityInterface;
  public category: ProductCategoryModel;
  public type: TypedEntityInterface;
  public brand: TypedEntityInterface;
  public image: UploadModel;
  public normalized: boolean;
  public show_on_brand: boolean;
  public inherit_parent_fields: boolean;

  constructor(product?: Partial<ProductModel>) {
    super();
    if (product) Object.assign(this, product);
  }
}
