import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BrandModel } from '../../model';
import { RestApiService } from '@shared/utility/api';
import { ProductModel } from '../../../product/model';

@Injectable({ providedIn: 'root' })
export class BrandProductService extends RestApiService<ProductModel> {
  basePath = '/brand/{brand-uuid}/product/';
  readonly uniqueIdentifier = 'uuid';

  constructor(brand: BrandModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{brand-uuid}', brand.uuid);
  }
}
