import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { DiscountModel } from '../../discount/model';
import { OrderModel } from '../../order/model';

@Injectable({ providedIn: 'root' })
export class OrderDiscountService extends BaseApiService {
  basePath = '/order/{order-uuid}/discount/';

  constructor(private order: OrderModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{order-uuid}', order.uuid);
  }

  insert(discount: DiscountModel): Observable<DiscountModel> {
    return this.http.post<DiscountModel>(this.getPath(), discount);
  }

  delete(discount: DiscountModel): Observable<DiscountModel> {
    return this.http.delete<DiscountModel>(this.getPath(discount.uuid));
  }
}
