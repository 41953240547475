/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService, OffsetHttpParameters } from '@shared/utility/api';
import { DrawingModel } from '../model/drawing.model';

@Injectable({ providedIn: 'root' })
export class DrawingService extends RestApiService<DrawingModel> {
  readonly basePath = '/drawing/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }
}
