import {
  LOCALE_ID,
  Inject,
  Component,
  HostBinding,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Optional,
  Self,
  DoCheck
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { InputMasks, Utility } from '../localization';

@Component({
  selector: 'cl-phone-input',
  templateUrl: './phone-input.component.html',
  providers: [{ provide: MatFormFieldControl, useExisting: PhoneInputComponent }]
})
export class PhoneInputComponent
  implements OnInit, DoCheck, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {
  mask: Array<RegExp | String> | Boolean = false;
  locale: string;

  @Input() label: string;
  @Input() hint: string;
  @Input() normalize: boolean;
  @Input() private: boolean;

  /*
    MatFormFieldControl Properties
  */
  value: string;
  stateChanges = new Subject<void>();
  controlType = 'phone-input';
  focused = false;
  errorState = false;

  static nextId = 0;
  @HostBinding() id = `cl-phone-input-${PhoneInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  private _placeholder: string = '';

  get empty() {
    return !this.value;
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(required) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(disabled) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }

  private _disabled = false;

  /*
    ControlValueAccessor Properties
  */

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Inject(LOCALE_ID) locale: string
  ) {
    this.locale = locale;
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.generateInputMask();
    this.subscribeFocus();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  /*
  MatFormFieldControl Methods
  */

  subscribeFocus() {
    this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  /*
    ControlValueAccessor Methods
  */

  writeValue(value: string) {
    if (value) this.value = value;
  }

  onChange(value: string) {}

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  onTouched(value: string) {}

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  generateInputMask() {
    this.mask = InputMasks.Phone[this.locale];
  }

  onInput($event) {
    this.value = $event.target.value;
    if (this.normalize) {
      this.onChange(Utility.normalizePhoneNumber(this.value));
    } else {
      this.onChange(this.value);
    }
    this.stateChanges.next();
  }
}
