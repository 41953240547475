import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { InventoryTransferService } from "../../inventory-transfer/service";
import { InventoryUploadService } from '../../inventory-upload/service/inventory-upload.service';
import { InventoryDataInterface, InventoryModel } from '../model/inventory.model';

@Injectable({ providedIn: 'root' })
export class InventoryService extends RestApiService<InventoryDataInterface> {
  readonly basePath = '/inventory/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  getUploadService(inventory: InventoryModel): InventoryUploadService {
    return new InventoryUploadService(inventory, this.config, this.http);
  }

  getTransferService(inventory: InventoryModel): InventoryTransferService {
    return new InventoryTransferService(inventory, this.config, this.http);
  }
}
