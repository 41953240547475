import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateStatusPipe } from './pipes/date-status.pipe';
import { EntityStatusPipe } from './pipes/entity-status.pipe';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { NamePipe } from './pipes/name.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SecondsConversionPipe } from './pipes/seconds-conversion.pipe';
import { SubscriberStatusPipe } from './pipes/customer/subscriber-status.pipe';
import { TypeNamePipe } from './pipes/type-name.pipe';
import { ZeroPlaceholderPipe } from './pipes/zero-placeholder.pipe';
import { InventoryWeightPipe } from './pipes/inventory-weight.pipe';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { ObfuscatePhonePipe } from './pipes/obfuscate-phone.pipe';
import { CapitalizeIntegrationNamePipe } from './pipes/capitalize-integration-name.pipe';
import { CarrierNameFromSlugPipe } from './pipes/carrier-name-from-slug.pipe';
import { ProductFilterPipe } from './pipes/product-filter.pipe';
import { CityNamePipe } from './pipes/city-name.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DateStatusPipe,
    EntityStatusPipe,
    ImageSizePipe,
    NamePipe,
    PhonePipe,
    ZeroPlaceholderPipe,
    TypeNamePipe,
    SecondsConversionPipe,
    InventoryWeightPipe,
    AbsoluteValuePipe,
    SubscriberStatusPipe,
    ObfuscatePhonePipe,
    CapitalizeIntegrationNamePipe,
    CarrierNameFromSlugPipe,
    ProductFilterPipe,
    CityNamePipe
  ],
  exports: [
    DateStatusPipe,
    EntityStatusPipe,
    ImageSizePipe,
    NamePipe,
    PhonePipe,
    ZeroPlaceholderPipe,
    TypeNamePipe,
    SecondsConversionPipe,
    InventoryWeightPipe,
    AbsoluteValuePipe,
    SubscriberStatusPipe,
    ObfuscatePhonePipe,
    CapitalizeIntegrationNamePipe,
    CarrierNameFromSlugPipe,
    ProductFilterPipe,
    CityNamePipe
  ],
  providers: [CurrencyPipe, DecimalPipe, ObfuscatePhonePipe]
})
export class PipesModule {}
