import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FeatureFlagApiService } from './feature-flag-api.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagStateService {
  private flags$ = new BehaviorSubject<string[]>([]);

  constructor(private service: FeatureFlagApiService) {
  }

  public init(): Observable<string[]> {
    return this.service.getAll().pipe(
      catchError(() => of([])),
      tap(flags => this.flags$.next(flags))
    );
  }

  public getAllFlags(): Observable<string[]> {
    return this.flags$.asObservable();
  }

  public isFlagEnabled$(flagName: string): Observable<boolean> {
    return this.flags$.pipe(
      map(flags => (flags.includes(flagName))));
  }

  public isFlagEnabled(flagName: string): boolean {
    return this.flags$.getValue().includes(flagName);
  }
}
