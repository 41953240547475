import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { IConsumerData } from '../consumer/model';
import { RestApiService } from '@shared/utility/api';
import { AddressModel } from '@shared/model/entity/address/address.model';

export class DeliveryConsumerAddressService extends RestApiService<IConsumerData> {
  baseUrl = this.config.baseUrl;
  basePath = `/delivery/${this.delivery}/consumer/address`;
  readonly uniqueIdentifier = 'uuid';

  constructor(
    private delivery: { uuid: string },
    config: ApiConfig,
    http: HttpClient
  ) {
    super(config, http);
  }

  loadConsumerAddress(delivery: { uuid: string }): Observable<{ records: AddressModel[] }>{
    return this.http.get<{ records: AddressModel[] }>(this.baseUrl.concat(this.basePath));
  }

}
