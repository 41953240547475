import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { BrandModel } from '../../brand/model';

export class OrganizationModel extends EntityModel {
  readonly _type = TypesEnum.ORGANIZATIONS;

  public name: string;
  public color: string;
  public website: string;
  public email: string;
  public description: string;
  public color_logo: UploadModel;
  public white_logo: UploadModel;
  public brands: BrandModel[];
  public campaign_billing_plan: TypedEntityInterface;
  public connect_types: ('campaign_sms' | 'campaign_mms' | 'campaign_email' | 'single_sms' | 'single_mms' | 'single_email')[];
  public featured_flags: string[];

  constructor(organization?: Partial<OrganizationModel>) {
    super();
    if (organization) Object.assign(this, organization);
  }
}
