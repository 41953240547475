/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { NameFieldInterface } from '@shared/model/field/name/name-field.interface';

export class UserParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    site: { distinct: string };
    name: Partial<{ first: string; last: string }>;
    email: string;
    role: { distinct: string };
  }> = {};

  constructor(params?: Partial<UserParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereNameLike(name?: Partial<NameFieldInterface>): this {
    this.searchJSON = { ...this.searchJSON, name };
    return this;
  }

  setWhereEmailLike(email?: string) {
    this.searchJSON = { ...this.searchJSON, email: email ? email : undefined };
    return this;
  }

  setWhereRole(role?: string) {
    this.searchJSON = { ...this.searchJSON, role: role ? { distinct: role } : undefined };
    return this;
  }

  getParams(): any {
    return this.removeUndefinedValues({
      ...super.getParams(),
      ...(this.searchJSON ? { search: JSON.stringify(this.searchJSON) } : undefined)
    });
  }
}
