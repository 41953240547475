import { LicenseClassModel } from '@shared/entity/license-class/model';
import { DeliveryStatusEnum } from '@shared/enumeration/status/delivery/delivery.enum';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { AddressModel } from '@shared/model/entity/address/address.model';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';
import { VehicleInterface } from '@myblackbird/api/interface';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

export class DeliveryModel extends EntityModel {
  readonly _type = TypesEnum.DELIVERIES;
  public delivered_at: string;
  public tracking_number: string;
  public distance: number;
  public scheduled_at: DateRangeFieldInterface;
  public status: DeliveryStatusEnum;
  public service: TypedEntityInterface;
  public attached_to: TypedEntityInterface;
  public custody_user: TypedEntityInterface;
  public custody_location: TypedEntityInterface;
  public custody_timezone: string;
  public merchant: TypedEntityInterface;
  public tax_remitting_location: TypedEntityInterface;
  public custody_license: LicenseClassModel;
  public custody_name: string;
  public custody_address: AddressModel;
  public custody_license_number: string;
  public created_by_user: TypedEntityInterface;
  public created_by_location: TypedEntityInterface;
  public delivery_fee: number;
  public invoice_number: string;
  public billed: boolean;
  public abs: number;
  public net: number;
  public net_from: number;
  public net_to: number;
  public eta: number;
  public cod: boolean; //Collect on Delivery
  public collect_amount: number;
  public parcel_value: number;
  public parcel_count: number;
  public return_cash_balance_requested: boolean;
  public notes: string;
  public distributor_notes: string;
  public to_name: string;
  public from_name: string;
  public to_address: AddressModel;
  public from_address: AddressModel;
  public from_contact_name: string;
  public from_contact_phone: number;
  public to_contact_name: string;
  public to_contact_phone: number;
  public from: TypedEntityInterface;
  public to: TypedEntityInterface;
  public from_license: LicenseClassModel;
  public from_license_number: string;
  public to_license: LicenseClassModel;
  public to_license_number: string;
  public vehicle: VehicleInterface;

  constructor(delivery?: Partial<DeliveryModel>) {
    super();
    if (delivery) Object.assign(this, delivery);

    // BUGBUG: PHP is returning numbers in its payloads, but Ruby is returning strings.
    this.abs = +this.abs;
    this.collect_amount = +this.collect_amount;
    this.delivery_fee = +this.delivery_fee;
    this.distance= +this.distance;
    this.net= +this.net;
    this.net_from= +this.net_from;
    this.net_to= +this.net_to;
    this.parcel_count= +this.parcel_count;
    this.parcel_value= +this.parcel_value;


    this.to_address = new AddressModel(this.to_address);
    this.from_address = new AddressModel(this.from_address);
  }

  get custody_location_scheduled_start(): moment.Moment {
    return this.toCustodyLocationTime(this.scheduled_at.start);
  }

  get custody_location_scheduled_end(): moment.Moment {
    return this.toCustodyLocationTime(this.scheduled_at.end);
  }

  isToConsumer() {
    return [TypesEnum.CONSUMERS, TypesEnum.LOCATION_CHECKINS].includes(this.to._type);
  }

  isToLocation() {
    return this.to._type === TypesEnum.LOCATIONS;
  }

  isAttachedToCashJob() {
    return this.attached_to && this.attached_to._type === TypesEnum.CASH_JOBS;
  }

  isRetail() {
    return this.isToConsumer();
  }

  isCashJob() {
    return this.isAttachedToCashJob();
  }

  isWholesale() {
    return this.isToLocation() && !this.isCashJob();
  }

  isViable() {
    return this.status !== DeliveryStatusEnum.CANCELLED && this.status !== DeliveryStatusEnum.PENDING;
  }

  toCustodyLocationTime(time: string): moment.Moment {
    return momentTimezone(time).tz(this.custody_timezone);
  }
}
