import { EntityInterface } from '@shared/model/entity/entity.interface';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { ProductModel } from '../../product/model';
import { AllAvailableInventoryZones, InventoryStatusEnum, MeasurementTypeEnum } from '../enumeration';
import { FlowerSizeInterface } from './flower-size.interface';
import { TerpeneLevelInterface } from './terpene-level.interface';
import { UnitInterface } from './unit.interface';
import { TypesEnum } from '@shared/enumeration/type/types.enum';

export interface InventoryDataInterface extends EntityInterface {
  name: string;
  attribute_name: string;
  sku: string;
  batch: string;
  parent: TypedEntityInterface;
  product: ProductModel;
  location: TypedEntityInterface;
  owner: TypedEntityInterface;
  originating_location: TypedEntityInterface;
  purchase_order: TypedEntityInterface;
  integration_product: TypedEntityInterface;
  wholesale_cost: number;
  price: number;
  thc_mg: number;
  thc_percent: number;
  cbd_mg: number;
  cbd_percent: number;
  cbn_mg: number;
  cbn_percent: number;
  weight_grams: number;
  flower_size: FlowerSizeInterface;
  pack_size: number;
  measurement_type: MeasurementTypeEnum;
  unit: UnitInterface;
  unit_quantity: number;
  unit_label: string;
  pack_label: string;
  serving_size: string;
  serving_count: number;
  ingredients: string;
  allergens: string;
  extraction_method: string;
  case_size: number;
  terpene_levels: TerpeneLevelInterface[];
  batch_date: string;
  test_date: string;
  package_date: string;
  expiration_date: string;
  tracked: boolean;
  featured: boolean;
  stock: number;
  stock_front: number;
  stock_back: number;
  stock_sample: number;
  stock_unsellable: number;
  stock_receiving: number;
  stock_shipping: number;
  stock_quarantine: number;
  stock_destroyed: number;
  tax_rate: number;
  initial_stock: number;
  status: InventoryStatusEnum;
  available_online: boolean;
  transfer_notes?: string;
  brand?: string;
  strain?: string;
  category?: string;
  description?: string;
  image_url?: string;
}

export class InventoryModel extends EntityModel implements InventoryDataInterface {
  name: string;
  full_name: string;
  attribute_name: string;
  sku: string;
  batch: string;
  parent: TypedEntityInterface;
  product: ProductModel;
  location: TypedEntityInterface;
  owner: TypedEntityInterface;
  originating_location: TypedEntityInterface;
  purchase_order: TypedEntityInterface;
  integration_product: {
    uuid: string;
    _type: TypesEnum;
    _name?: string;
    deleted_at?: string
  };
  wholesale_cost: number;
  price: number;
  thc_mg: number;
  thc_percent: number;
  cbd_mg: number;
  cbd_percent: number;
  cbn_mg: number;
  cbn_percent: number;
  weight_grams: number;
  flower_size: FlowerSizeInterface;
  pack_size: number;
  measurement_type: MeasurementTypeEnum;
  unit: UnitInterface;
  unit_quantity: number;
  unit_label: string;
  pack_label: string;
  serving_size: string;
  serving_count: number;
  ingredients: string;
  allergens: string;
  extraction_method: string;
  case_size: number;
  terpene_levels: TerpeneLevelInterface[];
  batch_date: string;
  test_date: string;
  package_date: string;
  expiration_date: string;
  tracked: boolean;
  featured: boolean;
  stock: number;
  stock_front: number;
  stock_back: number;
  stock_sample: number;
  stock_unsellable: number;
  stock_receiving: number;
  stock_shipping: number;
  stock_quarantine: number;
  stock_destroyed: number;
  tax_rate: number;
  initial_stock: number;
  status: InventoryStatusEnum;
  available_online: boolean;
  transfer_notes?: string;
  brand?: string;
  strain?: string;
  category?: string;
  description?: string;
  image_url?: string;

  constructor(inventory?: Partial<InventoryModel>) {
    super();
    if (inventory) Object.assign(this, inventory);
  }

  hasRemainingStock(): boolean {
    return AllAvailableInventoryZones.reduce((result, zone) => (result += this[zone.key]), 0) > 0;
  }
}
