import { TypesEnum } from '../types.enum';

// Get the proper type based on singular camel case format
export class TypeFactory {
  public get(type: string) {
    switch (type) {
      case 'location':
        return TypesEnum.LOCATIONS;

      case 'station':
        return TypesEnum.STATIONS;

      case 'employee':
        return TypesEnum.EMPLOYEES;

      case 'multiplier':
        return TypesEnum.LOYALTY_MULTIPLIERS;

      case 'trigger':
        return TypesEnum.LOYALTY_TRIGGERS;

      case 'tier':
        return TypesEnum.LOYALTY_TIERS;

      case 'drawing':
        return TypesEnum.DRAWINGS;

      case 'campaignMessage':
        return TypesEnum.CAMPAIGN_MESSAGES;

      case 'discount':
        return TypesEnum.DISCOUNTS;

      case 'event':
        return TypesEnum.EVENTS;

      case 'order':
        return TypesEnum.ORDERS;

      case 'consumer':
        return TypesEnum.CONSUMERS;

      case 'reward':
        return TypesEnum.LOYALTY_REWARDS;

      case 'collectEmbed':
        return TypesEnum.COLLECT_EMBEDS;

      case 'menu':
        return TypesEnum.MENUS;

      case 'inventory':
        return TypesEnum.INVENTORY;

      case 'integrationInventory':
        return TypesEnum.INTEGRATION_INVENTORY;

      case 'locationProduct':
        return TypesEnum.LOCATION_INVENTORY;

      case 'product':
        return TypesEnum.PRODUCTS;

      case 'strain':
        return TypesEnum.STRAINS;

      case 'productCategory':
        return TypesEnum.PRODUCT_CATEGORIES;

      case 'productType':
        return TypesEnum.PRODUCT_TYPES;

      case 'flowerSize':
        return TypesEnum.FLOWER_SIZES;

      case 'license':
        return TypesEnum.LICENSES;

      case 'location':
        return TypesEnum.LOCATIONS;

      case 'organization':
        return TypesEnum.ORGANIZATIONS;

      case 'brand':
        return TypesEnum.BRANDS;

      case 'campaignBillingPlan':
        return TypesEnum.CAMPAIGN_BILLING_PLANS;

      case 'userConsumer':
        return TypesEnum.USER_CONSUMER;

      case 'consumer':
        return TypesEnum.CONSUMERS;

      case 'user':
        return TypesEnum.USERS;

      case 'userAcl':
        return TypesEnum.USER_ACCESS_CONTROL;

      case 'integrationOrder':
        return TypesEnum.INTEGRATION_ORDERS;

      case 'entityAcl':
        return TypesEnum.ACCESS_CONTROL;

      case 'terpene':
        return TypesEnum.TERPENES;

      case 'licenseClass':
        return TypesEnum.LICENSE_CLASSES;

      case 'tax':
        return TypesEnum.TAXES;

      case 'region':
        return TypesEnum.REGIONS;

      case 'rateFunction':
        return TypesEnum.RATE_FUNCTIONS;

      case 'service':
        return TypesEnum.SERVICES;

      case 'messageTrigger':
        return TypesEnum.MESSAGE_TRIGGERS;

      case 'integrationConfiguration':
        return TypesEnum.INTEGRATION_CONFIGURATIONS;

      case 'integrationError':
        return TypesEnum.INTEGRATION_ERRORS;

      case 'unit':
        return TypesEnum.UNITS;

      default:
        console.error('type not added to typeFactory');
    }
  }
}
