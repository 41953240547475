/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';

type IntegrationConfigurationOrderByTypes = 'configuration.name';

export class IntegrationConfigurationParameters extends OffsetHttpParameters {

  protected searchJSON: Partial<{
    integration_configuration: { name: { like: string } };
    location: { distinct: string };
  }> = {};

  constructor(params?: Partial<IntegrationConfigurationParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: IntegrationConfigurationOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string) {
    this.searchJSON = { ...this.searchJSON, integration_configuration: name ? { name: { like: name } } : undefined };
    return this;
  }

  setWhereLocation(location?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
