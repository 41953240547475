import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { LoyaltyRewardStatusEnum } from '../enumeration';

export class LoyaltyRewardModel extends EntityModel {
  location: TypedEntityInterface;
  loyalty_program: TypedEntityInterface;
  consumer: TypedEntityInterface;
  checkin: TypedEntityInterface;
  order: TypedEntityInterface;
  discount: TypedEntityInterface;
  discount_target_type: number;
  discount_targets: TypedEntityInterface[];
  points: number;
  notes: string;
  status: LoyaltyRewardStatusEnum;

  constructor(loyaltyReward?: Partial<LoyaltyRewardModel>) {
    super();
    if (loyaltyReward) Object.assign(this, loyaltyReward);
  }
}
