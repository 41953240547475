import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { OrderProductDiscountService } from '../../order-product-discount/service';
import { OrderModel } from '../../order/model';
import { OrderProductModel } from '../model/order-product.model';

@Injectable({ providedIn: 'root' })
export class OrderProductService extends RestApiService<OrderProductModel> {
  basePath = '/order/{order-uuid}/product/';
  readonly uniqueIdentifier = 'uuid';

  constructor(private order: OrderModel, protected config: ApiConfig, protected http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{order-uuid}', order.uuid);
  }

  getDiscountService(product: OrderProductModel): OrderProductDiscountService {
    return new OrderProductDiscountService(this.order, product, this.config, this.http);
  }
}
