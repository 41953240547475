import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ImageSizePipe } from '@shared/pipes';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OffsetHttpParameters, RestApiService } from '@shared/utility/api';
import { ApiParametersFactory } from '@shared/utility/api/factory/api-parameters.factory';
import { ApiServiceFactory } from '@shared/utility/api/factory/api-service.factory';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, takeUntil } from 'rxjs/operators';
import { MaterialInputBase } from '../base/material-input';
import { defaultDisplayFn } from './helpers';

@Component({
  selector: 'frontend-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectSearchComponent }, ImageSizePipe]
})
export class SelectSearchComponent<T extends EntityModel> extends MaterialInputBase
  implements OnChanges, OnInit, OnDestroy, MatFormFieldControl<T>, ControlValueAccessor {
  value: T;

  /*
   * MatFormFieldControl Properties
   */

  id: string;
  stateChanges = new Subject<void>();
  controlType = 'select-search';
  errorState = false;
  _value: T | T[];

  @HostBinding('attr.aria-describedby') describedBy = '';
  _onChange: any = () => {};
  _onTouched: any = () => {};
  focused = false;
  types = TypesEnum;
  search$ = new Subject<string>();
  loading = true;
  canLoadMore: boolean;
  service: RestApiService<T>;
  records: T[] = [];

  /*
   * ControlValueAccessor Properties
   */
  query: string;
  @Input() type: TypesEnum;

  /*
   * Business Logic Properties
   */
  @Input() dependentEntity: TypedEntityInterface;
  @Input() parameters: OffsetHttpParameters;
  @Input() noResultsLabel = 'No Results Found';
  @Input() noResultsLink: string;
  @Input() multiple = false;
  @Input() onCreateEntity: () => void;
  @Input() defaultOption: { label: string; value: any };
  @Input() displayFn: (value: any) => string = defaultDisplayFn;
  @Input() excludeFn: (entity: T) => boolean;
  @Input() recordTpl: TemplateRef<unknown>;
  @Input() loadRecordsOnInit: boolean = true;
  @Output() handleNoResultsLinkClick = new EventEmitter();
  @Output() selected = new EventEmitter();
  @Output() reset = new EventEmitter();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoComplete', { static: true }) autoCompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger: MatAutocompleteTrigger;
  protected destroy$ = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private apiServiceFactory: ApiServiceFactory,
    private apiParametersFactory: ApiParametersFactory,
    private imageSizePipe: ImageSizePipe
  ) {
    super(ngControl, _parentForm, _parentFormGroup, _defaultErrorStateMatcher);

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = <ControlValueAccessor>this;
    }
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return (this.focused && !this._disabled) || !this.empty;
  }

  private _placeholder = 'Select';

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  get empty() {
    if (!this._value && this.defaultOption) return false;

    if (this._value) {
      return this._value instanceof Array ? this._value.length === 0 : false;
    }

    return true;
  }

  private _required = false;

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input() valueFn: (value: any) => EntityModel = value => value;

  /*
   * Lifecycle Hooks
   */

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type'] && this.type) {
      if (this.parameters === undefined) {
        this.parameters = <any>this.apiParametersFactory.create(this.type);
      }

      this.service = <any>this.apiServiceFactory.create(this.type, this.dependentEntity);
      if (typeof this._value === 'string' && this.service && !this.multiple) this.loadSelectedRecordByUuid(this._value);
      if (!changes['type'].firstChange || this.loadRecordsOnInit) this.loadRecords();
    }

    if (changes['dependentEntity'] && this.type) {
      this.service = <any>this.apiServiceFactory.create(this.type, this.dependentEntity);
      if (typeof this._value === 'string' && this.service && !this.multiple) this.loadSelectedRecordByUuid(this._value);
      this.loadRecords();
    }

    if (changes['parameters']) {
      if (this.parameters === undefined) this.parameters = new OffsetHttpParameters();
      this.loadRecords();
    }

    if (changes['displayFn'] && this.displayFn === undefined) {
      this.displayFn = defaultDisplayFn;
    }

    if (changes['multiple']) {
      this._value = this.coerceSingleOrMultipleValue(this._value);
    }

    if (changes['defaultOption'] && this.defaultOption !== undefined) {
      if (!this._value) this.writeValue(this.defaultOption.value);
    }
  }

  ngOnInit() {
    this.subscribeSearch();
    this.subscribeFocus();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  /*
   * ControlValueAccessor Methods
   */

  writeValue(value) {
    if (typeof value === 'string' && this.service && !this.multiple) {
      this.loadSelectedRecordByUuid(value);
      return;
    }
    this._value = this.coerceSingleOrMultipleValue(value);
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  /*
   * MatFormFieldControl Methods
   */

  onNoResultsLinkClick($event) {
    this.handleNoResultsLinkClick.emit($event);
  }

  subscribeFocus() {
    this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      if (!this.focused && !this._value && this.defaultOption) {
        this.searchInput.nativeElement.value = this.defaultOption.label;
        this.search$.next(this.defaultOption.value);
      } else if (
        !this.focused &&
        this.searchInput.nativeElement.value &&
        (!this._value || (Array.isArray(this._value) && (<T[]>this._value).length === 0))
      ) {
        this.searchInput.nativeElement.value = '';
        this.search$.next('');
      } else if (this.focused) {
        this.onScroll();
      }

      this.stateChanges.next();
    });
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() !== 'input') {
        this.elRef.nativeElement.querySelector('input')!.focus();
      }
      this.openPanel(event);
      this._onTouched();
    }
  }

  onInputFocus($event: FocusEvent) {
    this.openPanel($event);
  }

  openPanel(event) {
    event.stopPropagation();
    this.autoCompleteTrigger.openPanel();
    this.onScroll();
  }

  /*
   * Business Logic Methods
   */

  subscribeSearch() {
    this.search$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(query => {
        this.parameters.setOffset(0).setWhereName(query);
        this.loadRecords();
      });

    this.search$
      .pipe(
        filter(() => !!this._value && !Array.isArray(this._value)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.searchInput.nativeElement.value = '';
        this._value = undefined;
        this._onChange(undefined);
      });
  }

  onSearch($event) {
    this.parameters.setOffset(0);
    this.search$.next($event);
  }

  displaySelectedOrDefaultFn(value): string {
    return value
           ? this.displayFn(this._value)
           : this.defaultOption && this.defaultOption.label
             ? this.defaultOption.label
             : '';
  }

  loadMore() {
    if (this.canLoadMore) {
      this.parameters.setOffset(this.parameters.getLimit() + this.parameters.getOffset());
      this.loadRecords();
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    this.searchInput.nativeElement.value = '';

    if (this.multiple) {
      const newValue = this._value ? <T[]>this._value : [];
      const index = newValue.findIndex(x => x.uuid === value.uuid);
      if (index === -1) {
        newValue.push(<any>this.valueFn(event.option.value));
        this._value = newValue;
        this._onChange(this._value);
        this.selected.emit(this._value);
      }
    } else {
      this._value = event.option.value;
      this.searchInput.nativeElement.value = this.displaySelectedOrDefaultFn(event.option.value);
      this._onChange(this.valueFn(this._value));
      this.selected.emit(this.valueFn(this._value));
    }

    this.stateChanges.next();
  }

  createSelected() {
    if (this.onCreateEntity) this.onCreateEntity();
  }

  onScroll() {
    setTimeout(() => {
      if (this.autoCompleteRef && this.autoCompleteTrigger && this.autoCompleteRef.panel) {
        fromEvent(this.autoCompleteRef.panel.nativeElement, 'scroll')
          .pipe(
            map(() => this.autoCompleteRef.panel.nativeElement.scrollTop),
            takeUntil(this.autoCompleteRef.closed)
          )
          .subscribe(() => {
            const scrollTop = this.autoCompleteRef.panel.nativeElement.scrollTop;
            const scrollHeight = this.autoCompleteRef.panel.nativeElement.scrollHeight;
            const elementHeight = this.autoCompleteRef.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight <= scrollTop + elementHeight;
            if (atBottom && this.canLoadMore && !this.loading) {
              this.loadMore();
            }
          });
      }
    });
  }

  loadRecords() {
    if (!this.service) return;

    this.loading = true;
    this.stateChanges.next();
    this.service
      .loadCollection(<any>this.parameters)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(collection => {
        this.canLoadMore = collection.current_page < collection.total_pages;
        if (this.parameters.getOffset() === 0) {
          if (this.autoCompleteRef.panel) this.autoCompleteRef.panel.nativeElement.scrollTop = 0;
          this.records = [];
        }

        if (this.excludeFn) {
          this.records = this.records.concat(collection.records.filter(this.excludeFn));
        } else {
          this.records = this.records.concat(collection.records);
        }

        this.stateChanges.next();
      });
  }

  loadSelectedRecordByUuid(uuid: string) {
    this.loading = true;
    this.service
      .load({ uuid: uuid } as any)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(entity => {
        this._value = entity;
      });
  }

  remove(item: T) {
    this.reset.emit();

    if (this._value instanceof Array) {
      const index = this._value.findIndex(x => x.uuid === item.uuid);
      this._value.splice(index, 1);
      const newValue = this._value;
      this._onChange(newValue);
    } else {
      this._value = undefined;
      this.searchInput.nativeElement.value = '';
      this._onChange(null);
    }

    // Need to refocus the input after removing a chip to prevent expressionChangedAfterChecked errors
    // caused by FocusMonitor changing the value of focus
    this.searchInput.nativeElement.focus();
  }

  private coerceSingleOrMultipleValue(value: T | T[]): T | T[] {
    // this function acts as a helper for converting values when necessary
    // it's a little more forgiving than MatSelect, which will break if the wrong type of value is passed with multiple
    if (this.multiple === true) {
      // if value is present and not an array with multiple is set, set to array.
      if (!Array.isArray(value) && value) {
        return [value];
      } else if (!value) {
        return [];
      }
    }
    return value;
  }

  getImageUrl(url) {
    return this.imageSizePipe.transform(url, '350');
  }
}
