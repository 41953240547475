import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabContainerComponent } from './tab-container/tab-container.component';
import { TabComponent } from './tab/tab.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TabContainerComponent, TabComponent],
  exports: [TabContainerComponent, TabComponent]
})
export class TabsModule {}
