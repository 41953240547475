<ng-container *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<div class="page-wrapper">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
    <div class="brand-img"></div>
    <mat-form-field appearance="outline" hideRequiredMarker color="primary" class="unstyled">
      <mat-label>email</mat-label>
      <input formControlName="email" type="email" matInput placeholder="email" required data-cy="admin-sign-in-email"/>
      <mat-error *ngIf="loginForm.get('email').invalid" data-cy="admin-invalid-email-error">{{ getErrorMessage('email') }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" hideRequiredMarker color="primary" class="unstyled">
      <mat-label>password</mat-label>
      <input formControlName="password" type="password" matInput placeholder="password" required data-cy="admin-sign-in-password"/>
      <mat-error *ngIf="loginForm.get('email').invalid" data-cy="admin-invalid-password-error">{{ getErrorMessage('password') }} </mat-error>
    </mat-form-field>

    <button mat-flat-button color="primary" type="submit" data-cy="admin-sign-in-button">
      Sign In
    </button>
    <mat-error class="form-error" *ngIf="serverError">{{ serverError }}</mat-error>
  </form>
</div>
