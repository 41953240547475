export enum DiscountStatusEnum {
  UNAVAILABLE = 0,
  AVAILABLE = 1,
  ARCHIVED = 255
}

const DiscountUnavailableStatus = {
  id: DiscountStatusEnum.UNAVAILABLE,
  name: 'Disabled'
};

const DiscountAvailableStatus = {
  id: DiscountStatusEnum.AVAILABLE,
  name: 'Enabled'
};

const DiscountArchivedStatus = {
  id: DiscountStatusEnum.ARCHIVED,
  name: 'Archived'
};

export const AllDiscountStatuses = [DiscountAvailableStatus, DiscountUnavailableStatus, DiscountArchivedStatus];
