/**
 * THIS FILE IS AUTOGENERATED
 */
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { PurchaseOrderStatusEnum } from '@app/myblackbird/src/app/shared/enumeration/purchase-order-status.enum';
import { CategoryModel } from '@shared/entity/category/model';
import { InventoryModel } from '@shared/entity/inventory/model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';


export class PurchaseOrderModel extends EntityModel {

  // Add additional properties here

  public uuid: string;
  public name: string;
  public description: string;
  public created_by_location: TypedEntityInterface;
  public from_location: TypedEntityInterface;
  public to_location: TypedEntityInterface;
  public inventory_location: TypedEntityInterface;
  public status: PurchaseOrderStatusEnum;
  public sale: boolean;
  public percent_received: number;
  public received_at: string;
  public total_cost: number;
  public total_value: number;

  constructor(purchaseOrder?: Partial<PurchaseOrderModel>){
    super();
    if(purchaseOrder) Object.assign(this, purchaseOrder);
  }

}

export interface MappedProductModel {
  uuid: string,
  name: string,
  image: UploadModel,
  category: CategoryModel,
  inventory: InventoryModel[]
}

