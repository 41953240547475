export abstract class BaseHttpParameters {
  private order: 'ASC' | 'DESC' = 'ASC';
  private globalSearch;
  private orderBy;

  public static parseQueryParams(params) {
    let parsedParams = {};

    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key].toString().charAt(0) === '[') {
        try {
          parsedParams[key] = params[key].split(/[,\[\]]+/).filter(param => param !== '');
        } catch (e) {
          parsedParams[key] = params[key];
        }
      } else if (params[key].toString().charAt(0) === '{') {
        parsedParams[key] = JSON.parse(params[key]);
      } else {
        parsedParams[key] = params[key];
      }
    }

    return parsedParams;
  }

  public static encodeQueryParams(params) {
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] && params[key].constructor === Array) {
        params[key] = `[${params[key].toString()}]`;
      }

      if (params.hasOwnProperty(key) && params[key] && typeof params[key] === 'object') {
        params[key] = JSON.stringify(params[key]);
      }
    }

    return params;
  }

  getOrder() {
    return this.order;
  }

  setOrder(order: 'ASC' | 'DESC') {
    this.order = order;
    return this;
  }

  getOrderBy() {
    return this.orderBy;
  }

  setOrderBy(orderBy?: string) {
    this.orderBy = orderBy;
    return this;
  }

  setWhereName(name?: string) {
    return this.setGlobalSearch(name);
  }

  getGlobalSearch() {
    return this.globalSearch;
  }

  setGlobalSearch(query?: string) {
    this.globalSearch = query;
    return this;
  }

  getParams(): { [param: string]: any } {
    return {
      order: this.order,
      query: this.globalSearch ? this.globalSearch : undefined,
      orderby: this.orderBy ? this.orderBy : undefined
    };
  }

  getFilteredParams(selectedParams?: Array<string>): { [param: string]: any } {
    const params = this.getParams();

    for (const key in params) {
      if (params[key] === undefined || params[key] === null || (selectedParams && !selectedParams.includes(key))) {
        delete params[key];
      }
    }

    return params;
  }

  removeUndefinedValues(params: { [param: string]: any }): { [param: string]: any } {
    const obj = Object.assign({}, params);

    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeUndefinedValues(obj[key]);
      else if (obj[key] == null || obj[key] === undefined) delete obj[key];
    });

    return obj;
  }
}
