import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { EmployeeModel } from '../model/employee.model';

@Injectable({ providedIn: 'root' })
export class EmployeeService extends RestApiService<EmployeeModel> {
  readonly basePath = '/employee/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  resendInvitation(employee: EmployeeModel): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${this.getPath(employee.uuid)}/invite`, null);
  }
}
