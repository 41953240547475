import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '@admin/shared/component/home';
import { LoginComponent } from '@admin/shared/component/login';
import { NotFoundComponent } from '@admin/shared/component/not-found';
import { AuthGuard } from './guards/auth.guard';
import { AdminPolicyPermissionsEnum } from '@shared/policy';
import { NgxPermissionsGuard } from 'ngx-permissions';

const rootRoutes: Routes = [
  {
    path: 'auth',
    component: LoginComponent,
    data: {
      label: 'Sign In'
    }
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
    data: {
      label: 'Home'
    }
  },
  {
    path: 'customer-service',
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    loadChildren: () => import('@admin/lazy/customer-service').then(m => m.CustomerServiceModule),
    data: {
      label: 'Customer Service',
      permissions: {
        only: AdminPolicyPermissionsEnum.VIEW_CUSTOMER_SERVICE_CARD,
        redirectTo: ''
      }
    }
  },
  {
    path: 'menus',
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    loadChildren: () => import('@admin/lazy/menus').then(m => m.MenusModule),
    data: {
      label: 'Menus',
      permissions: {
        only: AdminPolicyPermissionsEnum.VIEW_MENUS_CARD,
        redirectTo: ''
      }
    }
  },
  {
    path: 'clients',
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    loadChildren: () => import('@admin/lazy/clients').then(m => m.ClientsModule),
    data: {
      label: 'Clients',
      permissions: {
        only: AdminPolicyPermissionsEnum.VIEW_CLIENTS_CARD,
        redirectTo: ''
      }
    }
  },
  {
    path: 'settings',
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    loadChildren: () => import('@admin/lazy/settings').then(m => m.SettingsModule),
    data: {
      label: 'Settings',
      permissions: {
        only: AdminPolicyPermissionsEnum.VIEW_SETTINGS_CARD,
        redirectTo: ''
      }
    }
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(rootRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class RoutesModule {}
