export enum CashBalanceStatusEnum {
  PENDING    = 0,
  READY      = 1,
  INPROGRESS = 2,
  SETTLED    = 3,
  CANCELLED  = 4,
  ARCHIVED   = 255
}

const PendingStatus = {
  id: CashBalanceStatusEnum.PENDING,
  name: 'Pending',
  color: 'purple-600'
};

const ReadyStatus = {
  id: CashBalanceStatusEnum.READY,
  name: 'Ready',
  color: 'cyan-600'
};

const InProgressStatus = {
  id: CashBalanceStatusEnum.INPROGRESS,
  name: 'In Progress',
  color: 'orange-600'
};

const SettledStatus = {
  id: CashBalanceStatusEnum.SETTLED,
  name: 'Settled',
  color: 'green-600'
};

const CancelledStatus = {
  id: CashBalanceStatusEnum.CANCELLED,
  name: 'Cancelled',
  color: 'grey-400'
};

const ArchivedStatus = {
  id: CashBalanceStatusEnum.ARCHIVED,
  name: 'Deleted',
  color: 'grey-400'
};

export const AllCashBalanceStatuses = [
  PendingStatus,
  ReadyStatus,
  InProgressStatus,
  SettledStatus,
  CancelledStatus,
  ArchivedStatus
];

