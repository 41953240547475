import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { DeleteSession, sessionQuery } from '@shared/entity/session/state';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'admin-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['../../../../../shared/src/lib/component/portal/portal.component.scss', './navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  routes;

  authenticated$: Observable<boolean>;

  protected destroy$ = new Subject<void>();

  constructor(private _router: Router, private _store: Store<undefined>) {}

  ngOnInit() {
    this.authenticated$ = this._store.pipe(select(sessionQuery.getAuthenticated));

    this.routes = this._router.config;
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.routes = this._router.config;
    });
  }

  logout() {
    this.authenticated$
      .pipe(
        filter(authenticated => authenticated === false),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this._router.navigate(['/auth']);
      });

    this._store.dispatch(new DeleteSession());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
