/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { AbilityModel } from '../model/ability.model';

@Injectable({ providedIn: 'root'})
export class AbilityService extends RestApiService<AbilityModel> {

  readonly basePath = '/ability/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

}
