import { HomeComponent } from '@admin/shared/component/home';
import { LoginComponent } from '@admin/shared/component/login';
import { NavbarComponent } from '@admin/shared/component/navbar';
import { NotFoundComponent } from '@admin/shared/component/not-found';
import { ThemeService } from '@admin/shared/services/theme';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NxModule } from '@nrwl/angular';
import { BrowserCookiesModule } from '@shared/cookie/browser';
import { SessionStateModule } from '@shared/entity/session/state';
import { TypeFactory } from '@shared/enumeration/type/factory/type.factory';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { ApiParametersFactory } from '@shared/utility/api/factory/api-parameters.factory';
import { ApiServiceFactory } from '@shared/utility/api/factory/api-service.factory';
import { EncodeHttpParamsInterceptor } from '@shared/utility/encode-http-params.interceptor';
import { SharedModule } from 'libs/admin/shared/src/lib/shared.module';
import { MccColorPickerModule } from 'material-community-components';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AdminAuthRefreshInterceptor } from './interceptor/admin-auth-refresh.interceptor';
import { AuthGuard } from './routes/guards/auth.guard';
import { RoutesModule } from './routes/routes.module';
import { AppEffects } from './state/app.effects';
import { appReducer, initialState } from './state/app.reducer';
import { FeatureFlagModule } from '@shared/feature-flag';

export function apiServiceFactory(config: ApiConfig, http: HttpClient) {
  return new ApiServiceFactory(config, http);
}

export function apiParametersFactory() {
  return new ApiParametersFactory();
}

export function typeFactory() {
  return new TypeFactory();
}

@NgModule({
  declarations: [AppComponent, NavbarComponent, LoginComponent, HomeComponent, NotFoundComponent],
  imports: [
    FeatureFlagModule.forRoot({ environment: environment.releaseStage }),
    NxModule.forRoot(),
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserCookiesModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(
      { app: appReducer },
      {
        initialState: { app: initialState },
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false
        }
      }
    ),
    EffectsModule.forRoot([AppEffects]),
    SessionStateModule,
    MccColorPickerModule.forRoot({ empty_color: 'transparent' }),
    RoutesModule
  ],
  providers: [
    AuthGuard,
    ThemeService,
    MatIconRegistry,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 9000 }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminAuthRefreshInterceptor,
      multi: true
    },
    {
      provide: ApiConfig,
      useValue: {
        baseUrl: environment.apiUrl,
        doloresUrl: environment.doloresUrl
      }
    },
    {
      provide: ApiServiceFactory,
      useFactory: apiServiceFactory,
      deps: [ApiConfig, HttpClient]
    },
    {
      provide: ApiParametersFactory,
      useFactory: apiParametersFactory,
      deps: []
    },
    {
      provide: TypeFactory,
      useFactory: typeFactory,
      deps: []
    }
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private _matIconRegistry: MatIconRegistry) {
    _matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }
}
