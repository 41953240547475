/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { MenuProductModel } from '../model/menu-product.model';
import { MenuModel } from '../../menu/model';

@Injectable({ providedIn: 'root' })
export class MenuProductService extends RestApiService<MenuProductModel> {
  basePath = '/menu/{menu-uuid}/product/';
  readonly uniqueIdentifier = 'uuid';

  constructor(private menu: MenuModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{menu-uuid}', menu.uuid);
  }
}
