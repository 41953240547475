import { AdminPolicyPermissionsEnum } from '@shared/policy';

// TODO: Get list from product and finalize permissions
export const DATA_PERMISSIONS: AdminPolicyPermissionsEnum[] = [
  // Cards
  AdminPolicyPermissionsEnum.VIEW_CUSTOMER_SERVICE_CARD,
  AdminPolicyPermissionsEnum.VIEW_MENUS_CARD,
  AdminPolicyPermissionsEnum.VIEW_CLIENTS_CARD,
  AdminPolicyPermissionsEnum.VIEW_SETTINGS_CARD,

  // Customer Service
  AdminPolicyPermissionsEnum.VIEW_USER_CONSUMERS,
  AdminPolicyPermissionsEnum.UPDATE_USER_CONSUMERS,

  AdminPolicyPermissionsEnum.VIEW_LOCATION_CONSUMERS,
  AdminPolicyPermissionsEnum.UPDATE_LOCATION_CONSUMERS,

  AdminPolicyPermissionsEnum.VIEW_INTEGRATION_CONSUMERS,

  AdminPolicyPermissionsEnum.VIEW_INTEGRATION_ORDERS,
  AdminPolicyPermissionsEnum.UPDATE_INTEGRATION_ORDERS,

  AdminPolicyPermissionsEnum.VIEW_IDENTITIES,

  AdminPolicyPermissionsEnum.VIEW_USERS,

  AdminPolicyPermissionsEnum.VIEW_USER_ACL,
  AdminPolicyPermissionsEnum.UPDATE_USER_ACL,

  // Clients
  AdminPolicyPermissionsEnum.VIEW_CLIENT_SEARCH,
  AdminPolicyPermissionsEnum.TOGGLE_LOYALTY_PROGRAMS,

  AdminPolicyPermissionsEnum.VIEW_LICENSES,
  AdminPolicyPermissionsEnum.UPDATE_LICENSES,

  AdminPolicyPermissionsEnum.VIEW_LOCATIONS,
  AdminPolicyPermissionsEnum.UPDATE_LOCATIONS,

  AdminPolicyPermissionsEnum.VIEW_ORGANIZATIONS,
  AdminPolicyPermissionsEnum.UPDATE_ORGANIZATIONS,

  AdminPolicyPermissionsEnum.VIEW_BRANDS,
  AdminPolicyPermissionsEnum.UPDATE_BRANDS,

  AdminPolicyPermissionsEnum.VIEW_RATE_FUNCTIONS,
  AdminPolicyPermissionsEnum.UPDATE_RATE_FUNCTIONS,

  AdminPolicyPermissionsEnum.VIEW_ADDONS,

  AdminPolicyPermissionsEnum.VIEW_MS_MESSAGES,

  AdminPolicyPermissionsEnum.VIEW_CAMPAIGN_MESSAGES,

  AdminPolicyPermissionsEnum.VIEW_MESSAGE_ACCOUNTS,

  AdminPolicyPermissionsEnum.VIEW_MESSAGE_PHONE_NUMBERS,

  AdminPolicyPermissionsEnum.VIEW_VEHICLES,

  // Menus
  AdminPolicyPermissionsEnum.UPDATE_MENUS,
  AdminPolicyPermissionsEnum.VIEW_MENUS,

  AdminPolicyPermissionsEnum.VIEW_LISTINGS,

  AdminPolicyPermissionsEnum.UPDATE_INVENTORY,
  AdminPolicyPermissionsEnum.VIEW_INVENTORY,

  AdminPolicyPermissionsEnum.UPDATE_INTEGRATION_INVENTORY,
  AdminPolicyPermissionsEnum.VIEW_INTEGRATION_INVENTORY,

  AdminPolicyPermissionsEnum.VIEW_LOCATION_PRODUCT,
  AdminPolicyPermissionsEnum.UPDATE_LOCATION_PRODUCT,
  AdminPolicyPermissionsEnum.ARCHIVE_LOCATION_PRODUCT,

  AdminPolicyPermissionsEnum.UPDATE_PRODUCTS,
  AdminPolicyPermissionsEnum.VIEW_PRODUCTS,

  AdminPolicyPermissionsEnum.UPDATE_STRAINS,
  AdminPolicyPermissionsEnum.VIEW_STRAINS,

  AdminPolicyPermissionsEnum.UPDATE_PRODUCT_CATEGORIES,
  AdminPolicyPermissionsEnum.VIEW_PRODUCT_CATEGORIES,

  AdminPolicyPermissionsEnum.UPDATE_PRODUCT_TYPES,
  AdminPolicyPermissionsEnum.VIEW_PRODUCT_TYPES,

  AdminPolicyPermissionsEnum.UPDATE_FLOWER_SIZES,
  AdminPolicyPermissionsEnum.VIEW_FLOWER_SIZES,

  AdminPolicyPermissionsEnum.UPDATE_UNITS,
  AdminPolicyPermissionsEnum.UPDATE_UNITS,

  // Settings
  AdminPolicyPermissionsEnum.VIEW_TERPENES,
  AdminPolicyPermissionsEnum.UPDATE_TERPENES,

  AdminPolicyPermissionsEnum.VIEW_MESSAGE_TRIGGERS,

  AdminPolicyPermissionsEnum.UPDATE_INTEGRATION_CONFIGURATIONS,
  AdminPolicyPermissionsEnum.VIEW_INTEGRATION_CONFIGURATIONS,

  AdminPolicyPermissionsEnum.VIEW_INTEGRATION_ERRORS,
  AdminPolicyPermissionsEnum.UPDATE_INTEGRATION_ERRORS,

  AdminPolicyPermissionsEnum.VIEW_ENTITY_ACLS,
  AdminPolicyPermissionsEnum.UPDATE_ENTITY_ACLS,

  AdminPolicyPermissionsEnum.VIEW_CANCELLATION_REASONS,
];
