import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-fount.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
}
