import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { UploadService } from '@shared/utility/api';
import { MenuInventoryModel } from '../../model';
import { MenuModel } from '../../../menu/model';

@Injectable({ providedIn: 'root' })
export class MenuInventoryUploadService extends UploadService<UploadModel> {
  readonly uniqueIdentifier = 'uuid';
  protected basePath = '/menu/{menu}/inventory/{inventory}/upload/';

  constructor(menu: MenuModel, inventory: MenuInventoryModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{menu}', menu.uuid);
    this.basePath = this.basePath.replace('{inventory}', inventory.uuid);
  }
}
