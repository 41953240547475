import { StatusModel } from '@shared/enumeration/status/status.model';

export enum LoyaltyRewardStatusEnum {
  PENDING = 0,
  REDEEMED = 1,
  DENIED = 2
}

const LoyaltyRewardPendingStatus: StatusModel = {
  id: LoyaltyRewardStatusEnum.PENDING,
  name: 'Pending'
};

const LoyaltyRewardRedeemedStatus: StatusModel = {
  id: LoyaltyRewardStatusEnum.REDEEMED,
  name: 'Redeemed'
};

const LoyaltyRewardDeniedStatus: StatusModel = {
  id: LoyaltyRewardStatusEnum.DENIED,
  name: 'Denied'
};

export const AllLoyaltyRewardStatuses: StatusModel[] = [
  LoyaltyRewardPendingStatus,
  LoyaltyRewardRedeemedStatus,
  LoyaltyRewardDeniedStatus
];
