/**
 * THIS FILE IS AUTOGENERATED
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SessionEffects } from '../effects/session.effects';
import * as fromSession from '../reducers/session.reducer';
import { SESSION_KEY } from '../selectors/session.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SESSION_KEY, fromSession.reducer),
    EffectsModule.forFeature([SessionEffects])
  ]
})
export class SessionStateModule {}
