/**
 * THIS FILE IS AUTOGENERATED
 */
import { EntityModel } from '@shared/model/entity/entity.model';

import { TypedEntityInterface } from '../../../../../model/src/lib/entity/typed-entity.interface';
import { DeliveryModel } from '../../delivery/model';
import { VehicleInterface } from '@myblackbird/api/interface';

export class DeliveryLogModel extends EntityModel {
  // Add additional properties here
  public uuid: string;
  public created_at: string;
  public updated_at: string;
  public delivery: DeliveryModel;
  public delivery_fee: number;
  public status: number;
  public eta: string;
  public notes: string;
  public package_verification: string;
  public parcel_value: number;
  public amount_collected: number;
  public collect_amount: number;
  public custody_user: TypedEntityInterface;
  public created_by_user: TypedEntityInterface;
  public transacted_with: string;
  public vehicle: VehicleInterface;

  constructor(deliveryLog?: Partial<DeliveryLogModel>) {
    super();
    if (deliveryLog) Object.assign(this, deliveryLog);
  }
}
