import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ThemeService {
  themes = ['blackbird-light-theme', 'blackbird-dark-theme'];

  defaultTheme = this.themes[0];

  private _theme: Subject<string> = new Subject<string>();

  theme = this._theme.asObservable();

  constructor(private overlayContainer: OverlayContainer) {}

  loadLocalThemeOrDefault() {
    const localTheme = localStorage.getItem('bbx_admin_theme');
    if (localTheme && this.themes.includes(localTheme)) {
      this.setTheme(localTheme);
    } else {
      this.setTheme(this.defaultTheme);
    }
  }

  setTheme(theme: string): void {
    this.assignThemeToOverlayContainer(theme);
    this._theme.next(theme);
    localStorage.setItem('bbx_admin_theme', theme);
  }

  assignThemeToOverlayContainer(newTheme: string): void {
    this.themes.forEach(theme => {
      if (this.overlayContainer.getContainerElement().classList.contains(theme)) {
        this.overlayContainer.getContainerElement().classList.remove(theme);
      }
    });
    this.overlayContainer.getContainerElement().classList.add(newTheme);
  }

  getStoredTheme() {
    return localStorage.getItem('bbx_admin_theme');
  }

  getStoredThemeBrightnessMode() {
    const theme = this.getStoredTheme();

    if (theme === 'blackbird-dark-theme') {
      return 'dark';
    } else if (theme === 'blackbird-light-theme') {
      return 'light';
    }
  }
}
