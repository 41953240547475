/**
 * THIS FILE IS AUTOGENERATED
 */
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { AddressModel } from '@shared/model/entity/address/address.model';
import { ScheduleModel } from '@shared/model/entity/schedule/schedule.model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { LicenseClassModel } from '@shared/entity/license-class/model';

export interface MenuSurcharge {
  uuid: string;
  name: string;
  rate: number;
  rate_type: string;
  charge_method: string;
  status: boolean;
  deleted: string;
}

export class MenuModel extends EntityModel {
  // Add additional properties here
  public address: AddressModel;
  public brand_color: string;
  public cart_total: number;
  public delivery_enabled: boolean;
  public delivery_minimum: number;
  public delivery_schedule: ScheduleModel = new ScheduleModel();
  public description: string;
  public display_address: AddressModel;
  public embed_url: string;
  public external_link: string;
  public inventory_buffer: number;
  public location: TypedEntityInterface;
  public location_loyalty_program: TypedEntityInterface;
  public logo: UploadModel;
  public medical: boolean;
  public name: string;
  public password_protected: boolean;
  public curbside_enabled: boolean;
  public curbside_schedule: ScheduleModel = new ScheduleModel();
  public analytics_id: string;
  public pickup_enabled: boolean;
  public pickup_schedule: ScheduleModel = new ScheduleModel();
  public published_at: string;
  public recreational: boolean;
  public slug: string;
  public under_maintenance: boolean;
  public user_distance: number;
  public user_in_delivery_range: boolean;
  public _type: TypesEnum = TypesEnum.MENUS;
  public _name: string;
  public location_license_class: LicenseClassModel;
  public location_license_number: string;
  public website: string;
  public phone: string;
  public email: string;
  public orderUuid: string;
  public orderSubtotal: number;
  public numberOfProducts: number;
  public allow_unverified_pickup: boolean;
  public allow_unverified_delivery: boolean;
  public allow_blackbirdgo_subscription: boolean;
  public surcharges: MenuSurcharge[];
  public menu_banner: {
    uuid: string,
    image: {
      uuid: string,
      url: string
    } | null,
    image_alt: string | null,
    link_type: string,
    link: string,
    background_text_color: string,
    text_color: string,
    text_size: string,
    text_position: 'left' | 'center' | 'right',
    content: string,
    secondary_content: string,
    status: string,
  } | null;

  constructor(menu?: Partial<MenuModel>) {
    super();
    if (menu) Object.assign(this, menu);
  }
}
