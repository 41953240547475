import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DiscountModel } from './discount.model';

export class AppliedDiscountModel {
  code: string;
  discount: DiscountModel;
  percent: number;
  total: number;
  applied_to: TypedEntityInterface;

  constructor(discount?: Partial<AppliedDiscountModel>) {
    if (discount) Object.assign(this, discount);
  }

  isFromReward(): boolean {
    return this.discount.points_required !== null;
  }
}
