/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { IntegrationOrderModel } from '../model/integration-order.model';

@Injectable({ providedIn: 'root'})
export class IntegrationOrderService extends RestApiService<IntegrationOrderModel> {

  readonly basePath = '/integration/order/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  email(integrationOrder: IntegrationOrderModel, email) {
    return this.http.post(this.getPath(integrationOrder.uuid) + '/email', email);
  }

  submit(integrationOrder: IntegrationOrderModel) {
    return this.http.post(this.getPath(integrationOrder.uuid) + '/submit', '');
  }
}
