/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

type TypeOrderByTypes = 'type.name' | 'type.sort_order';

export class ProductTypeParameters extends OffsetHttpParameters {

  protected searchJSON: Partial<{
    type: { name: string };
  }> = {};

  constructor(params?: Partial<ProductTypeParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: TypeOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, type: name ? { name: name } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
