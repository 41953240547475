import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ProductModel } from '../../model';
import { UploadService } from '@shared/utility/api';

@Injectable({ providedIn: 'root' })
export class ProductUploadService extends UploadService<UploadModel> {
  readonly uniqueIdentifier = 'uuid';
  protected basePath = '/product/{product}/upload/';

  constructor(product: ProductModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{product}', product.uuid);
  }
}
