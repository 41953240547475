/**
 * THIS FILE IS AUTOGENERATED
 */

import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { UserInterface } from '@shared/entity/user-acl/model';
import { OffsetHttpParameters } from '@shared/utility/api';

export class UserAclParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    employee: { distinct: { uuid: string } } | { name: string };
    has_employee: boolean;
    user: { distinct: { uuid: string } } | { email: string };
    name: { first?: string; last?: string };
    email: string;
    role: { in: string[] };
    object: { distinct: { type: number; uuid: string } };
  }> = {};

  constructor(params?: Partial<UserAclParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereNameFirst(name?: string) {
    this.searchJSON = { ...this.searchJSON, name: name ? { first: name } : undefined };
    return this;
  }

  setWhereNameLast(name?: string) {
    this.searchJSON = { ...this.searchJSON, name: name ? { last: name } : undefined };
    return this;
  }

  setWhereEmailLike(email?: string) {
    this.searchJSON = { ...this.searchJSON, email: email ? email : undefined };
    return this;
  }

  setWhereUser(user?: UserInterface) {
    this.searchJSON = { ...this.searchJSON, user: user ? { distinct: { uuid: user.uuid } } : undefined };
    return this;
  }

  setWhereUserEmail(email?: string) {
    this.searchJSON = { ...this.searchJSON, user: email ? { email: email } : undefined };
    return this;
  }

  setWhereEmployee(employee?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, employee: employee ? { distinct: { uuid: employee.uuid } } : undefined };
    return this;
  }

  setWhereEmployeeName(name?: string) {
    this.searchJSON = { ...this.searchJSON, employee: name ? { name: name } : undefined };
    return this;
  }

  setWhereHasEmployee(hasEmployee?: boolean) {
    this.searchJSON = { ...this.searchJSON, has_employee: hasEmployee !== null ? hasEmployee : undefined };
    return this;
  }

  setWhereRoles(roles?: Array<{ name: string }>) {
    this.searchJSON = { ...this.searchJSON, role: roles ? { in: roles.map(role => role.name) } : undefined };
    return this;
  }

  setWhereTarget(object?: TypedEntityInterface) {
    this.searchJSON = {
      ...this.searchJSON,
      object: object ? { distinct: { type: object._type, uuid: object.uuid } } : undefined
    };
    return this;
  }

  setWhereObject(object?: TypedEntityInterface) {
    this.searchJSON.object = object ? { distinct: { type: object._type, uuid: object.uuid } } : undefined;
    return this;
  }

  getParams(): any {
    return this.removeUndefinedValues({
      ...super.getParams(),
      ...(this.searchJSON ? { search: JSON.stringify(this.searchJSON) } : undefined)
    });
  }
}
