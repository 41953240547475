import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { EntityInterface } from '@shared/model/entity/entity.interface';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { ConsumerModel } from '../../consumer/model';
import { DiscountModel } from '../../discount/model';
import { LocationServiceWindowModel } from '../../location-service-window/model';
import { LoyaltyRewardModel } from '../../loyalty-reward/model';
import { OrderProductModel } from '../../order-product/model';
import { OrderRefundModel } from '../../order-refund/model';
import { OrderStatusEnum } from '../enumeration';
import { TransactionModel } from './transaction.model';
import { OrderSurchargeModel } from './order-surcharge.model';

export class OrderModel extends EntityModel {
  readonly _type: TypesEnum = TypesEnum.ORDERS;

  merchant: TypedEntityInterface;
  customer: OrderCustomerInterface | ConsumerModel;
  customer_license_class: OrderCustomerLicenseClassInterface;
  delivery: TypedEntityInterface;
  products: OrderProductModel[];
  refunds: OrderRefundModel[];
  transactions: TransactionModel[];
  status: OrderStatusEnum;
  menu: TypedEntityInterface;
  notes: string;
  subtotal: number;
  surcharge_total: number;
  surcharges: OrderSurchargeModel[];
  sales_tax_total: number;
  excise_tax_total: number;
  tax_total: number;
  discount_total: number;
  total: number;
  total_override: number | null;
  total_override_reason: string | null;
  display_total: number;
  refund_total: number;
  net_total: number;
  delivery_rate: number;
  tendered: number;
  service_window: LocationServiceWindowModel;
  loyalty_rewards: Array<{
    uuid: string,
    consumer_uuid: string,
    discount_name: string,
    discount_uuid: string,
    discount_targets: TypedEntityInterface[],
    location_uuid: string,
    points: number,
    status: number
  }>;

  // Frontend additions
  consumer_discount: DiscountModel;
  discounts: Map<string, DiscountModel>;
  rewards: Map<string, DiscountModel>;
  reward_total: number;
  points_spent: number;

  transfer_method: number;

  constructor(order?: Partial<OrderModel>) {
    super();
    if (order) {
      Object.assign(this, order);
      if (this.products) {
        this.products = this.products.map(product => new OrderProductModel(product));
        if (!(order instanceof OrderModel)) this.calculateRewardDiscounts();
        this.getUniqueDiscounts();
      }

      if (this.refunds) this.refunds = this.refunds.map(refund => new OrderRefundModel(refund));

      if (this.service_window && !(this.service_window instanceof LocationServiceWindowModel))
        this.service_window = new LocationServiceWindowModel(this.service_window);

      if (this.surcharges) {
        this.surcharges = this.surcharges.map(surcharge => new OrderSurchargeModel(surcharge));
      }

      if (order.loyalty_rewards) {
        for (const params of this.loyalty_rewards) {
          this.upsertLoyaltyReward(new LoyaltyRewardModel({
            uuid: params.uuid,
            consumer: { uuid: params.consumer_uuid } as TypedEntityInterface,
            location: { uuid: params.location_uuid } as TypedEntityInterface,
            order: { uuid: order.uuid } as TypedEntityInterface,
            discount_target_type: params.discount_targets[0]?._type,
            discount_targets: params.discount_targets,
            discount: {
              uuid: params.discount_uuid,
              _name: params.discount_name
            } as TypedEntityInterface,
            points: params.points,
            status: params.status
          }));
        }
      }
    }
  }

  getOrderNumber(): string {
    const randomInt = this.uuid.slice(-2).padStart(3, '0');
    const timestamp = new Date(this.created_at).getTime() / 1000;
    return timestamp + randomInt;
  }

  isProductInOrder(orderProduct: OrderProductModel): boolean {
    return coerceBooleanProperty(this.products.find(product => product.uuid === orderProduct.uuid));
  }

  isDiscountInOrder(discount: DiscountModel) {
    return this.discounts.has(discount.uuid);
  }

  isRewardInOrder(reward: DiscountModel) {
    return this.rewards.has(reward.uuid);
  }

  getCurrentProductPrice(updatedOrderProduct: OrderProductModel): number {
    const productInOrder = this.products.find(product => product.uuid === updatedOrderProduct.uuid);
    const updatedProductPrice = updatedOrderProduct.price_each;

    if (!productInOrder || updatedProductPrice === productInOrder.original_price_each) return updatedProductPrice;

    return productInOrder.price_each !== updatedProductPrice ? updatedProductPrice : productInOrder.price_each;
  }

  getProductQuantity(updatedOrderProduct: OrderProductModel): number {
    const productInOrder = this.products.find(product => product.uuid === updatedOrderProduct.uuid);
    return productInOrder ? productInOrder.quantity : 0;
  }

  upsertCustomer(consumer: ConsumerModel) {
    this.customer = consumer;
  }

  upsertLoyaltyReward(reward: LoyaltyRewardModel) {
    if (!this.customer || !(this.customer instanceof ConsumerModel)) return;

    this.customer.upsertLoyaltyReward(reward);
  }

  removeLoyaltyReward(reward: LoyaltyRewardModel) {
    if (!this.customer || !(this.customer instanceof ConsumerModel)) return;

    this.customer.removeLoyaltyReward(reward);
  }

  private calculateRewardDiscounts() {
    const totals = this.products.reduce(
      (acc, product) => {
        return {
          reward_total: acc.reward_total + product.reward_total
        };
      },
      { reward_total: 0 }
    );
    this.reward_total = totals.reward_total;
    this.discount_total -= this.reward_total;
  }

  private getUniqueDiscounts() {
    this.points_spent = 0;
    this.discounts = new Map();
    this.rewards = new Map();
    this.products.forEach(product => {
      product.discounts.forEach(appliedDiscount => {
        if (appliedDiscount.isFromReward()) {
          if (!this.rewards.has(appliedDiscount.discount.uuid)) {
            this.rewards.set(appliedDiscount.discount.uuid, appliedDiscount.discount);
            this.points_spent += appliedDiscount.discount.points_required;
          }
        } else {
          if (!this.discounts.has(appliedDiscount.discount.uuid)) {
            this.discounts.set(appliedDiscount.discount.uuid, appliedDiscount.discount);
            if (appliedDiscount.discount.target_type === TypesEnum.CONSUMERS)
              this.consumer_discount = appliedDiscount.discount;
          }
        }
      });
    });
  }
}

interface OrderCustomerInterface extends TypedEntityInterface {
  license_class: any;
}

interface OrderCustomerLicenseClassInterface extends EntityInterface {
  name: string;
  display_name: string;
  medical: boolean;
  type: TypesEnum;
}
