/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

export class MessageTriggerParameters extends OffsetHttpParameters {
  protected location_name: string;

  constructor(params?: Partial<MessageTriggerParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereLocationName(locationName?: string) {
    this.location_name = locationName;
    return this;
  }

  getParams(): any {
    return this.removeUndefinedValues({
      ...super.getParams(),
      ...(this.location_name ? { location_name: this.location_name } : undefined)
    });
  }
}
