import { RoleInterface } from '../model/role.interface';

export enum RolesEnum {
  ADMIN = 'admin',
  MANAGER = 'manager',
  TELLER = 'teller',
  DISPATCHER = 'dispatcher',
  DRIVER = 'driver'
}

export const PosAdminRole: RoleInterface = {
  name: RolesEnum.ADMIN,
  displayName: 'Admin'
};

export const PosManagerRole: RoleInterface = {
  name: RolesEnum.MANAGER,
  displayName: 'Manager'
};

export const PosTellerRole: RoleInterface = {
  name: RolesEnum.TELLER,
  displayName: 'Staff'
};

export const PosDispatcherRole: RoleInterface = {
  name: RolesEnum.DISPATCHER,
  displayName: 'Dispatcher'
};

export const PosDriverRole: RoleInterface = {
  name: RolesEnum.DRIVER,
  displayName: 'Driver'
};

export const AllRoles: RoleInterface[] = [PosAdminRole, PosManagerRole, PosTellerRole, PosDispatcherRole, PosDriverRole];

export const AllPosRoles: RoleInterface[] = [PosAdminRole, PosManagerRole, PosTellerRole];

export const AllNonAdminPosRoles: RoleInterface[] = [PosManagerRole, PosTellerRole];

export const AllNonAdminBlackbirdRoles: RoleInterface[] = [PosDispatcherRole, PosDriverRole];

export const AllBlackbirdRoles: RoleInterface[] = [PosDispatcherRole, PosDriverRole, PosAdminRole];
