import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { OrderModel, TransactionModel } from '../model';

@Injectable({ providedIn: 'root' })
export class PreauthorizedOrderTransactionService extends BaseApiService {
  basePath = '/v2/orders';

  constructor(private order: OrderModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  charge(transaction: TransactionModel): Observable<any> {
    return this.http.post(`${this.getDoloresPath()}/process_preauthorized_transaction`, {
      order_uuid: this.order.uuid,
      transaction_uuid: transaction.uuid
    });
  }

  cancel(transaction: TransactionModel): Observable<any> {
    return this.http.post(`${this.getDoloresPath()}/cancel_preauthorized_transaction`, {
      order_uuid: this.order.uuid,
      transaction_uuid: transaction.uuid
    });
  }

  refund(transaction: TransactionModel): Observable<any> {
    return this.http.post(`${this.getDoloresPath()}/void_transaction`, {
      order_uuid: this.order.uuid,
      transaction_uuid: transaction.uuid
    });
  }
}
