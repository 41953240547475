import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'apps/admin/src/environments/environment';
import { select, Store } from '@ngrx/store';
import { DeleteSessionSuccess, Login, sessionQuery } from '@shared/entity/session/state';
import { ErrorModel } from '@shared/model/error/error.model';
import { distinctUntilChanged, filter, map, skip, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionModel } from '@shared/entity/session/model';
import { AdminPolicyService } from '@admin/policy';

@Component({
  selector: 'admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  authenticated$: Observable<boolean>;

  authenticationError$: Observable<ErrorModel>;

  loading = false;

  public loginForm: FormGroup;

  private sessionSubject$ = new BehaviorSubject(null);

  readonly session$ = this.sessionSubject$.asObservable();

  serverError = '';

  private destroy$ = new Subject<void>();

  constructor(
    private _adminPolicyService: AdminPolicyService,
    private _router: Router,
    private _route: ActivatedRoute,
    public _fb: FormBuilder,
    private _store: Store<undefined>,
    private _snackBar: MatSnackBar
  ) {
    this.authenticated$ = this._store.pipe(select(sessionQuery.getAuthenticated));
    this.authenticationError$ = this._store.pipe(select(sessionQuery.getError));
  }

  ngOnInit() {
    this.constructLoginForm();
    this.subscribeAuthenticated();
    this.subscribeRouteParams();
    this.subscribeError();
  }

  constructLoginForm() {
    this.loginForm = this._fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(5)])]
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    this.loading = true;
    this._store.dispatch(new Login({ credentials: this.loginForm.value }));
  }

  subscribeRouteParams() {
    // kill session when interceptor throws unauthorized param
    this._route.queryParams
      .pipe(
        filter(params => params['unauthorized']),
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        const updatedParams = { ...params, unauthorized: undefined };
        this._router.navigate([], { queryParams: updatedParams });
        this._store.dispatch(new DeleteSessionSuccess());
      });
  }

  subscribeAuthenticated() {
    this.authenticated$.pipe(takeUntil(this.destroy$)).subscribe(isAuthed => {
      this.loading = false;
      if (isAuthed) this._router.navigate(['']);
    });

    this._store
      .pipe(
        select(sessionQuery.getSession),
        filter(session => !!session),
        distinctUntilChanged((comparer: SessionModel, session: SessionModel) => comparer.uuid === session.uuid)
      )
      .subscribe(session => {
        this._adminPolicyService.setRoles(session.role);
        this.initLogRocket(session);
      });
  }

  subscribeError() {
    this.authenticationError$
      .pipe(
        skip(1),
        filter(res => !!res),
        map(error => (error && error.error ? error.error : 'An unexpected error has occurred'))
      )
      .subscribe(error => {
        this.loading = false;
        this._snackBar.open(error);
      });
  }

  getErrorMessage(formControlName: string) {
    switch (formControlName) {
      case 'email':
        return this.loginForm.get('email').hasError('required')
          ? 'Email is required'
          : this.loginForm.get('email').hasError('email')
          ? 'Please enter a valid email'
          : '';
      case 'password':
        return this.loginForm.get('password').hasError('required') ? 'Please enter your password' : '';
      default:
        break;
    }
  }

  private initLogRocket(session: SessionModel) {
    if (environment.logRocketId) {
      LogRocket.init(environment.logRocketId, {
        network: {
          requestSanitizer: (request) => {
            if (request.url.includes('session') && request.method && request.method.toLowerCase() === 'post') {
              return null;
            }

            if (request.headers['Authorization']) {
              request.headers['Authorization'] = '';
            }

            return request;
          },
          responseSanitizer(response) {
            if (response.headers['bearer']) {
              response.headers['bearer'] = '';
            }

            return response;
          }
        }
      });
      this.updateLogRocket(session);
    }
  }

  private updateLogRocket(session: SessionModel) {
    if (environment.logRocketId) {
      LogRocket.identify(session.uuid, {
        name: session.name.first + ' ' + session.name.last,
        email: session.email,
        lastSeenLocation: session.current_acl.target._name,
        role: session.current_acl.role.name
      });
    }
  }
}
