import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { DiscountModel } from '../model/discount.model';

@Injectable({ providedIn: 'root' })
export class DiscountService extends RestApiService<DiscountModel> {
  basePath = '/discount/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }
}
