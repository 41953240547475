import { LocationModel } from '@shared/entity/location/model';
import { OffsetHttpParameters } from '@shared/utility/api';
import { DrawingStatusEnum } from '../enumeration';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';

export class DrawingParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    name: string;
    location: string;
    status: DrawingStatusEnum[];
    date_range: DateRangeFieldInterface;
  }> = {};

  constructor(params?: Partial<DrawingParameters>) {
    super();
    if (params) Object.assign(this, params);
  }


  setWhereLocation(location?: LocationModel | TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, location: location ? location.uuid  : undefined };
    return this;
  }

  setWhereStatusIn(statuses?: DrawingStatusEnum[]): this {
    this.searchJSON = { ...this.searchJSON, status: statuses && statuses.length ? statuses : undefined };
    return this;
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON,  name };
    return this;
  }

  setWhereDateRange(dateRange?: DateRangeFieldInterface): this {
    this.searchJSON = { ...this.searchJSON, date_range: dateRange };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      query: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
