/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { MenuModel } from '../model/menu.model';
import { MenuUploadService } from './upload/menu-upload.service';
import { MenuProductService } from '../../menu-product/service';
import { MenuInventoryService } from '../../menu-inventory/service';
import { MenuBrandService } from '../../menu-brand/service';
import { MenuOrderService } from '../../menu-order/service';

@Injectable({ providedIn: 'root' })
export class MenuService extends RestApiService<MenuModel> {
  readonly basePath = '/menu/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  getUploadService(menu: MenuModel) {
    return new MenuUploadService(menu, this.config, this.http);
  }

  getProductService(menu: MenuModel): MenuProductService {
    return new MenuProductService(menu, this.config, this.http);
  }

  getInventoryService(menu: MenuModel): MenuInventoryService {
    return new MenuInventoryService(menu, this.config, this.http);
  }

  getBrandService(menu: MenuModel): MenuBrandService {
    return new MenuBrandService(menu, this.config, this.http);
  }

  getOrderService(menu: MenuModel): MenuOrderService {
    return new MenuOrderService(menu, this.config, this.http);
  }
}
