import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OffsetHttpParameters } from '@shared/utility/api';

export class StationParameters extends OffsetHttpParameters {

  protected name: string;
  protected sales_enabled: boolean;
  protected in_store: boolean;
  protected loyalty_enabled: boolean;
  protected kiosk_enabled: boolean;
  protected touchless_mode: boolean;
  protected location_uuid: string;

  constructor(params?: Partial<StationParameters>) {
    super();
    if (params) Object.assign(this, params);
    this.setOrderBy('name');
  }

  setWhereLocation(location?: TypedEntityInterface) {
    this.location_uuid = location?.uuid;
    return this;
  }

  setWhereInStore(in_store?: boolean) {
    this.in_store = in_store;
    return this;
  }

  setWhereLoyaltyEnabled(loyalty_enabled?: boolean) {
    this.loyalty_enabled = loyalty_enabled;
    return this;
  }

  setWhereKioskEnabled(kiosk_enabled?: boolean) {
    this.kiosk_enabled = kiosk_enabled;
    return this;
  }

  setWhereTouchlessMode(touchless_mode?: boolean) {
    this.touchless_mode = touchless_mode
    return this;
  }

  setWhereName(name?: string) {
    this.name = name;
    return this;
  }

  setWhereSalesEnabled(sales_enabled?: boolean) {
    this.sales_enabled = sales_enabled;
    return this;
  }

  getParams() {
    const params = {
      ...super.getParams(),
      name: this.name,
      sales_enabled: this.sales_enabled,
      in_store: this.in_store,
      kiosk_enabled: this.kiosk_enabled,
      touchless_mode: this.touchless_mode,
      loyalty_enabled: this.loyalty_enabled,
      location_uuid: this.location_uuid
    };
    return this.removeUndefinedValues(params);
  }
}
