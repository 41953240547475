import { EntityModel } from '@shared/model/entity/entity.model';
import { CoordinatesFieldInterface } from '@shared/model/field/coordinates/coordinates-field.interface';

export class AddressModel extends EntityModel {
  street: string | null = null;
  street2: string | null = null;
  city: string | null = null;
  state: string | null = null;
  zip: string | null = null;
  country: string | null = null;
  coordinates: CoordinatesFieldInterface | null = null;

  constructor(address?: Partial<AddressModel>) {
    super();
    if (address) Object.assign(this, address);
  }

  public stringifyStreet(): string {
    return this.street + (this.street2 ? `, ${this.street2}` : '');
  }

  public stringifyCityStatePostalCode(): string {
    return `${this.city}, ${this.state} ${this.zip}`;
  }

  public stringifyAddress(): string {
    return this.stringifyStreet() + ', ' + this.stringifyCityStatePostalCode();
  }
}
