import { BaseHttpParameters } from './base-http.parameters';

export class OffsetHttpParameters extends BaseHttpParameters {
  limit  = 10;
  offset = 0;

  constructor(params?: Partial<OffsetHttpParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  getLimit() {
    return this.limit;
  }

  setLimit(limit?: number) {
    this.limit = limit ? limit : 10;
    return this;
  }

  getOffset() {
    return this.offset;
  }

  setOffset(offset?: number) {
    this.offset = offset ? offset : 0;
    return this;
  }

  getParams(): { [param: string]: any } {
    return {
      ...super.getParams(),
      limit: this.limit.toString(),
      offset: this.offset.toString(),
      page: Math.floor(this.offset / this.limit) + 1,
    };
  }
}
