import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { OffsetHttpParameters } from '@shared/utility/api';
import { TypedEntityInterface } from '../../../../../model/src/lib/entity/typed-entity.interface';
import { LocationModel } from '../../location/model';
import { ServiceModel } from '../../service/model';
import { DeliveryDateRangeModel } from '../model/delivery-date-range.model';
import { DeliverySurchargeModel } from '../../delivery-surcharge'

const today = new Date();
today.setHours(23, 59, 59, 999);

const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

export class DeliveryParameters extends OffsetHttpParameters {
  protected billed: boolean;
  protected hasCashJob: boolean;
  protected status: number[] = [];
  protected types: number[] = [];
  protected from: LocationModel;
  protected created_by: LocationModel;
  protected service: ServiceModel;
  protected to: LocationModel;
  protected merchant: LocationModel;
  protected trackingNumber: string = undefined;
  protected custodyUser: TypedEntityInterface = undefined;
  protected custodyLocation: LocationModel[] = [];
  protected delivery_surcharges: DeliverySurchargeModel[];
  protected display_types: string[];

  protected dateRange: DeliveryDateRangeModel = {
    dates: { start: yesterday.toISOString(), end: today.toISOString() },
    type: 'created_at'
  };

  protected showNonOnlineOrderPending = undefined;

  protected searchJSON = {
    has_cash_job: undefined,
    tracking_number: undefined,
    custody_location: undefined,
    type: undefined,
    custody_user: undefined,
    attached_to: undefined,
    service: undefined,
    to: undefined,
    from: undefined,
    merchant: undefined,
    to_name: undefined,
    from_name: undefined,
    created_by: undefined,
    created_at: undefined,
    updated_at: undefined,
    delivered_at: undefined,
    display_type: undefined
  };

  constructor(params?: Partial<DeliveryParameters>) {
    super();
    if (params) Object.assign(this, params);
    this.setWhereDateRange(this.dateRange);
  }

  getWhereStatus() {
    return this.status;
  }

  setWhereStatus(status?: number[]) {
    this.status = status ? status : undefined;
    return this;
  }

  getWhereService() {
    return this.service;
  }

  setWhereService(service: ServiceModel) {
    this.service = service;
    this.searchJSON.service = service ? { distinct: { uuid: service.uuid } } : undefined;
    return this;
  }

  getWhereNonOnlineOrderPending() {
    return this.showNonOnlineOrderPending;
  }

  setWhereNonOnlineOrderPending(show?: boolean) {
    this.showNonOnlineOrderPending = show;
    return this;
  }

  getWhereTrackingNumber() {
    return this.trackingNumber;
  }

  setWhereTrackingNumber(trackingNumber?: string) {
    this.trackingNumber = trackingNumber ? trackingNumber : undefined;
    this.searchJSON.tracking_number = trackingNumber ? trackingNumber : undefined;
    return this;
  }

  getWhereTypes() {
    // removing po from types, inferred from deliveries
    return this.types.filter(type => type !== TypesEnum.PURCHASE_ORDERS);
  }

  setWhereTypes(types?: number[]) {
    this.types = types ? types : [];

    // adding deliveries with purchase orders when querying for wholesale deliveries
    // currently no need to only query for deliveries with or without POs from the client
    if (this.types.findIndex(type => type === TypesEnum.DELIVERIES) !== -1) {
      this.types.push(TypesEnum.PURCHASE_ORDERS);
    }

    this.searchJSON.type = types ? { in: types } : undefined;
    return this;
  }

  getWhereBilled() {
    return this.billed;
  }

  setWhereBilled(isBilled?: boolean) {
    this.billed = isBilled;
    return this;
  }

  getWhereHasCashJob(): boolean {
    return this.hasCashJob;
  }

  setWhereHasCashJob(hasCashJob: boolean) {
    this.hasCashJob = hasCashJob;
    this.searchJSON.has_cash_job = hasCashJob;
    return this;
  }

  getWhereCustodyLocation() {
    return this.custodyLocation;
  }

  setWhereCustodyLocation(locations: LocationModel[] = []) {
    this.custodyLocation = locations;
    const targetUuids = locations.map(location => ({ uuid: location.uuid }));
    this.searchJSON.custody_location = targetUuids ? { in: targetUuids } : undefined;
    return this;
  }

  getWhereCustodyUser() {
    return this.custodyUser;
  }

  setWhereCustodyUser(user?: TypedEntityInterface) {
    this.custodyUser = user;
    this.searchJSON.custody_user = user ? { distinct: { uuid: user.uuid } } : undefined;
    return this;
  }

  getWhereAttachedTo() {
    return this.searchJSON.attached_to ? this.searchJSON.attached_to.distinct : undefined;
  }

  setWhereAttachedTo(attachedTo?: TypedEntityInterface) {
    this.searchJSON.attached_to = attachedTo
      ? {
          distinct: {
            uuid: attachedTo.uuid,
            _type: attachedTo._type
          }
        }
      : undefined;
    return this;
  }

  getWhereFrom() {
    return this.from;
  }

  setWhereFrom(from?: LocationModel) {
    this.from = from;
    this.searchJSON.from = from ? { distinct: { uuid: from.uuid, _type: from._type } } : undefined;
    return this;
  }

  getWhereCreatedBy() {
    return this.created_by;
  }

  setWhereCreatedBy(createdBy?: LocationModel) {
    this.created_by = createdBy;
    this.searchJSON.created_by = createdBy ? { distinct: { uuid: createdBy.uuid, _type: createdBy._type } } : undefined;
    return this;
  }

  getWhereTo() {
    return this.to;
  }

  setWhereTo(to?: LocationModel) {
    this.to = to;
    this.searchJSON.to = to ? { distinct: { uuid: to.uuid, _type: to._type } } : undefined;
    return this;
  }

  getWhereMerchant() {
    return this.merchant;
  }

  setWhereMerchant(merchant?: LocationModel) {
    this.merchant = merchant;
    this.searchJSON.merchant = merchant ? { distinct: { uuid: merchant.uuid, _type: merchant._type } } : undefined;
    return this;
  }

  setWhereDateRange(dateRange?: DeliveryDateRangeModel) {
    this.clearDateRange();
    this.dateRange = dateRange;
    if (this.dateRange) {
      this.searchJSON[this.dateRange.type] = dateRange.dates ? dateRange.dates : undefined;
    }
    return this;
  }

  getWhereDateRange(): DeliveryDateRangeModel {
    return this.dateRange;
  }

  getWhereToName() {
    return this.searchJSON ? this.searchJSON.from_name : undefined;
  }

  setWhereToName(toName?: string) {
    this.searchJSON.to_name = toName ? toName : undefined;
    return this;
  }

  getWhereFromName() {
    return this.searchJSON ? this.searchJSON.from_name : undefined;
  }

  setWhereFromName(fromName?: string) {
    this.searchJSON.from_name = fromName ? fromName : undefined;
    return this;
  }

  getWhereDisplayTypes() {
    return this.display_types;
  }

  setWhereDisplayTypes(types?: ('retail' | 'facility' | 'cash')[]) {
    this.display_types = types ? types : [];
    this.searchJSON.display_type = types ? { in: types } : undefined;
    return this;
  }

  clearDateRange() {
    this.dateRange = null;
    this.searchJSON.created_at = undefined;
    this.searchJSON.updated_at = undefined;
    this.searchJSON.delivered_at = undefined;
    return this;
  }

  getParams() {
    const params = {
      ...super.getParams(),
      'status[]': this.status,
      tracking_number: this.trackingNumber,
      billed: this.billed,
      show_non_online_order_pending: this.showNonOnlineOrderPending,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
