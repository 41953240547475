import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagConfig } from './feature-flag-config';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagApiService {
  private readonly baseUrl: string;

  constructor(
    private config: FeatureFlagConfig,
    private http: HttpClient) {

    this.baseUrl = FeatureFlagApiService.setUrl(config.environment);
  }

  static setUrl(environment: string): string {
    const uri = 'api/common/v1/feature-flags';

    switch (environment) {
      case 'develop':
        return `https://maeve.develop.myblackbird.com/${uri}`;
      case 'staging':
        return `https://maeve.staging.myblackbird.com/${uri}`;
      case 'prod':
        return `https://maeve.myblackbird.com/${uri}`;
      default:
        return `http://localhost:2082/${uri}`;
    }
  }

  public getAll(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl);
  }
}
