import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from '@shared/cookie';
import { SessionService } from '@shared/entity/session/service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { SessionModel } from '@shared/entity/session/model';

const BB_ACCESS_TOKEN = 'bb-access-token';
const BB_REFRESH_TOKEN = 'bb-refresh-token';

@Injectable()
export class AdminAuthRefreshInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private _sessionService: SessionService,
    private _cookiesService: CookiesService,
    private _router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this._cookiesService.get(BB_ACCESS_TOKEN);

    // TODO: on admin, we have to use refresh token on get requests, this is inconsistent
    if (request.url.includes('session')) {
      token = this._cookiesService.get(BB_REFRESH_TOKEN);
    }
    // everything below is unchanged

    //ignore request
    if (request.url.includes('refresh')) {
      return next.handle(request).pipe(
        catchError(responseError => {
          this._cookiesService.remove(BB_ACCESS_TOKEN);
          this._cookiesService.remove(BB_REFRESH_TOKEN);
          this._router.navigate(['/auth'], { queryParams: { unauthorized: true } });
          return throwError(responseError.error);
        })
      );
    }

    return next.handle(this.addTokenToRequest(request, token)).pipe(
      tap((res: HttpResponse<any>) => {
        if (res instanceof HttpResponse && res.status === 200 && res.headers.get('Bearer')) {
          const token = res.headers.get('Bearer');

          if (res.url.includes('session') && request.method === 'DELETE') {
            this._cookiesService.remove(BB_ACCESS_TOKEN);
            this._cookiesService.remove(BB_REFRESH_TOKEN);
          } else {
            this._cookiesService.put(BB_ACCESS_TOKEN, token);
            if (res.url.includes('session') && (request.method === 'POST' || request.method === 'PATCH')) {
              this._cookiesService.put(BB_REFRESH_TOKEN, token);
            }
          }
        }
      }),
      catchError(responseError => {
        if (responseError instanceof HttpErrorResponse) {
          switch (responseError.status) {
            case 401:
              if (responseError.error.error.includes('Token')) {
                return this.handle401Error(request, next);
              } else {
                this._router.navigate(['/auth'], { queryParams: { unauthorized: true } });
                return throwError(responseError.error);
              }
            default:
              return throwError(responseError.error);
          }
        } else {
          return throwError(responseError.error);
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this._cookiesService.get(BB_REFRESH_TOKEN));

      return this._sessionService.refresh(headers).pipe(
        switchMap((session: HttpResponse<SessionModel>) => {
          const token = session.headers.get('Bearer');
          this._cookiesService.put(BB_ACCESS_TOKEN, token);
          this._cookiesService.put(BB_REFRESH_TOKEN, token);
          this.tokenSubject.next(token);
          return next.handle(this.addTokenToRequest(request, token));
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addTokenToRequest(request, token)))
      );
    }
  }
}
