/**
 * THIS FILE IS AUTOGENERATED
 */
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { LocationModel } from '@shared/entity/location/model';
import { MenuModel } from '@shared/entity/menu/model';
import { OrderProductModel } from '../../order-product/model';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { OrderStatusEnum } from '../../order/enumeration';

export class MenuOrderModel extends EntityModel {
  // Add additional properties here
  merchant: TypedEntityInterface;
  customer: LocationModel;
  menu: MenuModel;
  order: TypedEntityInterface;
  products: OrderProductModel[];
  subtotal: number;
  sales_tax_total: number;
  excise_tax_total: number;
  total: number;
  status: OrderStatusEnum;
  notes: string;

  constructor(menuOrder?: Partial<MenuOrderModel>) {
    super();
    if (menuOrder) Object.assign(this, menuOrder);
  }

  isProductInOrder(orderProduct: OrderProductModel): boolean {
    return coerceBooleanProperty(this.products.find(product => product.uuid === orderProduct.uuid));
  }

  getProductQuantity(updatedOrderProduct: OrderProductModel): number {
    const productInOrder = this.products.find(product => product.uuid === updatedOrderProduct.uuid);
    return productInOrder ? productInOrder.quantity : 0;
  }

  getOrderNumber(): string {
    const randomInt = this.uuid.slice(-2).padStart(3, '0');
    const timestamp = new Date(this.created_at).getTime() / 1000;
    return timestamp + randomInt;
  }
}
