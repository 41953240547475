import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import * as moment from 'moment';
import { LocationModel } from '../../location/model';
import { DiscountStatusEnum, DiscountTargetEnum, DiscountTypeEnum } from '../enumeration';
import { DaysEnum } from '@shared/enumeration/days/days.enum';

interface AvailableDaysInterface {
  sunday?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
}

export class DiscountParameters extends OffsetHttpParameters {
  protected searchJSON: {
    location: { distinct: string };
    loyalty_program: { distinct: string };
    has_loyalty_program: boolean;
    requires_points: boolean;
    has_code: boolean;
    discount: Partial<{ code: string; name: string }>;
    object_type: { in: DiscountTargetEnum[] };
    status: { in: DiscountStatusEnum[] };
    type: { in: DiscountTypeEnum[] };
    available_online: boolean;
    available_to_tier: { distinct: string };
    has_minimum_loyalty_tier: boolean;
    available_date: DateRangeFieldInterface;
    available_days: number[] | AvailableDaysInterface;
  };

  constructor(params?: Partial<DiscountParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  resetSearch() {
    this.searchJSON = {
      location: undefined,
      loyalty_program: undefined,
      has_loyalty_program: undefined,
      requires_points: undefined,
      has_code: undefined,
      discount: undefined,
      object_type: undefined,
      status: undefined,
      type: undefined,
      available_online: undefined,
      available_to_tier: undefined,
      has_minimum_loyalty_tier: undefined,
      available_date: undefined,
      available_days: undefined
    };
  }

  setWhereLocation(location?: TypedEntityInterface | LocationModel): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereLoyaltyProgram(program?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, loyalty_program: program ? { distinct: program.uuid } : undefined };
    return this;
  }

  setWhereHasLoyaltyProgram(hasProgram?: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_loyalty_program: hasProgram };
    return this;
  }

  setWhereRequiresPoints(requiresPoints?: boolean): this {
    this.searchJSON = { ...this.searchJSON, requires_points: requiresPoints };
    return this;
  }

  setWhereHasCode(hasCode: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_code: hasCode };
    return this;
  }

  setWhereCodeLike(code?: string): this {
    let discount = this.searchJSON.discount;
    discount = code ? (discount ? { ...discount, code } : { code }) : undefined;
    this.searchJSON = { ...this.searchJSON, discount };
    return this;
  }

  setWhereName(name?: string): this {
    let discount = this.searchJSON.discount;
    discount = name ? (discount ? { ...discount, name } : { name }) : undefined;
    this.searchJSON = { ...this.searchJSON, discount };
    return this;
  }

  setWhereObjectTypeIn(objTypes?: DiscountTargetEnum[]): this {
    this.searchJSON = { ...this.searchJSON, object_type: objTypes && objTypes.length ? { in: objTypes } : undefined };
    return this;
  }

  setWhereStatusIn(statuses?: DiscountStatusEnum[]): this {
    this.searchJSON = { ...this.searchJSON, status: statuses && statuses.length ? { in: statuses } : undefined };
    return this;
  }

  setWhereTypeIn(types?: DiscountTypeEnum[]): this {
    this.searchJSON = { ...this.searchJSON, type: types && types.length ? { in: types } : undefined };
    return this;
  }

  setWhereAvailableOnline(availableOnline?: boolean): this {
    this.searchJSON = { ...this.searchJSON, available_online: availableOnline };
    return this;
  }

  setWhereAvailableToTier(tier?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, available_to_tier: tier ? { distinct: tier.uuid } : undefined };
    return this;
  }

  setWhereHasMinimumLoyaltyTier(hasMinimumTier?: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_minimum_loyalty_tier: hasMinimumTier };
    return this;
  }

  setWhereAvailableDate(dateRange?: DateRangeFieldInterface): this {
    this.searchJSON = { ...this.searchJSON, available_date: dateRange };
    return this;
  }

  setWhereAvailableDays(days: number[]) {
    this.searchJSON = { ...this.searchJSON, available_days: days !== null ? this.mapDays(days) : undefined };
    return this;
  }

  setWhereAvailableToday(): this {
    const today = moment();
    this.setWhereAvailableDays(<any>[today.day()])
      .setWhereAvailableDate({
        start: today.format('YYYY-MM-DD'),
        end: today.format('YYYY-MM-DD')
      })
      .setWhereStatusIn([DiscountStatusEnum.AVAILABLE]);
    return this;
  }

  getAvailableTodayParams() {
    const today = moment();
    return {
      days: [today.day().toString()],
      date_range: {
        start: today.format('YYYY-MM-DD'),
        end: today.format('YYYY-MM-DD')
      },
      status: DiscountStatusEnum.AVAILABLE
    };
  }

  getParams() {
    return {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
  }

  private mapDays(days: number[]) {
    if (!days) {
      return days;
    }

    return days.reduce((availableDays: AvailableDaysInterface, day) => {
      switch (day) {
        case DaysEnum.Sunday:
          availableDays.sunday = true;
          break;
        case DaysEnum.Monday:
          availableDays.monday = true;
          break;
        case DaysEnum.Tuesday:
          availableDays.tuesday = true;
          break;
        case DaysEnum.Wednesday:
          availableDays.wednesday = true;
          break;
        case DaysEnum.Thursday:
          availableDays.thursday = true;
          break;
        case DaysEnum.Friday:
          availableDays.friday = true;
          break;
        case DaysEnum.Saturday:
          availableDays.saturday = true;
          break;
      }

      return availableDays;
    }, {});
  }
}
