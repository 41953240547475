/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { LoyaltyProgramModel } from '../../loyalty-program/model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';

type LoyaltyTierOrderByTypes = 'points_required' | 'name';

export class LoyaltyTierParameters extends OffsetHttpParameters {
  protected searchJSON: { [key: string]: any } = {
    loyalty_program: undefined,
    name: undefined
  };

  constructor(params?: Partial<LoyaltyTierParameters>) {
    super();
    if(params) Object.assign(this, params);
    this.setOrderBy('points_required').setOrder('DESC');
  }

  setOrderBy(orderBy: LoyaltyTierOrderByTypes) {
    return super.setOrderBy('loyalty_tier.' + orderBy);
  }

  setWhereLoyaltyProgram(program?: LoyaltyProgramModel | TypedEntityInterface) {
    this.searchJSON.loyalty_program = program ? { distinct: program.uuid } : undefined;
    return this;
  }

  setWhereNameLike(name?: string) {
    this.searchJSON.name = name ? { like: name } : undefined;
    return this;
  }

  setWhereName(name?: string): this {
    return this.setWhereNameLike(name);
  }

  getParams() {
    const params =  {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
