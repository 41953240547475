/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DiscountTypeEnum } from '../../discount/enumeration';

type IntegrationOrderByTypes =
  | 'product.name'
  | 'product.stock'
  | 'product.price'
  | 'product.created_at'
  | 'product.updated_at';

export class IntegrationProductParameters extends OffsetHttpParameters {
  protected has_stock: number;
  protected connected: boolean;
  protected searchJSON: Partial<{
    product: { name: string };
    integration: { in: { uuid: string }[] };
    location: { distinct: string };
  }> = {};

  constructor(params?: Partial<IntegrationProductParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: IntegrationOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, product: name ? { name: name } : undefined };
    return this;
  }

  setWhereIntegrationIn(integrations?: TypedEntityInterface[]): this {
    this.searchJSON = { ...this.searchJSON, integration: integrations && integrations.length ? { in: integrations.map(integration => ({ uuid: integration.uuid })) } : undefined };
    return this;
  }

  setWhereLocation(location?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereConnected(connected?: boolean): this {
    this.connected = typeof connected === 'boolean' ? connected : undefined;
    return this;
  }

  setWhereHasStock(hasStock?: number): this {
    this.has_stock = hasStock !== undefined ? hasStock : undefined;
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      has_stock: this.has_stock,
      connected: this.connected,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
