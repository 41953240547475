import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TypesEnum }                             from '@shared/enumeration/type/types.enum';
import { AclInterface }                          from '@shared/model/acl/acl.interface';
import { RolesEnum }                             from '../../enumeration';
import { SessionModel }                          from '../../model';
import { State }                                 from '../reducers/session.reducer';

export const SESSION_KEY = 'SESSION';

/*
 *   SESSION SELECTORS
 */

const getSessionState = createFeatureSelector<State>(SESSION_KEY);

const getLoading = createSelector(
  getSessionState,
  (state: State) => state.loading
);

const getUpdating = createSelector(
  getSessionState,
  (state: State) => state.updating
);

const getAuthenticated = createSelector(
  getSessionState,
  (state: State) => state.authenticated
);

const getSession = createSelector(
  getSessionState,
  (state: State) => state.session
);

const getError = createSelector(
  getSessionState,
  (state: State) => state.error
);

const getUserFullName = createSelector(
  getSession,
  (session: SessionModel) => (session ? session.name : null)
);

/*
 *   ACL SELECTORS
 */

const getAcls = createSelector(
  getSession,
  (session: SessionModel) => (session ? session.acl : [])
);

const getLocationAclsByOrganization = createSelector(
  getSessionState,
  (state: State) => state.aclsByOrganization
);

const getCurrentAcl = createSelector(
  getSession,
  (session: SessionModel) => (session ? session.current_acl : null)
);

const getCurrentAclTarget = createSelector(
  getCurrentAcl,
  (acl: AclInterface) => (acl ? acl.target : null)
);

const getEmployee = createSelector(
  getCurrentAcl,
  (acl: AclInterface) => (acl ? acl.employee : null)
);

const getCurrentRole = createSelector(
  getCurrentAcl,
  (acl: AclInterface) => (acl ? acl.role.name : '')
);

const getIsOrganizationAcl = createSelector(
  getCurrentAcl,
  (acl: AclInterface) => acl && acl.target._type === TypesEnum.ORGANIZATIONS
);

const getIsOrganizationAdmin = createSelector(
  getIsOrganizationAcl,
  getCurrentRole,
  (isOrganizationAcl: boolean, role: string) => (isOrganizationAcl ? role === RolesEnum.ADMIN : false)
);

const getIsLocationAcl = createSelector(
  getCurrentAcl,
  (acl: AclInterface) => acl && acl.target._type === TypesEnum.LOCATIONS
);

const getIsLocationAdmin = createSelector(
  getIsLocationAcl,
  getCurrentRole,
  (isLocationAcl: boolean, role: string) => (isLocationAcl ? role === RolesEnum.ADMIN : false)
);

const getIsLocationManager = createSelector(
  getIsLocationAcl,
  getCurrentRole,
  (isLocationAcl: boolean, role: string) => (isLocationAcl ? role === RolesEnum.MANAGER : false)
);

const getIsLocationTeller = createSelector(
  getIsLocationAcl,
  getCurrentRole,
  (isLocationAcl: boolean, role: string) => (isLocationAcl ? role === RolesEnum.TELLER : false)
);

const getIsLocationDispatcher = createSelector(
  getIsLocationAcl,
  getCurrentRole,
  (isLocationAcl: boolean, role: string) => (isLocationAcl ? role === RolesEnum.DISPATCHER : false)
);

const getIsLocationDriver = createSelector(
  getIsLocationAcl,
  getCurrentRole,
  (isLocationAcl: boolean, role: string) => (isLocationAcl ? role === RolesEnum.DRIVER : false)
);

// Is the current user a location admin or organization admin (owner)?
const getIsLocationAdminOrAbove = createSelector(
  getIsLocationAdmin,
  getIsOrganizationAdmin,
  (isLocationAdmin: boolean, isOrganizationAdmin: boolean) => isLocationAdmin || isOrganizationAdmin
);

// Is the current user a location manager, location admin, or organization admin?
const getIsLocationManagerOrAbove = createSelector(
  getIsLocationManager,
  getIsLocationAdmin,
  getIsOrganizationAdmin,
  (isLocationManager: boolean, isLocationAdmin: boolean, isOrganizationAdmin: boolean) =>
    isLocationManager || isLocationAdmin || isOrganizationAdmin
);

// Is the current user a location teller, location manager, location admin, or organization admin?
const getIsLocationTellerOrAbove = createSelector(
  getIsLocationTeller,
  getIsLocationManagerOrAbove,
  (isLocationTeller: boolean, isLocationManagerOrAbove: boolean) => isLocationTeller || isLocationManagerOrAbove
);

export const sessionQuery = {
  getLoading,
  getUpdating,
  getError,
  getAuthenticated,
  getSession,
  getAcls,
  getLocationAclsByOrganization,
  getCurrentAcl,
  getCurrentAclTarget,
  getEmployee,
  getCurrentRole,
  getUserFullName,
  getIsOrganizationAcl,
  getIsOrganizationAdmin,
  getIsLocationAcl,
  getIsLocationAdmin,
  getIsLocationManager,
  getIsLocationTeller,
  getIsLocationDispatcher,
  getIsLocationDriver,
  getIsLocationAdminOrAbove,
  getIsLocationManagerOrAbove,
  getIsLocationTellerOrAbove
};
