<input
  matInput
  [attr.data-private]="private ? 'lipsum' : null"
  [textMask]="{ mask: mask, keepCharPositions: false, guide: false }"
  [placeholder]="placeholder"
  [attr.id]="id"
  (input)="onInput($event)"
  [formControl]="ngControl?.control"
  [required]="required"
/>
