import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { OffsetHttpParameters, RestApiService } from '@shared/utility/api';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CheckinSnapshotInterface, LocationConsumerCheckinAnalytics } from '../model';
import { CheckinModel } from '../model/checkin.model';
import { CheckinParameters } from '.';

@Injectable({ providedIn: 'root' })
export class CheckinService extends RestApiService<CheckinModel> {
  readonly basePath = '/checkin/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  loadSnapshot<r extends OffsetHttpParameters>(params?: r) {
    const filteredParams = params ? { params: params.getFilteredParams(['search', 'timezone', 'status[]']) } : undefined;
    return this.http.get<CheckinSnapshotInterface>(this.getPath('snapshot'), filteredParams).pipe(
      catchError(err =>
        of({
          checkin_count: undefined,
          consumer_checkin_count: undefined,
          consumer_count: undefined,
          drawing_checkin_count: undefined,
          event_checkin_count: undefined,
          first_checkins: undefined,
          guest_checkin_count: undefined
        })
      )
    );
  }

  loadAnalyticsSnapshot(
    endpoint: string,
    params: CheckinParameters
  ): Observable<Array<LocationConsumerCheckinAnalytics>> {
    const filteredParams = params ? { params: params.getFilteredParams(['search', 'timezone', 'status[]']) } : undefined;
    return this.http.get<Array<LocationConsumerCheckinAnalytics>>(this.getPath(`snapshot/${endpoint}`), filteredParams);
  }
}
