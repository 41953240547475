import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';

interface TypedEntityWithName extends TypedEntityInterface {
  name: string;
}

export function nameFieldDisplayFn(entity): string {
  if ('_name' in entity) {
    return `${entity._name}`;
  }
  if ('name' in entity) {
    return `${entity.name.first} ${entity.name.last}`;
  }
  return entity.name;
}

export function defaultDisplayFn(value): string {
  return value && value.name ? value.name : value && value._name ? value._name : undefined;
}

export function typedEntityValueFn<T extends TypedEntityWithName>(entity: T) {
  if (!entity) {
    return null;
  }
  return Object.assign({}, { _name: entity.name, uuid: entity.uuid, _type: entity._type });
}
