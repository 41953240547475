import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { EntityMetaModel } from '@shared/model/entity/meta/entity-meta.model';
import { RestApiService } from '@shared/utility/api';
import { LocationModel } from '../../model';

export enum LocationMetaKeys {
  RECEIPT_TEXT = 'location_receipt_text',
  LABEL_TEXT = 'location_label_text'
}

@Injectable({
  providedIn: 'root'
})
export class LocationMetaService extends RestApiService<EntityMetaModel> {
  basePath: string = '/location/{location}/meta/';
  readonly uniqueIdentifier: string = 'uuid';

  constructor(location: LocationModel, apiConfig: ApiConfig, protected http: HttpClient) {
    super(apiConfig, http);
    this.setBasePath(location);
  }

  setBasePath(location: LocationModel) {
    this.basePath = this.basePath.replace('{location}', location.uuid);
  }

  getReceiptCopy() {
    return this.http.get(this.getPath(LocationMetaKeys.RECEIPT_TEXT));
  }

  getLabelCopy() {
    return this.http.get(this.getPath(LocationMetaKeys.LABEL_TEXT));
  }
}
