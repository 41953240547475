import { EntityModel } from '../entity.model';
import { TypedEntityInterface } from '../typed-entity.interface';
import { TypesEnum } from '@shared/enumeration/type/types.enum';

export class ScheduleDayModel {
  constructor(public open: boolean = null, public start: string = null, public stop: string = null) {}
}

export class ScheduleModel extends EntityModel implements TypedEntityInterface {
  constructor(
    public _type: TypesEnum = TypesEnum.SCHEDULES,
    public _name: string = null,
    public timezone: string = null,
    public sunday: ScheduleDayModel = new ScheduleDayModel(),
    public monday: ScheduleDayModel = new ScheduleDayModel(),
    public tuesday: ScheduleDayModel = new ScheduleDayModel(),
    public wednesday: ScheduleDayModel = new ScheduleDayModel(),
    public thursday: ScheduleDayModel = new ScheduleDayModel(),
    public friday: ScheduleDayModel = new ScheduleDayModel(),
    public saturday: ScheduleDayModel = new ScheduleDayModel()
  ) {
    super();
  }
}
