import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import { LoyaltyRewardStatusEnum } from '../enumeration';

export class LoyaltyRewardParameters extends OffsetHttpParameters {
  protected status: LoyaltyRewardStatusEnum[];
  protected searchJSON: Partial<{
    order: { distinct: string };
    location: { distinct: string };
    consumer: { distinct: string };
    loyalty_reward: { type: number };
    updated_at: DateRangeFieldInterface;
  }> = {};

  constructor(params?: Partial<LoyaltyRewardParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereOrder(order?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, order: order ? { distinct: order.uuid } : undefined };
    return this;
  }

  setWhereLocation(location?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereConsumer(consumer?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, consumer: consumer ? { distinct: consumer.uuid } : undefined };
    return this;
  }

  setWhereLoyaltyRewardType(type?: number) {
    this.searchJSON = { ...this.searchJSON, loyalty_reward: type ? { type } : undefined };
    return this;
  }

  setWhereStatus(status: LoyaltyRewardStatusEnum | LoyaltyRewardStatusEnum[]) {
    if (status !== null) {
      if (Array.isArray(status)) {
        this.status = status.length ? status : undefined;
      } else if (status !== undefined) {
        this.status = [status];
      } else if (status === undefined) {
        this.status = status as any;
      }
    }
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      'status[]': this.status,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
