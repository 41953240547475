import {AdminPolicyPermissionsEnum} from '@shared/policy';

// TODO: Get list from product and finalize permissions
export const SUPPORT_PERMISSIONS: AdminPolicyPermissionsEnum[] = [
  // Cards
  AdminPolicyPermissionsEnum.VIEW_CUSTOMER_SERVICE_CARD,
  AdminPolicyPermissionsEnum.VIEW_CLIENTS_CARD,

  // Customer Service
  AdminPolicyPermissionsEnum.VIEW_USER_CONSUMERS,
  AdminPolicyPermissionsEnum.UPDATE_USER_CONSUMERS,

  AdminPolicyPermissionsEnum.VIEW_LOCATION_CONSUMERS,
  AdminPolicyPermissionsEnum.UPDATE_LOCATION_CONSUMERS,

  AdminPolicyPermissionsEnum.VIEW_INTEGRATION_CONSUMERS,

  AdminPolicyPermissionsEnum.VIEW_INTEGRATION_ORDERS,
  AdminPolicyPermissionsEnum.UPDATE_INTEGRATION_ORDERS,

  AdminPolicyPermissionsEnum.VIEW_IDENTITIES,

  AdminPolicyPermissionsEnum.VIEW_USER_ACL,

  AdminPolicyPermissionsEnum.VIEW_USERS,

  AdminPolicyPermissionsEnum.VIEW_LISTINGS,

  // Clients
  AdminPolicyPermissionsEnum.VIEW_CLIENT_SEARCH,

  AdminPolicyPermissionsEnum.VIEW_LOCATIONS,

  AdminPolicyPermissionsEnum.VIEW_ORGANIZATIONS,

  AdminPolicyPermissionsEnum.VIEW_RATE_FUNCTIONS,

  AdminPolicyPermissionsEnum.VIEW_VEHICLES,

  // Settings
  AdminPolicyPermissionsEnum.VIEW_ENTITY_ACLS,

  AdminPolicyPermissionsEnum.VIEW_CANCELLATION_REASONS,
];
