import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../../form';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LicenseClassAutocompleteComponent } from './license-class-autocomplete.component';

@NgModule({
  declarations: [LicenseClassAutocompleteComponent],
  imports: [
    CommonModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule, FormModule, MatProgressBarModule
  ],
  exports: [LicenseClassAutocompleteComponent]
})
export class LicenseClassAutocompleteModule {
}
