import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { MenuModel } from '../../menu/model';
import { MenuOrderModel } from '../../menu-order/model';
import { OrderProductModel } from '../../order-product/model';
import { RestApiService } from '@shared/utility/api';

@Injectable({ providedIn: 'root' })
export class MenuOrderProductService extends RestApiService<OrderProductModel>  {
  basePath = '/menu/{menu-uuid}/order/{order-uuid}/product/';
  protected uniqueIdentifier: string = 'uuid';

  constructor(menu: MenuModel, order: MenuOrderModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{menu-uuid}', menu.uuid).replace('{order-uuid}', order.uuid);
  }

  protected getIdentifier(entity: OrderProductModel) {
    return entity[this.uniqueIdentifier];
  }
}
