/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { ProductCategoryModel } from '../model/product-category.model';

@Injectable({ providedIn: 'root'})
export class ProductCategoryService extends RestApiService<ProductCategoryModel> {

  readonly basePath = '/product/category/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

}
