import { CategoryModel } from '@shared/entity/category/model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import { isEmpty } from 'lodash';
import { InventoryStatusEnum, InventoryZoneEnum } from '../enumeration';

export type InventoryOrderByTypes =
  | 'brand_name'
  | 'product_name'
  | 'product.location.name'
  | 'product.name'
  | 'product.integration_product.name';

interface RequestSearchInterface {
  product: { distinct: string };
  location: { distinct: string };
  type: { distinct: string };
  parent: { distinct: string };
  category: { distinct: string };
  brand: { distinct: string };
}

export class InventoryParameters extends OffsetHttpParameters {
  private searchJSON: Partial<{
    inventory: Partial<{ batch: string; sku: string; name: string }>;
    status: { in: InventoryStatusEnum[] };
    location: { distinct: string };
    purchase_order: { distinct: string };
    owner: { distinct: string };
    originating_location: { distinct: string };
    product: { request: { search: Partial<RequestSearchInterface> } } | { distinct: string };
    integration_product: { distinct: string };
    featured: boolean;
  }> = {};
  private requestSearchJSON: Partial<RequestSearchInterface> = {};
  private active: boolean;
  private in_menu: boolean;
  private has_integration_inventory: boolean;
  private zones_have_stock: InventoryZoneEnum[];

  constructor(params?: Partial<InventoryParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: InventoryOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereActive(active?: boolean): this {
    this.active = active;
    return this;
  }

  setWhereInMenu(inMenu?: boolean): this {
    this.in_menu = inMenu;
    return this;
  }

  setWhereHasIntegrationInventory(hasIntegrationInventory?: boolean): this {
    this.has_integration_inventory = hasIntegrationInventory;
    return this;
  }

  setWhereZonesHaveStock(zones?: InventoryZoneEnum[]): this {
    this.zones_have_stock = zones && zones.length ? zones : undefined;
    return this;
  }

  setWhereInventoryBatch(batch?: string): this {
    this.searchJSON = { ...this.searchJSON, inventory: { ...this.searchJSON.inventory, batch } };
    return this;
  }

  setWhereInventorySku(sku?: string): this {
    this.searchJSON = { ...this.searchJSON, inventory: { ...this.searchJSON.inventory, sku } };
    return this;
  }

  setWhereInventoryName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, inventory: { ...this.searchJSON.inventory, name } };
    return this;
  }

  setWhereName(name?: string): this {
    return this.setWhereInventoryName(name);
  }

  setWhereStatusIn(statuses?: InventoryStatusEnum[]): this {
    this.searchJSON = { ...this.searchJSON, status: statuses && statuses.length ? { in: statuses } : undefined };
    return this;
  }

  setWhereFeatured(featured?: boolean): this {
    if (featured !== null) {
      this.searchJSON = { ...this.searchJSON, featured: featured };
    } else {
      this.searchJSON = { ...this.searchJSON, featured: undefined };
    }
    return this;
  }

  setWhereLocation(location?: { uuid: string }): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWherePurchaseOrder(purchaseOrderUuid?: string): this {
    this.searchJSON = {
      ...this.searchJSON,
      purchase_order: purchaseOrderUuid ? { distinct: purchaseOrderUuid } : undefined
    };
    return this;
  }

  setWhereOwner(owner?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, owner: owner ? { distinct: owner.uuid } : undefined };
    return this;
  }

  setWhereOriginatingLocation(location?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, originating_location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereProductCategory(category?: CategoryModel | TypedEntityInterface) {
    this.requestSearchJSON = {
      ...this.requestSearchJSON,
      category: category ? { distinct: category.uuid } : undefined
    };
    return this;
  }

  setWhereBrand(brand?: TypedEntityInterface) {
    this.requestSearchJSON = { ...this.requestSearchJSON, brand: brand ? { distinct: brand.uuid } : undefined };
    return this;
  }

  setWhereProduct(product?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, product: product ? { distinct: product.uuid } : undefined };
    return this;
  }

  setWhereIntegrationProduct(product?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, integration_product: product ? { distinct: product.uuid } : undefined };
    return this;
  }

  getParams(): { [p: string]: any } {
    const search = JSON.stringify({
      ...this.searchJSON,
      ...{ product: this.searchJSON.product ? this.searchJSON.product : this.getProductRequestSearch() }
    });

    return this.removeUndefinedValues({
      ...super.getParams(),
      active: this.active,
      in_menu: this.in_menu,
      has_integration_inventory: this.has_integration_inventory,
      'zones_have_stock[]': this.zones_have_stock,
      search
    });
  }

  private getProductRequestSearch() {
    return isEmpty(this.removeUndefinedValues(this.requestSearchJSON))
      ? undefined
      : { request: { search: this.requestSearchJSON } };
  }
}
