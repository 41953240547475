import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OffsetHttpParameters } from '@shared/utility/api';

type ProductOrderByTypes = 'name' | 'product.name';

export class ProductParameters extends OffsetHttpParameters {
  protected hasStockInZones: number[];
  protected hasActiveInventory: boolean;
  protected normalized: boolean;
  protected isParent: boolean;
  protected hasDescription: boolean;
  protected hasImage: boolean;
  protected inheritParentFields: boolean;

  protected searchJSON: Partial<{
    uuid: { in: string[] } | { not_in: string[] } | { distinct: string };
    category: { in: string[] } | { distinct: string };
    brand: { in: string[] } | { distinct: string };
    type: { distinct: string };
    location: { distinct: string };
    product: { name: string };
    parent: { distinct: string };
  }> = {};

  constructor(params?: Partial<ProductParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: string): this {
    return super.setOrderBy(orderBy);
  }

  setHasStockInZones(zones?: number[]) {
    this.hasStockInZones = zones && zones.length ? zones : undefined;
    return this;
  }

  setHasActiveInventory(isActive?: boolean) {
    this.hasActiveInventory = isActive !== undefined ? isActive : undefined;
    return this;
  }

  setHasDescription(hasDescription?: boolean) {
    this.hasDescription = hasDescription !== undefined ? hasDescription : undefined;
    return this;
  }

  setHasImage(hasImage?: boolean) {
    this.hasImage = hasImage !== undefined ? hasImage : undefined;
    return this;
  }

  setNormalized(normalized?: boolean) {
    this.normalized = normalized !== undefined ? normalized : undefined;
    return this;
  }

  setIsParent(isParent?: boolean) {
    this.isParent = isParent !== undefined ? isParent : undefined;
    return this;
  }

  setCategory(category?: TypedEntityInterface | TypedEntityInterface[]) {
    if (Array.isArray(category)) {
      const ids = category.map(category => category.uuid);
      this.searchJSON = { ...this.searchJSON, category: category.length ? { in: ids } : undefined };
    } else {
      this.searchJSON = { ...this.searchJSON, category: category ? { distinct: category.uuid } : undefined };
    }

    return this;
  }

  setInheritParentFields(inheritParentFields?: boolean) {
    this.inheritParentFields = inheritParentFields !== undefined ? inheritParentFields : undefined;
    return this;
  }

  setUuid(uuid, not_in: boolean = false) {
    if (Array.isArray(uuid)) {
      const search = not_in ? {not_in: uuid} : {in: uuid};
      this.searchJSON = { ...this.searchJSON, uuid: uuid.length ? search : undefined };
    } else {
      this.searchJSON = { ...this.searchJSON, uuid: uuid ? { distinct: uuid } : undefined };
    }

    return this;
  }

  setBrand(brand?: TypedEntityInterface | TypedEntityInterface[]) {
    if (Array.isArray(brand)) {
      const ids = brand.map(category => category.uuid);
      this.searchJSON = { ...this.searchJSON, brand: brand.length ? { in: ids } : undefined };
    } else {
      this.searchJSON = { ...this.searchJSON, brand: brand ? { distinct: brand.uuid } : undefined };
    }

    return this;
  }

  setType(type?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, type: type ? { distinct: type.uuid } : undefined };
    return this;
  }

  setLocation(location?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: location.uuid } : undefined };
    return this;
  }

  setWhereName(name?: string) {
    this.searchJSON = { ...this.searchJSON, product: name ? { name } : undefined };
    return this;
  }

  setParent(product?: TypedEntityInterface) {
    this.searchJSON = { ...this.searchJSON, parent: product ? { distinct: product.uuid } : undefined };
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      normalized: this.normalized,
      has_active_inventory: this.hasActiveInventory,
      'has_stock_in_zones[]': this.hasStockInZones,
      is_parent: this.isParent,
      has_description: this.hasDescription,
      has_image: this.hasImage,
      inherit_parent_fields: this.inheritParentFields,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
