import { EntityModel } from '@shared/model/entity/entity.model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';

export class BrandModel extends EntityModel {
  color: string;
  name: string;
  description: string;
  logo: UploadModel;
  status: number; // TODO: Set BrandStatusEnum

  constructor(brand?: Partial<BrandModel>) {
    super();
    if (brand) Object.assign(this, brand);
  }
}
