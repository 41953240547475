/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { MultiRestMethods, RestApiService } from '@shared/utility/api';
import { StationSnapshotInterface } from '../model/station-snapshot.interface';
import { StationModel } from '../model/station.model';
import { StationParameters } from './station.parameters';
import { OffsetHttpParameters, CursorHttpParameters } from '@shared/utility/api';
import { Observable } from 'rxjs';
import { CollectionInterface } from '@shared/model/collection/collection.interface';

@Injectable({ providedIn: 'root' })
export class StationService extends RestApiService<StationModel> {
  basePath = '/v2/stations/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  setDoloresApiBasePath() {
    this.basePath = '/v2/stations/';
    return this;
  }

  setMyBbApiBasePath() {
    this.basePath = '/station/';
    return this;
  }

  loadCollection<P extends OffsetHttpParameters | CursorHttpParameters>(
    params?: P
  ): Observable<{ results: CollectionInterface<StationModel> } | any> {
    return this.http.post<CollectionInterface<StationModel>>(`${this.getDoloresPath()}/search`, params.getParams());
  }

  insert(station: StationModel): Observable<StationModel> {
    return this.http.post<StationModel>(this.getDoloresPath(), station);
  }

  update(station: StationModel): Observable<StationModel> {
    return this.http.patch<StationModel>(this.getDoloresPath(this.getIdentifier(station)), station);
  }

  load(station: StationModel | StationModel[]): Observable<StationModel | StationModel[]> {
    if (Array.isArray(station)) return this.handleMultiple(station, MultiRestMethods.LOAD);
    return this.http.get<StationModel>(this.getDoloresPath(this.getIdentifier(station)));
  }

  // BUGBUG add an upsert and adjust method
  updateAndAdjust(station: StationModel | StationModel[]): Observable<StationModel | StationModel[]> {
    if (Array.isArray(station)) return this.handleMultiple(station, MultiRestMethods.UPDATE, 'patch');
    return this.http.patch<StationModel>(this.getPath(this.getIdentifier(station)), station);
  }

  delete(station: StationModel | StationModel[]): Observable<StationModel | StationModel[]> {
    if (Array.isArray(station)) return this.handleMultiple(station, MultiRestMethods.DELETE);
    return this.http.delete<StationModel>(this.getDoloresPath(this.getIdentifier(station)));
  }

  loadSnapshot(params?: StationParameters) {
    const filteredParams = params ? params.getFilteredParams() : undefined;
    return this.http.get<StationSnapshotInterface>(this.getPath('snapshot'), { params: filteredParams });
  }
}
