/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

type StrainOrderByTypes = 'strain.name';

export class StrainParameters extends OffsetHttpParameters {
  protected hasDescription: boolean;

  protected searchJSON: Partial<{
    strain: { name: string };
  }> = {};

  constructor(params?: Partial<StrainParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: StrainOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, strain: name ? { name: name } : undefined };
    return this;
  }

  setHasDescription(hasDescription?: boolean): this {
    this.hasDescription = hasDescription;
    return this;
  }

  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      has_description: this.hasDescription,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
