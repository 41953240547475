import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IComment, ICommentCollection } from '../model';

export class CommentService {
  constructor(
    private path: string,
    private http: HttpClient,
    opts: Partial<{ loadOnInitialization: boolean }> = { loadOnInitialization: true }
  ) {
    if (opts.loadOnInitialization) this.loadComments().subscribe();
  }

  private _comments$ = new BehaviorSubject<ICommentCollection & { loading: boolean; loaded: boolean; error: boolean }>({
    records: [],
    total_records: 0,
    loading: false,
    loaded: false,
    error: false
  });

  get comments$() {
    return this._comments$.asObservable();
  }

  loadComments(): Observable<ICommentCollection> {
    this.setLoading();
    return this.http
      .get<ICommentCollection>(this.path + '/comments')
      .pipe(
        tap(data => this._comments$.next({ ...data, loading: false, loaded: true, error: false })),
        catchError(() => {
          const errorResponse = { records:[], total_records: 0, loading: false, loaded: false, error: true };
          this._comments$.next({ records:[], total_records: 0, loading: false, loaded: false, error: true })
          return of(errorResponse)
        })
      );
  }

  createComment(body: string): Observable<IComment> {
    this.setLoading();
    return this.http
      .post<IComment>(this.path + '/create_comment', { comment: { body } })
      .pipe(
        tap(data => {
          const collection = this._comments$.getValue();
          collection.records.unshift(data);
          collection.total_records++;
          this._comments$.next({
            ...collection,
            loading: false
          });
        }),
        catchError(err => {
          const collection = this._comments$.getValue();
          this._comments$.next({...collection, loading: false, loaded: true, error: true})
          return throwError(err);
        })
      );
  }

  deleteComment(comment: { uuid: string }): Observable<{ success: boolean; message: string }> {
    this.setLoading();
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { comment: { uuid: comment.uuid } }
    };
    return this.http.delete<{ success: boolean; message: string }>(this.path + '/destroy_comment', options).pipe(
      tap(() => {
        const collection = this._comments$.getValue();
        const idx = collection.records.findIndex(collectionComment => collectionComment.uuid === comment.uuid);
        collection.records.splice(idx, 1);
        collection.total_records--;
        this._comments$.next({
          ...collection,
          loading: false
        });
      }),
      catchError(err => {
        const collection = this._comments$.getValue();
        this._comments$.next({...collection, loading: false, loaded: true, error: true})
        return throwError(err);
      })
    );
  }

  private setLoading() {
    this._comments$.next({
      ...this._comments$.getValue(),
      loading: true
    });
  }
}
