import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'imagesize' })
export class ImageSizePipe implements PipeTransform {
  transform(value: string, size: '100' | '150' | '350'): any {
    if (!value) {
      return value;
    }

    return value.replace('/0x', '/' + size + 'x');
  }
}
