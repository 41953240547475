import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService, MultiRestMethods } from '@shared/utility/api';
import { UserAclModel } from '../model/user-acl.model';
import { Observable } from 'rxjs';
import { get } from 'lodash';

@Injectable({ providedIn: 'root' })
export class UserAclService extends RestApiService<UserAclModel> {
  readonly basePath = '/acl/user';
  readonly uniqueIdentifier = '';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  getIdentifier(acl: UserAclModel): string {
    return `/${acl.target._type}/${acl.target.uuid}/${acl.employee.uuid}`;
  }

  // TODO: can we standardize this request on the backend?
  getIdentifierAdminApiOnly(acl: UserAclModel): string {
    return `/${acl.target._type}/${acl.target.uuid}/${acl.user.uuid}/${acl.role.name}`;
  }

  deleteAdminApiOnly(entity: UserAclModel | UserAclModel[]): Observable<UserAclModel | UserAclModel[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.DELETE);
    return this.http.delete<UserAclModel>(this.getPath(this.getIdentifierAdminApiOnly(entity)));
  }
}
