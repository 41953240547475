/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { BrandModel } from '../model/brand.model';
import { BrandUploadService } from './upload/brand-upload.service';
import { BrandProductService } from './product/brand-product.service';

@Injectable({ providedIn: 'root' })
export class BrandService extends RestApiService<BrandModel> {
  readonly basePath = '/brand/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  getUploadService(brand: BrandModel) {
    return new BrandUploadService(brand, this.config, this.http);
  }

  getBrandProductService(brand: BrandModel) {
    return new BrandProductService(brand, this.config, this.http);
  }
}
