import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { OrderRefundProductModel } from './order-refund-product.model';

export class OrderRefundModel extends EntityModel {
  public products: OrderRefundProductModel[] = [];
  public station: TypedEntityInterface;
  public product_amount: number;
  public delivery_rate_amount: number;
  public tax_amount: number;
  public surcharge_amount: number;
  public total: number;
  public notes: string;

  constructor(orderRefund?: Partial<OrderRefundModel>) {
    super();
    if (orderRefund) Object.assign(this, orderRefund);
  }
}
