import { EntityModel } from '@shared/model/entity/entity.model';

export class OrderSurchargeModel extends EntityModel {
  public uuid: string
  public name: string
  public rate: number
  public rate_type: string
  public amount: number

  constructor(data: Partial<OrderSurchargeModel>) {
    super();

    if (data) {
      Object.assign(this, data);
    }
  }

  public isPercentage() {
    return this.rate_type == 'percentage';
  }
}
