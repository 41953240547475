import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { LocationModel } from '../../model';
import { ServiceModel } from '@shared/entity/service/model';

@Injectable({
  providedIn: 'root'
})
export class LocationServiceService extends RestApiService<ServiceModel> {
  basePath: string = '/location/{location}/service-type/';
  readonly uniqueIdentifier: string = 'uuid';

  constructor(location: LocationModel, apiConfig: ApiConfig, protected http: HttpClient) {
    super(apiConfig, http);
    this.setBasePath(location);
  }

  setBasePath(location: LocationModel) {
    this.basePath = this.basePath.replace('{location}', location.uuid);
  }
}
