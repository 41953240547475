import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { CashBalanceTypesEnum } from '../enumeration/cash-balance-types.enum';
import { CashBalanceStatusEnum } from '../enumeration/cash-balance-status.enum';

export class CashBalanceModel extends EntityModel {
  readonly _type = TypesEnum.CASH_JOBS;
  balance: number;
  initial_balance: number;
  outstanding_balance: number;
  paid: boolean;
  notes: string;
  settled_notes: string;
  created_by: TypedEntityInterface;
  delivery: TypedEntityInterface;
  status: number;
  type: CashBalanceTypesEnum;
  from: TypedEntityInterface;
  to: TypedEntityInterface;

  constructor(cashBalance?: Partial<CashBalanceModel>) {
    super();
    if (cashBalance) Object.assign(this, cashBalance);
  }

  get is_settled(): boolean {
    return this.status === CashBalanceStatusEnum.SETTLED;
  }

  get is_archived(): boolean {
    return this.status === CashBalanceStatusEnum.ARCHIVED;
  }

  get is_product_payment(): boolean {
    return this.type === CashBalanceTypesEnum.ProductPayment;
  }

  get is_excise_tax_remittance(): boolean {
    return this.type === CashBalanceTypesEnum.ExciseTaxRemittance;
  }
}
