import { DoCheck, Optional, Self, Directive } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';

class _MaterialInputBase {
   constructor(
       public _defaultErrorStateMatcher: ErrorStateMatcher,
       public _parentForm: NgForm,
       public _parentFormGroup: FormGroupDirective,
       public ngControl: NgControl
   ) {}
}

const _MaterialInputMixinBase = mixinErrorState(_MaterialInputBase);

// BUGBUG: Eventually, we can move more of the custom input boilerplate into here. There is a LOT of it.
@Directive()
export abstract class MaterialInputBase extends _MaterialInputMixinBase implements DoCheck {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
  }

  ngDoCheck() {
    this.updateErrorState();
  }
}

