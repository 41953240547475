/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

type RegionOrderByTypes = 'region.name';

export class RegionParameters extends OffsetHttpParameters {

  protected searchJSON: Partial<{
    region: { name: string };
  }> = {};

  constructor(params?: Partial<RegionParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: RegionOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string): this {
    this.searchJSON = { ...this.searchJSON, region: name ? { name: name } : undefined };
    return this;
  }
  
  getParams(): { [param: string]: any } {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
