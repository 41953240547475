import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { RestApiService } from '@shared/utility/api';
import { CashBalanceModel } from '../../cash-balance/model';

@Injectable({ providedIn: 'root' })
export class CashBalanceUploadService extends RestApiService<UploadModel> {
  readonly uniqueIdentifier = 'uuid';
  protected basePath = '/cash-job/{cash-job}/upload/';

  constructor(cashBalance: CashBalanceModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{cash-job}', cashBalance.uuid);
  }
}
