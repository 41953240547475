/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { MultiRestMethods, RestApiService } from '@shared/utility/api';
import { MenuInventoryModel } from '../model/menu-inventory.model';
import { MenuModel } from '../../menu/model/menu.model';
import { MenuInventoryUploadService } from './upload/menu-inventory-upload.service';
import { CursorHttpParameters, OffsetHttpParameters } from '@shared/utility/api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuInventoryService extends RestApiService<MenuInventoryModel> {
  basePath = '/menu/{menu-uuid}/inventory/';
  readonly uniqueIdentifier = 'uuid';

  constructor(private menu: MenuModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.basePath = this.basePath.replace('{menu-uuid}', menu.uuid);
  }

  getUploadService(menuInventory: MenuInventoryModel) {
    return new MenuInventoryUploadService(this.menu, menuInventory, this.config, this.http);
  }

  load(
    entity: MenuInventoryModel | MenuInventoryModel[],
    params?: OffsetHttpParameters | CursorHttpParameters
  ): Observable<MenuInventoryModel | MenuInventoryModel[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.LOAD);
    return this.http.get<MenuInventoryModel>(this.getPath(this.getIdentifier(entity)), {
      params: params ? params.getParams() : undefined
    });
  }
}
