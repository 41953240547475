import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiRestMethods, RestApiService } from '@shared/utility/api';
import { MessageTriggerModel } from '../../../message-trigger/model';
import { LocationModel } from '../../model/location.model';
import { ApiConfig } from '@shared/model/config/api/api-config.model';

@Injectable({
  providedIn: 'root'
})
export class LocationMessageTriggerService extends RestApiService<MessageTriggerModel> {
  basePath = '/location/{location}/message-trigger/';
  defaultsTriggersPath = '/location/{location}/message-trigger-default';
  readonly uniqueIdentifier: string = 'uuid';

  constructor(location: LocationModel, config: ApiConfig, http: HttpClient) {
    super(config, http);
    this.setBasePath(location);
    this.setDefaultsTriggerPath(location);
  }

  setBasePath(location: LocationModel) {
    this.basePath = this.basePath.replace('{location}', location.uuid);
  }

  setDefaultsTriggerPath(location: LocationModel) {
    this.defaultsTriggersPath = this.defaultsTriggersPath.replace('{location}', location.uuid);
  }

  loadDefaults(): Observable<any> {
    return this.http.get(`${this.config.baseUrl}${this.defaultsTriggersPath}`);
  }

  update(entity: MessageTriggerModel | MessageTriggerModel[]): Observable<MessageTriggerModel | MessageTriggerModel[]> {
    if (Array.isArray(entity)) return this.handleMultiple(entity, MultiRestMethods.UPDATE, 'put');
    return this.http.put<MessageTriggerModel>(this.getPath(this.getIdentifier(entity)), entity);
  }
}
