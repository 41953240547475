/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';

export class AbilityParameters extends OffsetHttpParameters {

  constructor(params?: Partial<AbilityParameters>) {
    super();
    if(params) Object.assign(this, params);
  }

}
