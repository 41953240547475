import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FeatureFlagConfig } from './feature-flag-config';
import { FeatureFlagStateService } from './feature-flag-state.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, HttpClientJsonpModule]
})
export class FeatureFlagModule {

  constructor(
    @Optional() @SkipSelf() parentModule?: FeatureFlagModule
  ) {
    if (parentModule) {
      throw new Error('FeatureFlagModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: { environment: string }): ModuleWithProviders<FeatureFlagModule> {
    // TODO add environment types or interfaces per app
    if (!['local', 'develop', 'staging', 'prod'].includes(config.environment)) {
      throw new Error('App releaseStage must be one of: local, develop, staging, prod');
    }

    return {
      ngModule: FeatureFlagModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (service: FeatureFlagStateService) => () => service.init().toPromise(),
          multi: true,
          deps: [FeatureFlagStateService]
        },
        {
          provide: FeatureFlagConfig,
          useValue: config
        },
      ]
    };
  }
}
