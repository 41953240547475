/**
 * THIS FILE IS AUTOGENERATED
 */
import { OffsetHttpParameters } from '@shared/utility/api';

import { DateRangeFieldInterface } from '../../../../../model/src/lib/field/date-range/date-range.interface';
import { DeliveryModel } from '../../delivery/model';
import { LocationModel } from '../../location/model';

export class CashBalanceParameters extends OffsetHttpParameters {
  private dateRange: DateRangeFieldInterface;

  private status: number;
  private type: number;
  private delivery: DeliveryModel;
  private from: LocationModel;
  private to: LocationModel;
  private created_by_location: LocationModel;
  private searchJSON = {
    status: undefined,
    type: undefined,
    delivery: undefined,
    from: undefined,
    to: undefined,
    created_by_location: undefined,
    created_at: undefined
  };

  constructor(params?: Partial<CashBalanceParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  getStatus() {
    return this.status;
  }

  setWhereStatus(status?: number) {
    this.status = status !== null && status !== undefined ? status : undefined;
    this.searchJSON.status = this.status;
    return this;
  }

  getType() {
    return this.type;
  }

  setWhereType(type?: number) {
    this.type = type !== null && type !== undefined ? type : undefined;
    this.searchJSON.type = this.type;
    return this;
  }

  getDelivery() {
    return this.delivery;
  }

  setWhereDelivery(delivery?: DeliveryModel) {
    this.delivery = delivery ? delivery : undefined;
    this.searchJSON.delivery = delivery ? { distinct: { uuid: delivery.uuid } } : undefined;
    return this;
  }

  getFromLocation() {
    return this.from;
  }

  setWhereFromLocation(from?: LocationModel) {
    this.from = from ? from : undefined;
    this.searchJSON.from = from ? { distinct: { uuid: from.uuid } } : undefined;
    return this;
  }

  getToLocation() {
    return this.to;
  }

  setWhereToLocation(to?: LocationModel) {
    this.to = to ? to : undefined;
    this.searchJSON.to = to ? { distinct: { uuid: to.uuid } } : undefined;
    return this;
  }

  getCreatedByLocation() {
    return this.created_by_location;
  }

  setWhereCreatedByLocation(created_by_location?: LocationModel) {
    this.created_by_location = created_by_location ? created_by_location : undefined;
    this.searchJSON.created_by_location = created_by_location
      ? { distinct: { uuid: created_by_location.uuid } }
      : undefined;
    return this;
  }

  getWhereDateRange() {
    return this.dateRange;
  }

  setWhereDateRange(dateRange?: DateRangeFieldInterface) {
    this.searchJSON.created_at = dateRange ? dateRange : undefined;
    return this;
  }

  getParams() {
    const params = {
      ...super.getParams(),
      search: JSON.stringify(this.removeUndefinedValues(this.searchJSON))
    };
    return this.removeUndefinedValues(params);
  }
}
