import { ErrorModel } from '@shared/model/error/error.model';
import { SessionModel } from '../../model';
import { SessionActions, SessionActionTypes } from '../actions/session.actions';

export interface State {
  authenticated: boolean;
  session: SessionModel;
  aclsByOrganization: any;
  loading: boolean;
  updating: boolean;
  error: ErrorModel;
}

export const initialState = {
  authenticated: undefined,
  session: undefined,
  aclsByOrganization: [],
  loading: false,
  updating: false,
  error: null
};

export function reducer(state = initialState, action: SessionActions): State {
  switch (action.type) {
    case SessionActionTypes.LoadSession: {
      return { ...state, loading: true };
    }

    case SessionActionTypes.LoginSuccess:
    case SessionActionTypes.LoadSessionSuccess: {
      const session = action.payload.session;
      return {
        ...state,
        error: null,
        session: session,
        authenticated: true,
        loading: false
      };
    }

    case SessionActionTypes.LoadSessionFailure: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        session: null,
        authenticated: false
      };
    }

    case SessionActionTypes.UpdateSession:
    case SessionActionTypes.DeleteSession: {
      return { ...state, updating: true };
    }

    case SessionActionTypes.UpdateSessionSuccess: {
      const session = action.payload.session;
      return { ...state, session: session, error: null, updating: false };
    }

    case SessionActionTypes.LoginFailure:
    case SessionActionTypes.UpdateSessionFailure:
    case SessionActionTypes.DeleteSessionFailure: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        updating: false,
        authenticated: false
      };
    }

    case SessionActionTypes.DeleteSessionSuccess:
    case SessionActionTypes.ClearSessionState: {
      return { ...initialState, authenticated: false };
    }

    case SessionActionTypes.LoadAclsByOrganizationSuccess: {
      return {
        ...state,
        aclsByOrganization: action.payload
      };
    }

    case SessionActionTypes.LoadAclsByOrganization:
    case SessionActionTypes.LoadAclsByOrganizationFailure: {
      return {
        ...state,
        aclsByOrganization: []
      };
    }

    default: {
      return state;
    }
  }
}
