/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { MessageTriggerModel } from '../model/message-trigger.model';

@Injectable({ providedIn: 'root' })
export class MessageTriggerService extends RestApiService<MessageTriggerModel> {
  readonly basePath = '/v2/message_triggers/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  getPath(identifier?: string): string {
    return this.getDoloresPath(identifier);
  }
}
