import { ServiceModel } from '@app/myblackbird/src/app/shared/model/service/service.model';
import { LocationStatusEnum } from '@shared/entity/location/enumeration';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { AddressModel } from '@shared/model/entity/address/address.model';
import { EntityInterface } from '@shared/model/entity/entity.interface';
import { EntityModel } from '@shared/model/entity/entity.model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ScheduleFieldInterface } from '@shared/model/field/schedule/schedule-field.interface';
import { LicenseClassModel } from '../../license-class/model';

interface LicenseInterface extends EntityInterface {
  number: string;
  license_class: LicenseClassModel;
  organization: TypedEntityInterface;
}

export class LocationModel extends EntityModel {
  public _type: number = TypesEnum.LOCATIONS;
  public _name: string;
  public name: string;
  public license: LicenseInterface;
  public organization: TypedEntityInterface;
  public default_distributor: TypedEntityInterface;
  public default_cash_service: TypedEntityInterface;
  public default_product_service: TypedEntityInterface;
  public address: AddressModel;
  public phone: number;
  public email: string;
  public notification_order_email: string | null;
  public website: string;
  public logo: UploadModel;
  public loyalty_program: TypedEntityInterface;
  public created_at: string;
  public updated_at: string;
  public taxes: TypedEntityInterface[];
  public services: ServiceModel[];
  public loading: boolean = false;
  public notes: string;
  public brand_color: string;
  public business_hours: ScheduleFieldInterface;
  public storefront_image: UploadModel;
  public default_recreational_customer_license_class: LicenseClassModel;
  public default_medical_customer_license_class: LicenseClassModel;
  public baker: boolean;
  public retail_self_delivery: boolean;
  public timezone: string;
  public client: boolean;
  public sms_name: string;
  public display_name: string;
  public connect_types: ('campaign_sms' | 'campaign_mms' | 'campaign_email' | 'single_sms' | 'single_mms' | 'single_email')[];
  public transactional_account: {
    disabled_at: string,
    phone_number: string
  };
  public status: LocationStatusEnum;

  constructor(location?: Partial<LocationModel>) {
    super();
    if (location) Object.assign(this, location);
  }
}
